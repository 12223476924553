import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    form: {
        width: '99%', // Fix IE11 issue.
        margin:'auto',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}))

export const ValidatedForm = (props: any) => {
    const classes = useStyles();

    useEffect(()=>{
        React.Children.forEach(props.children, element => {
            if (!React.isValidElement(element)) return
        })
    },[])

    const isFormValid = (): boolean => {
        return true
    }

    const formChildren = React.Children.map(props.children, child => {
        if(child)
            return React.cloneElement(child);
    });

    return (
        <form
            className={classes.form}
            onSubmit={(event) => {
                event.preventDefault();
            }}>
            {formChildren}
        </form>
    )
}