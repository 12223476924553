import React, {
  PropsWithoutRef,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {
  Avatar,
  Box,
  CardContent,
  Divider,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";

const randomColor = () => {
  let hex = Math.floor(Math.random() * 0xffffff);
  return "#" + hex.toString(16);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.contrastText,
      maxWidth: "unset",
      maxHeight: "unset",
      fontSize: "3rem!important",
      margin: "15px",
      [theme.breakpoints.down("sm")]: {
        pointerEvents: "none",
      },
    },
    avatar: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "inherit",
      width: "unset",
      height: "unset",
      fontSize: "3rem!important",
      margin: "auto",
      paddingTop: "20px",
      [theme.breakpoints.down("sm")]: {
        pointerEvents: "none",
      },
    },
    randomColor: {
      backgroundColor: `${randomColor()}!important`,
    },
    disabled: {
      //color: "red",
      "& p": {
        color: "gray!important",
      },
    },
    alert: {
      "&.info": {
        boxShadow: `${theme.DoH.color.info} 0px 1px 3px 0px, ${theme.DoH.color.info} 0px 0px 0px 1px`,
      },
      "&.error": {
        boxShadow: `${theme.DoH.color.error} 0px 1px 3px 0px, ${theme.DoH.color.error} 0px 0px 0px 1px`,
      },
      "&.warning": {
        boxShadow: `${theme.DoH.color.warning} 0px 1px 3px 0px, ${theme.DoH.color.warning} 0px 0px 0px 1px`,
      },
      cursor: "default",
      "&:hover": {
        boxShadow: "#2196f3 0px 1px 3px 0px, #2196f3 0px 0px 0px 1px",
      },
      "&.noHover": {
        //pointerEvents: 'none'
      },
    },
    alertBox: {
      "&.info": {
        backgroundColor: theme.DoH.color.info + "!important",
      },
      "&.error": {
        backgroundColor: theme.DoH.color.error + "!important",
      },
      "&.warning": {
        backgroundColor: theme.DoH.color.warning + "!important",
      },
    },
    fullWidth: {
      margin: "0px!important",
    },
  })
);

const StyledCard = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //marginBottom: '15px',
    cursor: "pointer",
    WebkitTransition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    "&:hover": {
      //boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 12px 16px 0px rgb(0 0 0 / 14%), 0px 4px 32px 0px rgb(0 0 0 / 12%)',
      WebkitTransition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
      transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    },
    "&.noHover": {
      //pointerEvents: 'none'
    },
    "&.pressed": {
      boxShadow:
        "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 2px 0px rgb(0 0 0 / 12%)",
      top: 10,
      transition: "unset",
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "unset!important",
      maxWidth: "95%",
      margin: "auto",
      marginBottom: "15px",
    },
  },
}))(Card);
const StyledStatisticCard = withStyles((theme) => ({
  root: {
    //marginBottom: '15px',
    minWidth: "200px",
    cursor: "pointer",
    WebkitTransition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    "&:hover": {
      boxShadow:
        "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 12px 16px 0px rgb(0 0 0 / 14%), 0px 4px 32px 0px rgb(0 0 0 / 12%)",
      WebkitTransition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
      transition: "all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1)",
    },
    "&.noHover": {
      pointerEvents: "none",
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "unset!important",
      maxWidth: "95%",
      margin: "auto",
      marginBottom: "15px",
    },
  },
}))(Card);
const SelectableCardHeader = withStyles((theme) => ({
  root: {
    padding: "0px!important",
  },
}))(CardHeader);
const SelectableStatisticCardHeader = withStyles((theme) => ({
  root: {
    display: "block",
    padding: "20px!important",
    textAlign: "center",
  },
  avatar: {
    marginRight: "0px",
  },
}))(CardHeader);
const StyledBox = withStyles((theme) => ({
  root: {
    width: "80px",
    height: "80px",
    backgroundColor: theme.palette.primary.main,
    "&.pressed": {
      backgroundColor: theme.DoH.color.pass,
      top: 10,
      transition: "unset",
    },
  },
}))(Box);
const StyledStatisticBox = withStyles((theme) => ({
  root: {
    marginRight: "0px",
    //'& p':{color:'grey!important'},
  },
}))(Box);
const StyledListCard = withStyles((theme) => ({
  root: {
    maxWidth: "100%",
    //marginBottom: '15px',
    cursor: "pointer",
    border: "none",
    //padding:'1rem',
    borderRadius: 0,
    boxShadow: " 0 0 0px 0 rgba(0,0,0,0.3)",
    backgroundColor: "#35353",
    "&.pressed": {
      //boxShadow: '0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 2px 0px rgb(0 0 0 / 12%)',
      //top: 10,
      transition: "unset",
    },
  },
}))(Card);
const StyledListCardContent = withStyles((theme) => ({
  root: {
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      margin: "unset",
    },

    "&:last-child": {
      padding: "unset",
    },
    "& .MuiList-root": {
      padding: "0px!important",
    },
  },
}))(CardContent);

export interface ISelectableCardProps {
  variant?: "alert" | "selectable";
  severity?: "info" | "error" | "warning";
  style?: React.CSSProperties;
  icon?: JSX.Element;
  avatar?: string;
  avatarColor?: string;
  onSelected?: (selectedId: string, selected?: boolean) => void;
  onClick?: () => void;
  title?: ReactNode;
  subheader?: ReactNode;
  action?: ReactNode;
  id: string;
  checked?: boolean;
  disabled?: boolean;
}

export const SelectableCard = (props: ISelectableCardProps) => {
  const classes = useStyles();

  const { checked } = props;

  // useEffect(() => {
  //   setPressed(false);
  // }, [props.title]);

  const StyledIcon = (): JSX.Element | null => {
    if (props.icon)
      return React.cloneElement(props.icon as JSX.Element, {
        className: classes.icon,
      });
    return null;
  };
  const StyledAvatar = (): JSX.Element => (
    <Avatar variant="square" className={classes.avatar}>
      {props.avatar}
    </Avatar>
  );
  const getSeverityIcon = (): ReactElement | undefined => {
    switch (props.severity) {
      case "info":
        return (
          <InfoOutlinedIcon
            className={`${
              props.variant === "alert"
                ? clsx(classes.icon, props.severity)
                : ""
            }`}
          />
        );
      case "error":
        return (
          <ErrorOutlineIcon
            className={`${
              props.variant === "alert"
                ? clsx(classes.icon, props.severity)
                : ""
            }`}
          />
        );
      case "warning":
        return (
          <WarningRoundedIcon
            className={`${
              props.variant === "alert"
                ? clsx(classes.icon, props.severity)
                : ""
            }`}
          />
        );
    }
  };

  return (
    <div
      onClick={() => {
        if (props.onSelected) {
          props.onSelected(props.id);
        }
      }}
    >
      <StyledCard
        elevation={3}
        className={clsx(
          `${
            props.variant === "alert"
              ? clsx(classes.alert, props.severity, "noHover")
              : props.onSelected
              ? ""
              : "selectable"
          }`,

          `${checked ? clsx("pressed") : ""}`
        )}
        style={props.style}
      >
        <SelectableCardHeader
          avatar={
            <StyledBox //style={{backgroundColor:props.avatarColor}}
              className={clsx(
                `${
                  props.variant === "alert"
                    ? clsx(classes.alertBox, props.severity)
                    : ""
                }`,
                `${checked ? clsx("pressed") : ""}`
              )}
            >
              {props.severity ? (
                getSeverityIcon()
              ) : checked ? (
                <CheckBoxOutlinedIcon className={classes.icon} />
              ) : props.icon ? (
                <StyledIcon />
              ) : (
                <StyledAvatar />
              )}
              {/*<StyledIcon className={classes.icon}/>*/}
            </StyledBox>
          }
          title={props.title}
          subheader={props.subheader}
          action={props.action}
        />
      </StyledCard>
    </div>
  );
};

export const SelectableStatisticCard = (props: ISelectableCardProps & any) => {
  const classes = useStyles();

  const cardClassName = `${
    props.disabled === true
      ? "noHover"
      : props.variant === "alert"
      ? clsx(classes.alert, props.severity, "noHover")
      : props.onSelected
      ? ""
      : "noHover"
  }`;

  return (
    <div
      onClick={() => {
        if (props.onSelected && !props.disabled) {
          props.onSelected();
        }
      }}
    >
      <StyledStatisticCard
        elevation={3}
        className={cardClassName}
        style={props.style}
      >
        <SelectableStatisticCardHeader
          avatar={
            <StyledStatisticBox
              className={props.disabled === true ? classes.disabled : ""}
            >
              {props.value}
            </StyledStatisticBox>
          }
          title={props.title}
          subheader={props.subheader}
          action={props.action}
        />
      </StyledStatisticCard>
    </div>
  );
};
export const SelectableCardSkeleton = (props: any) => {
  const skeletons = [];

  for (let i = 0; i < props.count; i++) {
    skeletons.push(
      <Box
        key={i}
        display="flex"
        height="80px"
        style={{ marginBottom: "10px" }}
      >
        <Skeleton
          height="100%"
          style={{
            height: "80px",
            width: "90px",
            marginRight: "10px",
            transform: "unset",
          }}
        >
          <Avatar style={{ width: "80px" }} />
        </Skeleton>
        <Box width="100%">
          <Skeleton width="60%">
            <Typography>.</Typography>
          </Skeleton>
          <Skeleton width="30%">
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </Box>
    );
  }

  return <div>{skeletons}</div>;
};
export const CardList = (props: any) => {
  const classes = useStyles();
  return (
    <StyledListCard
      style={{ maxWidth: props.maxWidth, overflow: "auto" }}
      className={`${props.fullWidth ? classes.fullWidth : ""}`}
    >
      <StyledListCardContent>
        <List>{props.children}</List>
      </StyledListCardContent>
    </StyledListCard>
  );
};
export const SelectableCardListItem = (props: ISelectableCardProps) => {
  const classes = useStyles();

  const { checked = false } = props;

  const [pressed, setPressed] = useState(checked);
  const [previousPressedState, setPreviousPressedState] = useState(false);

  useEffect(() => {
    setPressed(false);
  }, [props.title]);

  // useEffect(() => {
  //     if((pressed && !previousPressedState) && props.onSelected) {
  //         props.onSelected(props.id, true)
  //     }
  //     else if((!pressed && previousPressedState) && props.onSelected){
  //         props.onSelected(props.id, false)
  //     }

  // }, [pressed])

  const StyledIcon = (): JSX.Element | null => {
    if (props.icon)
      return React.cloneElement(props.icon as JSX.Element, {
        className: classes.icon,
      });
    return null;
  };
  const StyledAvatar = (): JSX.Element => (
    <Avatar variant="square" className={classes.avatar}>
      {props.avatar}
    </Avatar>
  );
  const getSeverityIcon = (): ReactElement | undefined => {
    switch (props.severity) {
      case "info":
        return (
          <InfoOutlinedIcon
            className={`${
              props.variant === "alert"
                ? clsx(classes.icon, props.severity)
                : ""
            }`}
          />
        );
      case "error":
        return (
          <ErrorOutlineIcon
            className={`${
              props.variant === "alert"
                ? clsx(classes.icon, props.severity)
                : ""
            }`}
          />
        );
      case "warning":
        return (
          <WarningRoundedIcon
            className={`${
              props.variant === "alert"
                ? clsx(classes.icon, props.severity)
                : ""
            }`}
          />
        );
    }
  };

  return (
    <div
      onClick={() => {
        if (props.onSelected) {
          props.onSelected(props.id, false);
        }
        if (props.variant === "selectable") {
          setPreviousPressedState(pressed);
          setPressed(!pressed);
        } else {
          setPreviousPressedState(!pressed);
          setPressed(false);
        }
      }}
    >
      <ListItem disableGutters={true} style={{ padding: "0px" }}>
        <StyledListCard
          elevation={3}
          className={clsx(
            `${
              props.variant === "alert"
                ? clsx(classes.alert, props.severity, "noHover")
                : props.onSelected
                ? ""
                : "selectable"
            }`,

            `${props.checked ? clsx("pressed") : ""}`
          )}
          style={props.style}
        >
          <SelectableCardHeader
            avatar={
              <StyledBox //style={{backgroundColor:props.avatarColor}}
                className={clsx(
                  `${
                    props.variant === "alert"
                      ? clsx(classes.alertBox, props.severity)
                      : ""
                  }`,
                  `${props.checked ? clsx("pressed") : ""}`
                )}
              >
                {props.severity ? (
                  getSeverityIcon()
                ) : props.checked ? (
                  <CheckBoxOutlinedIcon className={classes.icon} />
                ) : props.icon ? (
                  <StyledIcon />
                ) : (
                  <StyledAvatar />
                )}
                {/*<StyledIcon className={classes.icon}/>*/}
              </StyledBox>
            }
            title={props.title}
            subheader={props.subheader}
            action={props.action}
          />
        </StyledListCard>
      </ListItem>
      <Divider />
    </div>
  );
};
