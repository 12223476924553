import React, { forwardRef, useImperativeHandle } from "react";
import { Theme, withStyles } from "@material-ui/core/styles";
import MuiDrawer from "@material-ui/core/Drawer";
import { AppBar, IconButton, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const StyledAbsoluteDrawer = withStyles((theme: Theme) => ({
  paper: {
    position: "absolute",
    height: "100%",
  },
  modal: {
    position: "absolute",
  },
}))(MuiDrawer);
const StyledDrawer = withStyles((theme: Theme) => ({
  root: {},
  paper: {
    width: 800,
    overflowX: "hidden" /* Hide horizontal scrollbar */,
  },
}))(MuiDrawer);
export const DrawerBottom = (props: any) => {
  const { classes } = props;
  return (
    <StyledAbsoluteDrawer {...props}>
      <div tabIndex={0}>{props.children}</div>
    </StyledAbsoluteDrawer>
  );
};

export const Drawer = forwardRef((props: any, ref) => {
  const [drawerState, setDrawerState] = React.useState(false);
  const handleClose = () => {
    setDrawerState(false);
  };

  useImperativeHandle(ref, () => ({
    openDrawer: () => {
      setDrawerState(true);
    },
    closeDrawer: () => {
      setDrawerState(false);
    },
  }));

  return (
    <div>
      <StyledDrawer
        anchor={props.anchor}
        open={drawerState}
        onClose={() => {
          props.onClose && props.onClose();
          setDrawerState(false);
        }}
      >
        {props.toolbar && (
          <AppBar style={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        )}
        {props.children}
      </StyledDrawer>
    </div>
  );
});
