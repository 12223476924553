import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Container} from "@material-ui/core";


export const StyledContainer = withStyles((theme) => ({
    root: {
        [theme.breakpoints.down('md')]:{
            width:'100%',
            
            marginTop: theme.spacing(0),
            paddingLeft:theme.spacing(0),
            paddingRight:theme.spacing(0)
        },
        //width: '700px',
        marginTop: theme.spacing(4),
        // color:'#fff',
        //marginBottom: theme.spacing(4)
    }
}))(Container);