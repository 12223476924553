import { BlobProvider } from "@react-pdf/renderer";
import { GenerateQuestionnairePDF } from "./GeneratePDF";
import React, { useRef } from "react";
import { AnswersState } from "../Questionnaire";
import {
  PersonViewModel,
  QuestionAdminViewModel,
} from "../../../common/client";
import { saveAs } from "file-saver";

export function SaveQuestionnairePDF({
  title,
  filename,
  completedAt,
  person,
  answers,
  questions,
}: {
  title: string;
  filename: string;
  completedAt: string | undefined;
  person: PersonViewModel;
  answers: AnswersState;
  questions: QuestionAdminViewModel[];
}) {
  const saved = useRef(false);
  return (
    <BlobProvider
      document={
        <GenerateQuestionnairePDF
          title={title}
          questions={questions}
          answers={answers}
          person={person}
          completedAt={completedAt}
        />
      }
    >
      {({ blob, url, loading, error }) => {
        if (blob && !saved.current) {
          saved.current = true;
          saveAs(blob, filename);
        }
        return <div />;
      }}
    </BlobProvider>
  );
}
