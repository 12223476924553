import React, { useEffect, useRef, useState } from "react";
import { Slide, SlideProps } from "@material-ui/core";
import { Question } from "./QuestionItem";
import { QuestionChoice } from "../../../common/client";
import { QuestionItemNavProps } from "./Questionnaire";

export const QuestionItemSlider = (props: QuestionItemNavProps) => {
  const { questions, controls } = props;
  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] =
    useState<SlideProps["direction"]>("left");
  const [currentIndex, setCurrentIndex] = useState(props.questionIndex);
  const question = questions[currentIndex];
  const backRef = useRef<{ [id: string]: number }>({});

  useEffect(() => {
    if (props.questionIndex !== currentIndex) {
      const toDir = (b: boolean) => (b ? "left" : "right");
      const goingLeft = !props.transitionForward;
      setSlideDirection(toDir(goingLeft));
      setSlideIn(false);

      setTimeout(() => {
        setCurrentIndex(props.questionIndex);
        setSlideDirection(toDir(!goingLeft));
        setSlideIn(true);
      }, 300);
    }
  }, [props.questionIndex, currentIndex]);

  function getPreviousQuestionIndex() {
    return backRef.current[question.id] ?? currentIndex - 1;
  }
  function getNextQuestionIndex(selectedChoice: QuestionChoice) {
    let nextQuestionIndex = -1;
    if (question.responseMappings) {
      if (selectedChoice.id) {
        //find nextQuestionId of question response mappings matching choice Id
        const mapping = question.responseMappings.find(
          (m) => m.choiceId === selectedChoice.id
        );
        const nextQuestionId = mapping?.nextQuestionId;
        if (nextQuestionId) {
          //get question index matching these id
          nextQuestionIndex = questions.findIndex(
            (question) => question.id === nextQuestionId
          );
          if (nextQuestionIndex >= 0) {
            backRef.current[nextQuestionId] = currentIndex;
            return nextQuestionIndex;
          }
        }
      }
    }

    return nextQuestionIndex;
  }
  const onChoiceSelected = (direction: "left" | "right") => {
    const selectedChoiceId = controls.fields[question.id].fields.choiceId.value;
    const nextQuestionIndex =
      direction === "left"
        ? getPreviousQuestionIndex()
        : getNextQuestionIndex(
            question.choices.find((c) => c.id === selectedChoiceId)!
          );

    props.onIndexChange(nextQuestionIndex, direction === "right");
  };

  return (
    <>
      <Slide in={slideIn} direction={slideDirection}>
        <div
          style={{
            maxWidth: props.maxWidth ?? "100%",
            ...props.style,
            margin: "auto",
          }}
        >
          <Question
            readonly={false}
            question={question}
            control={props.controls.fields[question.id]}
            currentIndex={currentIndex}
            onBack={() => {
              onChoiceSelected("left");
            }}
            onResult={() => {
              onChoiceSelected("right");
            }}
          />
        </div>
      </Slide>
    </>
  );
};
