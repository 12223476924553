import React, { useRef, ReactElement } from "react";
import { Container, Grid, Box } from "@material-ui/core";
import ManagerForm from "./SubComponents/ManagerForm";
import { Drawer } from "./SubComponents/Drawer";
import ManagerToolbar from "./SubComponents/ManagerToolBar";
import CbxTree from "./SubComponents/CheckboxTree";
import ResultList from "./SubComponents/ResultList";
import { TeamTieSolid } from "./Icons/Icons";

interface IManager {
	name: string;
	appbarExtraComponents?: ReactElement;
	appbarRightComponents?: ReactElement;
	disableToolBar?: boolean;
	onSearchBarSearch?: (text: string) => void;
	treeData: any[];
	currentItems: any[];
	selectedItems: any[];
	onResultSelect?: (result: any) => void;
	onCheckBoxChecked?: (checked: any[]) => void;
	checkBoxTreeMultiSelect?: boolean;
	onAdd: (items: any[]) => void;
	onRemove: () => void;
	onItemSearch: (query: string) => Promise<any>;
	noResultsMessage?: string;
}

export const Manager = ({
	name,
	appbarExtraComponents,
	appbarRightComponents,
	disableToolBar = false,
	onSearchBarSearch,
	treeData,
	currentItems,
	selectedItems,
	onResultSelect,
	onCheckBoxChecked,
	checkBoxTreeMultiSelect = false,
	onAdd,
	onRemove,
	onItemSearch,
	noResultsMessage,
}: IManager) => {
	const formDrawer = useRef<any>(null);

	return (
		<Container maxWidth="lg">
			<Drawer
				ref={formDrawer}
				toolbar={true}
				anchor={"right"}
				title="Add a new team"
			>
				<ManagerForm />
			</Drawer>

			<Box mb={2}>
				<ManagerToolbar
					onSearch={(e) =>
						onSearchBarSearch && onSearchBarSearch(e.target.value)
					}
					onItemSearch={onItemSearch}
					onAdd={onAdd}
					onRemove={onRemove}
					onRefresh={() => {}}
					disabled={disableToolBar}
					renderExtraActions={appbarExtraComponents}
				>
					{appbarRightComponents}
				</ManagerToolbar>
			</Box>
			<Grid container justify="space-between" spacing={3}>
				<Grid item xs={12} md={5}>
					<CbxTree
						generic
						multiSelect={checkBoxTreeMultiSelect}
						notFoundContent={noResultsMessage === undefined ? `No ${name} To Display` : noResultsMessage}
						nodes={treeData}
						header={name}
						onChecked={(checked: any) => {
							onCheckBoxChecked && onCheckBoxChecked(checked);
						}}
					/>
				</Grid>
				<Grid item xs={12} md={7}>
					<ResultList
						members={currentItems}
						icon={<TeamTieSolid />}
						title={name}
						onSelected={onResultSelect}
						selectedMembers={selectedItems}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
