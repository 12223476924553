import { Box, Button, Grid, MenuItem } from "@material-ui/core";
import {
  ArrayControl,
  FormArray,
  useControlValue,
} from "@react-typed-forms/core";
import { FCheckbox } from "../../../../common/formComponents/FCheckbox";
import { ChoiceEditor } from "./ChoiceEditor";
import { DateConstraintFormType, QuestionForm } from "./QuestionEditor";
import { SubQuestionEditor } from "./SubQuestionEditor";
import {
  QuestionType,
  QueueOutcomeViewModel,
  TextType,
} from "../../../../common/client";
import { FDateField } from "../../../../common/formComponents/FTextField";
import { FNumberField } from "../../../../common/formComponents/FNumberField";
import { moveDown, moveUp } from "../../../../common/arrays";

export interface QuestionOptionsProps {
  state: QuestionForm;
  otherQuestions: ArrayControl<QuestionForm>;
  outcomeResults: QueueOutcomeViewModel[];
}

export function QuestionOptions({
  state,
  otherQuestions,
  outcomeResults,
}: QuestionOptionsProps) {
  const fields = state.fields;
  const questionType = useControlValue(fields.type);

  return (
    <>
      <Box my={2}>
        <Box>
          <FCheckbox label="Hidden" state={fields.hidden} />
          <FCheckbox label="Required" state={fields.required} />
        </Box>
        {(questionType === QuestionType.DateField ||
          questionType === QuestionType.DateTimeField) && (
          <Box my={2}>
            {renderDateConstraint(fields.validation.fields.notBefore, "Before")}
            {renderDateConstraint(fields.validation.fields.notAfter, "After")}
          </Box>
        )}
        {questionType === QuestionType.TextField && (
          <Box my={2}>
            <FNumberField
              select
              state={fields.validation.fields.textType}
              label="Validation"
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value={TextType.Phone}>Mobile Phone</MenuItem>
              <MenuItem value={TextType.Email}>Email</MenuItem>
            </FNumberField>
          </Box>
        )}
        <FormArray state={fields.choices}>
          {(choices) =>
            choices.map((c, i) => (
              <ChoiceEditor
                key={c.uniqueId}
                state={c}
                otherQuestions={otherQuestions}
                onDelete={() => fields.choices.remove(i)}
                onUp={() => moveUp(fields.choices, i)}
                onDown={() => moveDown(fields.choices, i)}
                queueOutcomes={outcomeResults}
              />
            ))
          }
        </FormArray>
        <Button onClick={() => fields.choices.add()}>Add choice</Button>
      </Box>
      <Box>
        <FormArray state={fields.subQuestions}>
          {(subQuestions) =>
            subQuestions.map((c, i) => (
              <SubQuestionEditor
                state={c}
                key={c.uniqueId}
                onDelete={() => fields.subQuestions.remove(i)}
              />
            ))
          }
        </FormArray>
        <Button onClick={() => fields.subQuestions.add()}>
          Add sub-question
        </Button>
      </Box>
    </>
  );

  function renderDateConstraint(state: DateConstraintFormType, type: string) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FDateField
            state={state.fields.fixed}
            label={`Not ${type}`}
            fullWidth
          />
        </Grid>
        <Grid item xs={1}>
          <FNumberField
            state={state.fields.days}
            label={`Days ${type}`}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  }
}
