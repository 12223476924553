import { useApiClient } from "../../../../common/apiclients";
import {
  BasicQuestionnaireViewModel,
  QuestionnairesClient,
  QueueClient,
  ViewQueueViewModel,
} from "../../../../common/client";
import { useEffect, useState } from "react";
import { Button, Link } from "@material-ui/core";
import { editQueueLink } from "../../Routes";
import { useHistory } from "react-router";

export function QueueList() {
  const client = useApiClient(QueueClient);
  const [queues, setQueues] = useState<ViewQueueViewModel[]>();

  const history = useHistory();

  useEffect(() => {
    loadQueues();
  }, []);

  return queues ? showQueues(queues) : <div />;

  function showQueues(list: ViewQueueViewModel[]) {
    return (
      <>
        <ul>
          {list.map((q) => (
            <li>
              <Link href={editQueueLink(q.id)}>{q.name}</Link>
            </li>
          ))}
        </ul>
        <Button onClick={() => addQueue()}>Add</Button>
      </>
    );
  }

  function addQueue() {
    history.push("/admin/queue");
  }

  async function loadQueues() {
    setQueues(await client.getAllQueuesForEdit());
  }
}
