import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";

const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },
    fontSize: "1.25rem",
    lineHeight: " 1.4375rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px!important",
    },
  },
}))(TextField);

const ValidationTextField = React.forwardRef(function TextField(
  props: TextFieldProps & { children?: React.ReactElement } & any,
  ref: React.Ref<unknown>
) {
  return <StyledTextField {...props} />;
});

export default ValidationTextField;
