import { PersonKind, QueueWorkItem } from "../state/State.model";

const BASE_URL = "/api/";
const postJson = (route: string, data: any, token?: string) =>
  fetch(`${BASE_URL}${route}`, {
    method: "POST",
    body: data != null ? JSON.stringify(data) : null,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.text();
    })
    .then((data) => {
      let response = data ? JSON.parse(data) : {};
      if (response) {
        if (response?.status != 200 && response?.errors) {
          console.log(Object.keys(response?.errors));
          Object.keys(response?.errors).forEach((o) => {
            console.log(response.errors[o]);
          });
        }
      }

      return response;
    })
    .catch((err) => {
      throw new Error(err);
    });

const getJson = (route: string, token?: string) =>
  fetch(`${BASE_URL}${route}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      console.log(res);
      if (!res.ok) {
        throw new Error(JSON.stringify(res));
      }

      if (!res.redirected) {
        return res.json();
      }
    })
    .catch((err) => {
      console.error(err);
      throw new Error(err);
    });

const deleteJson = (route: string, data: any, token?: string) =>
  fetch(`${BASE_URL}${route}`, {
    method: "DELETE",
    body: data != null ? JSON.stringify(data) : null,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      return res.text();
    })
    .then((data) => {
      let response = data ? JSON.parse(data) : {};
      if (response) {
        if (response?.status != 200 && response?.errors) {
          console.log(Object.keys(response?.errors));
          Object.keys(response?.errors).forEach((o) => {
            console.log(response.errors[o]);
          });
        }
      }

      return response;
    })
    .catch((err) => {
      throw new Error(err);
    });

export const getUserInfo = (token: string) => getJson("user/info", token);

export const getJwtToken = (
  firstName: string,
  lastName: string,
  contactNumber: string,
  kind: PersonKind,
  additionalData?: any
) =>
  postJson("user/gate", {
    firstName,
    lastName,
    contactNumber,
    kind,
    ...additionalData,
  });
export const registerUser = (
  firstName: string,
  lastName: string,
  contactNumber: string,
  kind: PersonKind,
  additionalData?: any,
  token?: string
) =>
  postJson(
    "_admin/persons/register",
    {
      firstName,
      lastName,
      contactNumber,
      kind,
      ...additionalData,
    },
    token
  );
export const searchAddresses = (query: string) =>
  getJson(`address/search?q=${encodeURIComponent(query)}`);
export const startQuestionnaire = (locationId: string, token?: string) =>
  postJson(
    "questionnaire",
    {
      locationId,
    },
    token
  );
export const createQuestionnaireResponse = (
  questionnaireId: string,
  personId: string,
  token?: string
) =>
  postJson(
    `questionnaire/${questionnaireId}/createResponse/${personId}`,
    {},
    token
  );
export const saveQuestionResponse = (
  questionnaireId: string,
  questionId: string,
  choiceId: string,
  token?: string
) =>
  postJson(
    `questionnaire/${questionnaireId}/${questionId}`,
    {
      choiceId,
    },
    token
  );
export const saveQuestionResponseByAdmin = (
  questionnaireId: string,
  questionId: string,
  choiceId: string,
  personId: string,
  token?: string
) =>
  postJson(
    `questionnaire/${questionnaireId}/${questionId}/${personId}`,
    {
      choiceId,
    },
    token
  );
export const finaliseQuestionnaire = (
  questionnaireId: string,
  token?: string
) => postJson(`questionnaire/${questionnaireId}/finalise`, {}, token);
export const finaliseQuestionnaireByAdmin = (
  questionnaireId: string,
  personId: string,
  token?: string
) =>
  postJson(`questionnaire/${questionnaireId}/${personId}/finalise`, {}, token);
export const getQuestions = (token?: string) => getJson(`questionnaire`, token);
export const getQuestionsByPersona = (personKind: PersonKind, token?: string) =>
  getJson(`_admin/questionnaires/${personKind}/questions`, token);
export const getDependants = (token?: string) => getJson("dependants", token);
export const getQueueWorkItems = (
  queueId: string,
  queueWorkItemStatuses: QueueWorkItem[],
  query: string,
  before: any = null,
  after: any = null,
  outcomes: string[],
  token?: string
) =>
  postJson(
    `_admin/queue/${queueId}/workitems`,
    {
      queueWorkItemStatuses,
      query,
      before,
      after,
      outcomes,
    },
    token
  );
export const getQueueWorkItem = (
  queueId: string,
  workItemId: string,
  token?: string
) => getJson(`_admin/queue/${queueId}/workItems/${workItemId}`, token);
export const getQueueMetrics = (
  queueId: string,
  before: any = null,
  after: any = null,
  outcomes: string[] = [],
  token?: string
) =>
  postJson(
    `_admin/queue/${queueId}/metrics`,
    {
      before,
      after,
      outcomes,
    },
    token
  );
export const getQueues = (token?: string) => getJson(`_admin/queue`, token);
export const AssignWorkItemToMe = (
  queueId: string,
  workItemId: string,
  token?: string
) =>
  getJson(`_admin/queue/${queueId}/workItems/${workItemId}/assignToMe`, token);
export const UnAssignWorkItem = (
  queueId: string,
  workItemId: string,
  token?: string
) => getJson(`_admin/queue/${queueId}/workItems/${workItemId}/unassign`, token);
export const SaveWorkItemNote = (
  queueId: string,
  workItemId: string,
  note: string,
  global: boolean,
  token?: string
) =>
  postJson(
    `_admin/queue/${queueId}/workItems/${workItemId}/saveNote`,
    { note, global },
    token
  );
export const UpdateQueueWorkItemStatus = (
  queueId: string,
  workItemId: string,
  note: string,
  status: QueueWorkItem,
  token?: string
) =>
  postJson(
    `_admin/queue/${queueId}/workItems/${workItemId}/updateStatus`,
    { note, status },
    token
  );
export const getQueueWorkItemNotes = (
  queueId: string,
  workItemId: string,
  token?: string
) => getJson(`_admin/queue/${queueId}/workItems/${workItemId}/notes`, token);
export const getQueueWorkItemQuestions = (
  queueId: string,
  workItemId: string,
  token?: string
) =>
  getJson(`_admin/queue/${queueId}/workItems/${workItemId}/questions`, token);
export const getQueueWorkItemAuditEvents = (
  queueId: string,
  workItemId: string,
  token?: string
) =>
  getJson(`_admin/queue/${queueId}/workItems/${workItemId}/auditEvents`, token);

export const getAllQueueMetrics = (
  before: any = null,
  after: any = null,
  token?: string
) => postJson(`_admin/queue/metrics`, { before, after }, token);

export const unassignItemFromQueue = (
  queueId: string,
  workItemId: string,
  token?: string
) =>
  postJson(
    `_admin/queue/${queueId}/workItems/${workItemId}/unassign`,
    {},
    token
  );

export const getRoles = (token?: string) => getJson("_admin/roles", token);

export const getRoleMembers = (roleId: string, token?: string) =>
  getJson(`_admin/roles/${roleId}/members`, token);

export const removeRoleMember = (
  roleId: string,
  personId: string,
  token?: string
) => deleteJson(`_admin/roles/${roleId}/members/${personId}`, null, token);

export const searchStaffMembers = (query: string, token: string) =>
  postJson(`_admin/persons/search`, { query, accountType: 2 }, token);

export const addRoleMember = (
  roleId: string,
  personId: string,
  token?: string
) => postJson(`_admin/roles/${roleId}/members/${personId}`, null, token);

export const ResendEamilNotification = (
  emailDestination: string,
  destinationName: string,
  token?: string
) =>
  postJson(
    `_admin/queue/resendemail/${emailDestination}/${destinationName}`,
    {
      emailDestination,
      destinationName,
    },
    token
  );
