import React, { useContext, useEffect, useState } from "react";
import { Header } from "./components/core/Header";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import "./App.css";
import { Slide } from "@material-ui/core";
import { Routes } from "./views/Routes";
import { Footer } from "./components/core/Footer";
import { CustomTheme } from "./themes/themeFactory";
import { Context } from "./state/Store";
import { getUserInfo } from "./data/api";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import { useLocation } from "react-router";
// thomas added matchPath
import { matchPath } from "react-router";
import { useTheme } from "@material-ui/core";
import { isThrowStatement } from "typescript";
import Hidden from "@material-ui/core/Hidden";
import withWidth from "@material-ui/core/withWidth";
import { useMediaQuery } from "@material-ui/core";
import { getQueues } from "./data/api";
import { QuestionnaireProvider } from "./QuestionnaireContext";

const headerStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    headerPlaceHolder: {
      [theme.breakpoints.down("md")]: {
        height: "50px",
        width: "100%",
      },
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

function App() {
  //GLOBAL STATE
  const { state, dispatch } = useContext(Context);

  //COMPONENT STATE
  const [authToken, setAuthToken] = useState<string>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // Thomas add this
  // let isMatch = matchPath("/questionnaire",{exact:true});
  // console.log(isMatch)
  const [shouldHideFooter, setShouldHideFooter] = useState(false);

  const [scr, setScr] = useState(window.screen.availWidth);

  window.addEventListener("resize", () => {
    setScr(window.screen.availWidth);
  });

  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    // console.log(window.screen.availWidth)
    if (
      location.pathname === "/questionnaire" &&
      window.screen.availWidth < 960
    ) {
      setShouldHideFooter(true);
    } else {
      setShouldHideFooter(false);
    }
  }, [location, scr]);

  let classes = headerStyles();
  let theme = useTheme();

  const isThemeDown = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const url = window.location.href;
    console.log("relog",state.user?.token)
    if (url.slice(-6) !== "logout") {
      if (state.user?.token) {
        setAuthToken(state.user?.token);
      } else {
        if (location.hash != "") {
          setAuthToken(location.hash.split("=")[1]);
        } else if (state.user?.token) setAuthToken(state.user?.token);
        else window.location.href = "/api/user/login";
      }
    }
  }, []);

  useEffect(() => {
    const url = window.location.href;
    console.log(url)

    if (url.slice(-6) !== "logout") {
      if (authToken) {
        if (url.slice(-6) !== "admin" || url.slice(-15) !== "admin/referrals"){
          getUserInfo(authToken)
              .then((userInfo: any) => {
                console.log(userInfo);
                console.log(authToken);
                dispatch({
                  type: "SET_USERINFO",
                  payload: {
                    ...state.user,
                    token: authToken,
                    rememberMe: true,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    contactNumber: userInfo.contactNumber,
                    kind: userInfo.kind,
                    roles: userInfo.roles,
                  },
                });
                history.push("/");
              })
              .catch((err) => {
                console.error(err);
                enqueueSnackbar("Not able to return user info", {
                  variant: "error",
                });
              });
        } else {

          getUserInfo(authToken)
              .then((userInfo: any) => {
                console.log(authToken);
                dispatch({
                  type: "SET_USERINFO",
                  payload: {
                    ...state.user,
                    token: authToken,
                    rememberMe: true,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                    contactNumber: userInfo.contactNumber,
                    kind: userInfo.kind,
                    roles: userInfo.roles,
                  },
                });
                history.push("/admin");
              })
              .catch((err) => {
                console.error(err);
                enqueueSnackbar("Not able to return user info", {
                  variant: "error",
                });
              });
        }
      } else {
        if (location.hash != "") {
          setAuthToken(location.hash.split("=")[1]);
        } else {
          if (state.user?.token) setAuthToken(state.user?.token);
        }
      }
    }
  }, [authToken]);

  return (
    <QuestionnaireProvider>
          <div className={"site-container"}>
              <Header />
              <div className={classes.headerPlaceHolder} />
              <main id={"main-content"}>
                  <div className="content">
                      {state.user !== null && state.user?.token.length > 1 && (
                          <Routes />
                      )}
                  </div>
                  {!shouldHideFooter && (
                      <footer>
                          <Footer />
                      </footer>
                  )}
              </main>
          </div>
    </QuestionnaireProvider>
  );
}

export default App;
