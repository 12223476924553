import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { withStyles } from "@material-ui/core/styles";

const StyledToggleButton = withStyles((theme) => ({
  root: {
    fontSize: "1.8rem!important",
    padding: "5px!important",
    "&.Mui-selected": {
      border: "1px solid white",
      color: theme.palette.primary.contrastText,
      "&.New": {
        backgroundColor: theme.DoH.color.conditional,
      },
      "&.OnHold": {
        backgroundColor: theme.DoH.color.fail,
      },
      "&.Viewed": {
        backgroundColor: theme.DoH.color.exemption,
      },
      "&.PDFDownloaded": {
        backgroundColor: theme.DoH.color.pass,
      },
    },
    "&:focus": {
      outline: "none !important",
    },
  },
}))(ToggleButton);

// Unfortunately, ToggleButtonGroup uses React.children.map instead of context
// so wrapping with Tooltip breaks ToggleButton functionality.
// This is a workaround.

// @ts-ignore
const TooltipToggleButton = ({ children, title, ...props }) => (
  <Tooltip title={title}>
    <span>
      <StyledToggleButton {...props}>{children}</StyledToggleButton>
    </span>
  </Tooltip>
);

export default TooltipToggleButton;
