import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  makeStyles,
  Typography,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down("sm")]: {
      color:" #FFF!important",
      position: "fixed",
      bottom: "0px",
      left: "0px",
      width: "100%",
      margin: "unset",
      maxWidth: "unset!important",
    },
    display: "flex!important",
    textAlign: "center",
    fontSize: "1rem",
    maxHeight: "50px",
    color:" #FFF!important",
  },
  btnActive: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  btnStartIcon: {
    color:" #FFF!important",
    fontSize: "1rem!important",
    maxWidth: "400px",
    minWidth: "40px",
    maxHeight: "50px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem!important",
    },
  },
  typography: {
    color: "#FFF!important",
  }

}));

interface ICommonBTN {
    BtnText: string;
    onClick: () => void;
    maxWidth: string; 
}

export const CommonBTN = (props: ICommonBTN) => {
  const classes = useStyles();
  return (
    <Box className={classes.box} style={{ maxWidth: props.maxWidth }}>
      <ButtonGroup variant={"contained"} fullWidth>
        <Button onClick={() => props.onClick()}>
          <Typography className={classes.typography}>{props.BtnText}</Typography>
        </Button>
      </ButtonGroup>
    </Box>
  );
};