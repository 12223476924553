import React, { useContext, useEffect, useRef, useState } from "react";
import { Drawer } from "../../../../components/core/Manager/SubComponents/Drawer";
import ManagerForm from "../../../../components/core/Manager/SubComponents/ManagerForm";
import { Manager } from "../../../../components/core/Manager/Manager";
import InputDialog from "../../../../components/core/dialogs/InputDialog";
import { useSnackbar } from "notistack";
import { useApiClient } from "../../../../common/apiclients";
import {
  QueueChoiceVm,
  QueueClient,
  PersonsClient,
  PersonSearchQueryViewModel,
  UserOutcomeViewModel,
} from "../../../../common/client";
import { User } from "../../../../state/State.model";
import { CommonBTN } from "../../../../components/core/Buttons/CommonBTN";
import { Style } from "@material-ui/icons";
import { AppBarIconButton, AppBarTextButton } from "../../../../components/muiCore/Button";
import { AppBarDivider } from "../../../../components/core/Manager/SubComponents/ManagerToolBar";
import { fontSize, textAlign } from "@material-ui/system";
import { ToggleButtonGroup } from "@material-ui/lab";
import { searchStaffMembers } from "../../../../data/api";
import { Context } from "../../../../state/Store";

//was working on showing the selected outcome in the bottom right

export const OutcomeAccess = () => {
  const { enqueueSnackbar } = useSnackbar();
  // text in search bar
  const [searchBarText, setSearchBarText] = useState<string>("");
  // current items being displayed on the bottom right section => objects
  const [currentItems, setCurrentMembers] = useState<any[]>([]);
  // selected items on the bottom right section, a subset of currentItems
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  // selected nodes from the check box tree component => id
  const [selectedNode, setSelectedNode] = useState<any>();
  // selected grouping WT or Lil
  const [grouping, setGrouping] = useState<string>("");
  // name of the currently displayed manager
  const [managerName, setManagerName] = useState<string>("");
  // teams => array of objects
  const [teams, setTeams] = useState<any[]>([]);
  const [outcomeId, setOutcomeId] = useState<string>("");

  const [editTeamNameOpen, setEditTeamNameOpen] = useState<boolean>(false);
  const [outcomes, setOutcomes] =
    useState<{ [key: string]: QueueChoiceVm[] }>();

  // team manager form
  const formDrawer = useRef<any>(null);

  const queueClient = useApiClient(QueueClient);
  const personsClient = useApiClient(PersonsClient);

  let inputDialogData = {
    value: selectedNode
      ? teams.filter((x: any) => x.id === selectedNode)[0].name
      : "",
    title: "Edit",
    inputPlaceholder: "Team Name",
    open: editTeamNameOpen,
    onClose: () => {},
    onSubmit: () => {},
  };

    const [alignment, setAlignment] = React.useState<string>('');
    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    function changeGrouping(newGrouping: string) {
        setGrouping(newGrouping);
        if (newGrouping === 'WT') { setManagerName(newGrouping); }
        else { setManagerName("Schools/Clinics - " + newGrouping); }
    }

    let AppBarComponents =
        <>
            <div>
                <div style={{ fontSize: "1rem", textAlign: "center" }}>North</div>
                <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleAlignment}>
                    <AppBarTextButton value="left north" title="WT" onClick={() => changeGrouping("WT")}>WT</AppBarTextButton>
                    <AppBarTextButton value="center north" title="LiL North" onClick={() => changeGrouping("LiL North")}>LiL</AppBarTextButton>
                    <AppBarTextButton value="right north" title="CFLC North" onClick={() => changeGrouping("CFLC North")}>CFLC</AppBarTextButton>
                </ToggleButtonGroup>
            </div>
            <AppBarDivider flexItem orientation="vertical" />
            <div>
                <div style={{ fontSize: "1rem", textAlign: "center" }}>South</div>
                <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleAlignment}>
                    <AppBarTextButton value="left south" title="WT" onClick={() => changeGrouping("WT")}>WT</AppBarTextButton>
                    <AppBarTextButton value="center south" title="LiL South" onClick={() => changeGrouping("LiL South")}>LiL</AppBarTextButton>
                    <AppBarTextButton value="right south" title="CFLC South" onClick={() => changeGrouping("CFLC South")}>CFLC</AppBarTextButton>
                </ToggleButtonGroup>
            </div>
        </>;

  let appBarRightComponents;
  let disableToolBar =
    !selectedNode;

  useEffect(() => {
    loadData();
    treeDataMapper();
  }, []);

  async function loadData() {
      queueClient.getQueueOutcomes().then((outcomes) => setOutcomes(outcomes));
  }

  function onAddTeamFormSubmit() {}

  function treeDataMapper() {
    let data: any[][] = [];
    if (outcomes !== undefined) {
        data = Object.values(outcomes);
        data[0].sort((a, b) => a.name.localeCompare(b.name));

        // Handle CFLC Outreach
        data[0].forEach(item => {
            if (item.grouping === 'CFLC Outreach') {
                item.grouping = item.description;
            }
        });

        data[0] = data[0].filter(item => item.grouping === grouping);

      return data[0].map((x: any) => {
        return {
          value: x.id,
          label: x.name,
        };
      });
    }
    return [];
  }

  //function matchQuery(currentItems: any[]) {
  //  if (currentItems.length !== 0) {
  //    let node = treeDataMapper()
  //      .filter((x: any) => x.value == currentItems[0].id)
  //      .map((node) => {
  //        console.log(node);
  //        return node;
  //      });
  //      return node;
  //  }
  //  return [];
  //  }

    const matchQuery = (currentArr: any[]) => {
        const matcher = (name: string, query: string) => {
            if (!query || query === "") return true;

            // remove space in name
            const newName = name.replace(/\s/g, "").toLowerCase();
            const newQuery = query.replace(/\s/g, "").toLowerCase();
            return newName.includes(newQuery);
        };

        return currentArr.filter((x: any) => matcher(x.firstName + x.lastName, searchBarText));
    };

  function onResultSelect(selectedId: string) {
    selectedItems.find((x: any) => x == selectedId)
      ? setSelectedItems(selectedItems.filter((x) => x !== selectedId))
          : setSelectedItems([...selectedItems, selectedId]);
  }

  function onCheckBoxChecked(node: any) {
    setSelectedItems([]);
    if (node[0]) {
      setOutcomeId(node[0]);
      queueClient.getUserOutcomesOutcome(node[0]).then((response) => {
        setCurrentMembers(response);
      });
    } else {
      setOutcomeId("");
      setCurrentMembers([]);
      }
  }

  async function onAdd(persons: any) {
    const userOutcomes: UserOutcomeViewModel[] = persons.map((x: any) => {
      let temp: UserOutcomeViewModel = {
        personId: x.id,
        queueOutcomeChoiceId: outcomeId,
      };
      return temp;
    });
    await queueClient
      .setUserOutcomes(userOutcomes)
      .then((response) => {
        enqueueSnackbar("Person(s) successfully added", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar("Error occourd while adding person(s)", {
          variant: "error",
        });
      });

    setTimeout(() => {}, 500);
    queueClient.getUserOutcomesOutcome(outcomeId).then((response) => {
      setCurrentMembers(response);
    });
  }

  async function onRemove() {
    if (selectedItems.length > 0){
    const userOutcomes: UserOutcomeViewModel[] = selectedItems.map((x: any) => {
      let temp: UserOutcomeViewModel = {
        personId: x,
        queueOutcomeChoiceId: outcomeId,
      };
      return temp;
    });
    
    await queueClient
      .removeUserOutcome(userOutcomes)
      .then((response) => {
        enqueueSnackbar("Person(s) successfully removed", {
          variant: "success",
        });
      })
      .catch((err) => {
        enqueueSnackbar("Error occourd while removed person(s)", {
          variant: "error",
        });
      });

    setTimeout(() => {}, 500);
    queueClient.getUserOutcomesOutcome(outcomeId).then((response) => {
      setCurrentMembers(response);
    });
    setSelectedItems([]);
  }
  }

  function onItemSearch(searchString: string): Promise<any> {
    const person: PersonSearchQueryViewModel = {
      query: searchString,
      accountType: undefined,
    };

    return personsClient.searchPersons(person);
    }

  return (
    <>
      <Drawer
        ref={formDrawer}
        toolbar={true}
        anchor={"right"}
        title="Add a new team"
      >
        <ManagerForm onSucceed={onAddTeamFormSubmit} />
      </Drawer>
      <InputDialog {...inputDialogData} />
          <Manager
              name={managerName}
              appbarExtraComponents={AppBarComponents}
              appbarRightComponents={appBarRightComponents}
              treeData={treeDataMapper()}
              disableToolBar={!(outcomeId.length > 0)}
              currentItems={matchQuery(currentItems)}
              selectedItems={selectedItems}
              onResultSelect={onResultSelect}
              onSearchBarSearch={setSearchBarText}
              onCheckBoxChecked={onCheckBoxChecked}
              onAdd={onAdd}
              onRemove={onRemove}
              onItemSearch={onItemSearch}
              noResultsMessage="Select Required Clinic From Menu Bar"
          />
    </>
  );
};
