import React, { useContext, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { ValidationTextField } from "../components/muiCore/TextField";
import Button from "@material-ui/core/Button";
import { CircularProgress, Divider } from "@material-ui/core";
import { MaskSolid } from "../components/Icons/DoHIcons";
import { getJwtToken, searchAddresses } from "../data/api";
import { PersonKind } from "../state/State.model";
import { Context } from "../state/Store";
import { useHistory, useLocation } from "react-router";
import { Autocomplete } from "@material-ui/lab";
import { ValidatedForm } from "../components/core/forms/ValidatedForm";
import { useAuth } from "../auth/useAuth";
import { googleMaps } from "../components/core/maps/maps";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    // Thomas changed teh 60 to 100
    width: "100px",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      width: "64px",
      height: "64px",
    },
  },
  heading: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem!important",
      // added the center
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.3rem!important",
      fontWeight: "600!important",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
  },
  icon: {
    //fontSize: '2rem!important',
    color: theme.palette.primary.contrastText,
    //maxWidth: 'unset',
    //minWidth: '40px',
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem!important",
      margin: "10pxImportant",
    },
    fontSize: "3rem!important",
    margin: "10pxImportant",
  },
}));

export const Registration = (props: any) => {
  const classes = useStyles();
  let history = useHistory();
  const region = new URLSearchParams(useLocation().search).get("region");
  useEffect(() => {
    if (!region) {
      history.push("/");
    }
  });

  const { state, dispatch } = useContext(Context);
  // Get auth state and re-render anytime it changes
  const auth = useAuth();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [address, setAddress] = useState("");
  const [longitude, setLongitude] = useState<number>();
  const [latitude, setLatitude] = useState<number>();
  const [gpPractice, setGPPractice] = useState("");
  const [gpName, setGPName] = useState("");
  const [addressOptions, setAddressOptions] = React.useState([]);

  const [cbxRememberMe, setCbxRememberMe] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    mobile: {
      valid: true,
      errorMessage: "",
    },
    firstName: {
      valid: true,
      errorMessage: "",
    },
    lastName: {
      valid: true,
      errorMessage: "",
    },
    dateOfBirth: {
      valid: true,
      errorMessage: "",
    },
    address: {
      valid: true,
      errorMessage: "",
    },
  });
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCbxRememberMe(event.target.checked);
  };

  useEffect(() => {
    if (address && address.length > 3) {
      searchAddresses(address)
        .then((d) => setAddressOptions(d))
        .catch((e) => setAddressOptions([]));
    }
  }, [address]);

  const isFormValid = (): boolean => {
    let valid = true;
    let errors = { ...formErrors };

    if (!firstName) {
      errors.firstName.valid = false;
      errors.firstName.errorMessage = "First name can not be blank";
      valid = false;
    } else {
      errors.firstName.valid = true;
      errors.firstName.errorMessage = "";
    }

    if (!lastName) {
      errors.lastName.valid = false;
      errors.lastName.errorMessage = "Last name can not be blank";
      valid = false;
    } else {
      errors.lastName.valid = true;
      errors.lastName.errorMessage = "";
    }

    if (!contactNumber) {
      errors.mobile.valid = false;
      errors.mobile.errorMessage = "Contact number can not be blank";
      valid = false;
    } else {
      errors.mobile.valid = true;
      errors.mobile.errorMessage = "";

      if (!/^[0][0-9]{9}$|^[1300][0-9]{9}$/.test(contactNumber)) {
        errors.mobile.valid = false;
        errors.mobile.errorMessage =
          "Please provide valid contact number 10 digits with no spaces - eg. 0400123456 or 0362291643";
        valid = false;
      }
    }

    if (!dateOfBirth) {
      errors.dateOfBirth.valid = false;
      errors.dateOfBirth.errorMessage = "Date Of Birth can not be blank";
      valid = false;
    } else if (!dobValidator(dateOfBirth)) {
      errors.dateOfBirth.valid = false;
      errors.dateOfBirth.errorMessage = "Date Of Birth invalid";
      valid = false;
    } else {
      errors.dateOfBirth.valid = true;
      errors.dateOfBirth.errorMessage = "";
    }

    if (!address) {
      errors.address.valid = false;
      errors.address.errorMessage = "Address can not be blank";
      valid = false;
    } else if (!addressValidator(address)) {
      errors.address.valid = false;
      errors.address.errorMessage = "Address can not start with space";
      valid = false;
    } else {
      errors.address.valid = true;
      errors.address.errorMessage = "";
    }

    setFormErrors(errors);
    return valid;
  };

  const dobValidator = (dob: string): boolean => {
    let dobObj = new Date(dob);
    let today = new Date();

    if (dobObj > today) {
      return false;
    }
    return true;
  };

  const addressValidator = (address: string): boolean => {
    if (address.charAt(0) === " ") {
      return false;
    }
    return true;
  };

  function addressAutocomplete(htmlElelment: HTMLDivElement | null) {
    if (htmlElelment) {
      googleMaps().then(() => {
        const autocomplete = new google.maps.places.Autocomplete(
          htmlElelment as HTMLInputElement,
          {
            componentRestrictions: { country: "au" },
            fields: ["formatted_address", "geometry", "name"],
            strictBounds: false,
            types: ["address"],
          }
        );
        autocomplete.addListener("place_changed", () => {
          if (autocomplete.getPlace().geometry) {
            setLongitude(autocomplete.getPlace().geometry?.location?.lng());
            setLatitude(autocomplete.getPlace().geometry?.location?.lat());
          }

          if (autocomplete.getPlace().formatted_address)
            setAddress(autocomplete.getPlace().formatted_address!);
        });
      });
    }
  }

  const onSubmit = () => {
    if (isFormValid()) {
      getJwtToken(firstName, lastName, contactNumber, PersonKind.Public, {
        dateOfBirth: dateOfBirth,
        address: address,
        latitude: latitude,
        longitude: longitude,
        gpName: gpName,
        gpPractice: gpPractice,
      })
        .then((d) => {
          dispatch({
            type: "SET_USERINFO",
            payload: {
              ...state.user,
              ...d.user,
              token: d.token,
            },
          });
          if (d.user?.activePass != null) {
          } else {
            history.push(
              "/questionnaire?region=" + encodeURIComponent(region!)
            );
          }
        })
        .catch((err) => {
          //    console.log(err);
          //  console.debug(`PersonasContainer::Dispatch::SET_ERROR`);
          setLoading(false);
        });
    } else {
      //console.log(auth);
      setLoading(false);
    }
  };
  return (
    <Container component="main" maxWidth="sm" style={{ marginBottom: "60px" }}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <MaskSolid className={classes.icon} />
        </Avatar>
        <Typography className={classes.heading}>
          COVID-19 Test Registration Information
        </Typography>
        <ValidatedForm>
          <ValidationTextField
            error={!formErrors.firstName.valid}
            helperText={formErrors.firstName.errorMessage}
            margin="normal"
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            autoFocus
            required
            onChange={(e: any) => {
              setFirstName(e.target.value);
            }}
            variant="outlined"
            disabled={loading}
          />
          <ValidationTextField
            error={!formErrors.lastName.valid}
            helperText={formErrors.lastName.errorMessage}
            margin="normal"
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            required
            onChange={(e: any) => {
              setLastName(e!.target.value);
            }}
            variant="outlined"
            validation={{ required: true }}
            disabled={loading}
          />
          <ValidationTextField
            variant="outlined"
            error={!formErrors.mobile.valid}
            helperText={
              !formErrors.mobile.valid ? formErrors.mobile.errorMessage : ""
            }
            margin="normal"
            fullWidth
            id="mobile"
            label="Phone number (mobile preferred)"
            name="mobileNumber"
            required
            onChange={(e: any) => {
              setContactNumber(e.target.value);
            }}
            //value={txtMobile || ''}
            type={"tel"}
            disabled={loading}
          />
          <ValidationTextField
            variant="outlined"
            error={!formErrors.dateOfBirth.valid}
            helperText={
              !formErrors.dateOfBirth.valid
                ? formErrors.dateOfBirth.errorMessage
                : ""
            }
            margin="normal"
            fullWidth
            id="dateOfBirth"
            label="Date of Birth"
            name="dateOfBirth"
            required
            onChange={(e: any) => {
              setDateOfBirth(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
            type={"date"}
            disabled={loading}
          />
          <Autocomplete
            style={{ marginTop: "16px" }}
            id="addressAutocomplete"
            disabled={loading}
            options={addressOptions}
            noOptionsText="No locations found"
            freeSolo
            onChange={(event: any, val: any) => {
              console.log(val);
              setAddress(val);
            }}
            //onSelect={(event: any, value: string) => setAddress(value)}
            renderInput={(params) => (
              <ValidationTextField
                {...params}
                required
                value={address}
                error={!formErrors.address.valid}
                helperText={
                  !formErrors.address.valid
                    ? formErrors.address.errorMessage
                    : ""
                }
                label="Residential Address"
                variant="outlined"
                disabled={loading}
                onChange={(e: any) => {
                  setAddress(e.target.value);
                }}
                //value={address}
              />
            )}
          />
          <ValidationTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="gpName"
            label="GP Name"
            name="gpName"
            onChange={(e: any) => {
              setGPName(e.target.value);
            }}
            disabled={loading}
          />
          <ValidationTextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="gpPractice"
            label="Practice"
            name="gpPractice"
            onChange={(e: any) => {
              setGPPractice(e.target.value);
            }}
            disabled={loading}
          />
          <br />
          <Button
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            onClick={(
              event: React.MouseEvent<HTMLButtonElement, MouseEvent>
            ) => {
              event.preventDefault();
              setLoading(true);
              onSubmit();
            }}
          >
            {loading && <CircularProgress style={{ color: "white" }} />}
            {!loading && "Next"}
          </Button>
        </ValidatedForm>
      </div>
      <Divider variant="middle" />
    </Container>
  );
};
