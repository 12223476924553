import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ClosableDialogTitle } from "../Queue";
import { buildGroup, useControlValue } from "@react-typed-forms/core";
import {
  AssignToQueueViewModel,
  QueueChoiceVm,
  QueueClient,
  QueueWorkItemStatus,
} from "../../../../common/client";
import { useSnackbar } from "notistack";
import { useApiClient } from "../../../../common/apiclients";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { FRadioList } from "../../../../common/formComponents/FRadioList";
import { FCheckList } from "../../../../common/formComponents/FCheckList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogRoot: {
      padding: "0 !important",
      minWidth: "400px",
    },
  })
);

const FormDef = buildGroup<AssignToQueueViewModel>()({
  outcomeIds: [],
  newQueueId: "",
});

export function AssignToQueueDialog({
  onClose,
  onAssignAndClose,
  open,
  queues,
  currentQueueId,
  workItemId,
  currentQueueName,
}: {
  onClose: () => void;
  onAssignAndClose: () => void;
  queues: any[];
  open: boolean;
  workItemId?: string;
  currentQueueId: string;
  currentQueueName: string;
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [form] = useState(FormDef);
  const fields = form.fields;
  const selectedQueueId = useControlValue(fields.newQueueId);
  const client = useApiClient(QueueClient);
  const [outcomes, setOutcomes] = useState<{ [id: string]: QueueChoiceVm[] }>();

  useEffect(() => {
    if (open) {
      form.setValue({ outcomeIds: [], newQueueId: "" }, true);
    }
  }, [open]);

  useEffect(() => {
    client.getQueueOutcomes().then(setOutcomes);
  }, []);

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <ClosableDialogTitle id="customized-dialog-title" onClose={onClose}>
        Assign to queue
      </ClosableDialogTitle>
      <DialogContent dividers classes={{ root: classes.dialogRoot }}>
        <Box m={2}>
          <FRadioList label={""} state={fields.newQueueId}>
            {(checkProps) =>
              queues
                .filter((x: any) => x.id !== currentQueueId)
                .map((x: any) => {
                  if (currentQueueName == "Hotline" && x.name == "IMT") {
                  } else {
                    const p = checkProps(x.id);
                    return (
                      <>
                        <FormControlLabel
                          control={<Radio {...p} />}
                          label={x.name}
                        />
                                                {Boolean(outcomes?.[x.id]?.length) && (
                                                    <Box mx={4}>
                                                        <FCheckList label="" state={fields.outcomeIds}>
                                                            {(cp) =>
                                                                outcomes![x.id].filter((o)=>o.assignable).map((o) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...cp(o.id)}
                                                                                disabled={!p.checked}
                                                                            />
                                                                        }
                                                                        label={o.name}
                                                                    />
                                                                ))
                                                            }
                                                        </FCheckList>
                                                    </Box>
                                                )}
                      </>
                    );
                  }
                })
            }
          </FRadioList>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onAssignFormSubmit(false);
            onClose();
          }}
          disabled={!selectedQueueId && fields.newQueueId.value != ""}
        >
          Assign
        </Button>
        <Button
          onClick={() => {
            onAssignFormSubmit(true);
            onAssignAndClose();
          }}
          disabled={!selectedQueueId && fields.newQueueId.value != ""}
        >
          Assign and Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  async function onAssignFormSubmit(closeWorkitem: boolean) {
    if (selectedQueueId) {
      const { newQueueId, outcomeIds } = form.toObject();
      const allowedOutcomes =
        (outcomes && outcomes[newQueueId]?.map((o) => o.id)) ?? [];
      const res = await client.assignItemToNewQueue(
        currentQueueId,
        workItemId!,
        {
          newQueueId,
          outcomeIds: outcomeIds.filter((o) => allowedOutcomes.includes(o)),
        }
      );

      if (res) {
        enqueueSnackbar("New work item assigned", { variant: "success" });
      }
      // } else {
      //   enqueueSnackbar("Work item already exists in the selected queue", {
      //     variant: "info",
      //   });
    }
  }
}
