import React, { useState } from "react";
import { ListItem, ListItemIcon, ListItemText, Divider, makeStyles, withStyles, Grid, Button, ButtonGroup, Typography, Collapse } from "@material-ui/core";
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import TextField from '@material-ui/core/TextField';



//A version of QuestionChoiceCheckBox that will be adding our dependants 

const useStyles = makeStyles((theme) => ({
    btnCheckbox: {
        paddingBottom: 16,
    },
    box: {
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            bottom: '0px',
            left: '0px',
            width: '100%'
        },
        display: "flex!important",
        textAlign: 'center',
        maxWidth: '660px',//Default width overriden by props.maxWidth
        margin: "auto",
    },
    btnActive: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    btnStartIcon: {
        width: '35px',
        height: '35px'
    }, textField: {
        fontSize: '1.25rem',
        lineHeight: ' 1.4375rem',
        letterSpacing: '-.01875rem',

        [theme.breakpoints.up("sm")]: {
            fontSize: '1.25rem',
            lineHeight: ' 1.4375rem',
            letterSpacing: '-.01875rem'
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: '16px!important',
        }

    }
}));



const ValidationTextField = withStyles({
    root: {
        '& .MuiFormHelperText-root': {
            fontSize: '0.9rem!Important'
        }
    },
})(TextField);

export const Dependant = (props: any) => {

    const isFormValid = (): boolean => {
        let valid = true;
        let errors = { ...formErrors }

        if (!txtFirstName) {
            errors.firstName.valid = false;
            errors.firstName.errorMessage = 'First name can not be blank';
            valid = false
        }
        else {
            errors.firstName.valid = true;
            errors.firstName.errorMessage = '';
        }

        if (!txtLastName) {
            errors.lastName.valid = false;
            errors.lastName.errorMessage = 'Last name can not be blank';
            valid = false
        }
        else {
            errors.lastName.valid = true;
            errors.lastName.errorMessage = '';
        }

        setFormErrors(errors);
        return valid;
    }

    const onSubmit = () => {
        if (isFormValid()) {
            console.log('form valid');
            setIsAddingDependant(!isAddingDependant);
            setTxtFirstName("");
            setTxtLastName("");

            if (props.submit) {
                props.submit(txtFirstName, txtLastName);
            }
        }
        else {
            console.log('form not valid')
        }
    }

    //Component State
    const [txtFirstName, setTxtFirstName] = useState<string>();
    const [txtLastName, setTxtLastName] = useState<string>();
    const [formErrors, setFormErrors] = useState({
        firstName: {
            valid: true,
            errorMessage: ''
        },
        lastName: {
            valid: true,
            errorMessage: ''
        }
    });
    const classes = useStyles();

    const [isAddingDependant, setIsAddingDependant] = useState<boolean>(false);

    const renderForm = () => {
        return (
            <>
                <form onSubmit={(event) => {
                    event.preventDefault();
                }}>

                    <Grid container justify={'space-around'} spacing={1} >
                        <Grid item xs={11}>
                            <ValidationTextField
                                error={!formErrors.firstName.valid}
                                helperText={formErrors.firstName.errorMessage}
                                className={classes.textField}
                                margin="normal"
                                fullWidth
                                id="firstName"
                                label="First Name"
                                name="firstName"
                                autoFocus
                                required
                                onChange={(e) => {
                                    setTxtFirstName(e.target.value)
                                }}
                                variant="outlined"
                            />
                            <ValidationTextField
                                variant="outlined"
                                error={!formErrors.lastName.valid}
                                helperText={formErrors.lastName.errorMessage}
                                className={classes.textField}
                                margin="normal"
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                required
                                onChange={(e) => {
                                    setTxtLastName(e.target.value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.btnCheckbox}>
                            <Button
                                fullWidth={true}
                                variant={"contained"}
                                type="submit"
                                onClick={() => {
                                    onSubmit()
                                }}
                            >Save Dependant
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </>
        )
    }

    const renderAddDependantButton = () => {
        return (
            <>
                <ButtonGroup
                    variant={'contained'}
                    fullWidth>
                    className={`${props.response === "Add a Dependant" ? classes.btnActive : ''}`}

                    <Button onClick={() => {
                        setIsAddingDependant(!isAddingDependant);
                    }}>
                        <Typography>
                            Add a Dependant
                        </Typography>
                    </Button>
                </ButtonGroup>
            </>
        )
    }

    const render = () => {
        return (
            <div>
                <Collapse collapsedHeight={63} in={isAddingDependant}>
                    {!isAddingDependant ? renderAddDependantButton() : renderForm()}
                </Collapse>
                <Divider />
            </div>
        )
    }
    //{ isAddingDependant ? null : renderAddDependantButton()}

    return (
        render()
    );
}

