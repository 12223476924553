import { createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

type QuestionnaireContextType = {
    questionnaireTitle: string;
    setQuestionnaireTitle: Dispatch<SetStateAction<string>>;
};

export const QuestionnaireContext = createContext<QuestionnaireContextType>({
    questionnaireTitle: "",
    setQuestionnaireTitle: () => { }
});

export const QuestionnaireProvider = ({ children }: any) => {
    const [questionnaireTitle, setQuestionnaireTitle] = useState("");

    return (
        <QuestionnaireContext.Provider value={{ questionnaireTitle, setQuestionnaireTitle }}>
            {children}
        </QuestionnaireContext.Provider>
    );
};

export const useQuestionnaireContext = () => {
    const context = useContext(QuestionnaireContext);
    if (context === undefined) {
        throw new Error('useQuestionnaireContext must be used within a QuestionnaireProvider');
    }
    return context;
};
