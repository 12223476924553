import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import MaskedInput from "react-text-mask";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "99%", // Fix IE11 issue.
    marginTop: "6px",
    marginLeft: "4px",
    marginBottom: "6px",
    border: "1px 1px !important",
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },
    
  },
}));

const datemask = (props: any) => {
  let date = "";
  const { inputRef, ...other } = props;

  const checkDays = () => {
    return date[0] === "3" ? /[0-1]/ : /[0-9]/;
  };

  const checkMonths = () => {
    return date[3] === "1" ? /[0-2]/ : /[0-9]/;
  };

  return (
    <MaskedInput
      {...other}
      value={other.value.value === undefined ? "" : other.value.value}
      mask={[
        /[0-3]/,
        checkDays(),
        "/",
        /[0-1]/,
        checkMonths(),
        "/",
        /[2]/,
        /[0]/,
        /[0-9]/,
        /[0-9]/,
      ]}
      guide={true}
      id="my-input-id"
    />
  );
};

export const ValidatedformWithDateFormatting = (props: any) => {
  const classes = useStyles();
  const handleChange = (event: any) => {
    props.setDate(event.target.value);
  };
  console.log("props", props)
  // if (props.required){
  //   props.date.value.value === undefined ? error = true : error = false
  // }
  return (
    <>
      <div>
        <TextField
          
          disabled={props.readonly}
          value={props.date}
          onChange={handleChange}
          name="textmask"
          id="formatted-text-mask-input"
          InputProps={{
            inputComponent: datemask,
            inputProps: {
              component: props.date,
            },
          }}
          variant="outlined"
          className={classes.form}
          error={Boolean(props.date.error)}
          {...props}
          helperText={props.date.error ?? props.date.error}
          autoComplete="no"
        />
      </div>
    </>
  );
};
