import { buildGroup, control, ControlType } from "@react-typed-forms/core";
import { Box, Grid } from "@material-ui/core";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { StyledTextField, ValidationTextField } from "../../muiCore/TextField";
import {
  Address,
  AddressClient,
  AddressViewModel,
} from "../../../common/client";
import { useApiClient } from "../../../common/apiclients";
import { useDebounce } from "../../../common/debounce";
import { FTextField } from "../../../common/formComponents/FTextField";

export function makeAddressFormDef(required: boolean) {
  const validate = required
    ? (c: string) =>
        !c
          ? "Cannot be blank"
          : c.charAt(0) === " "
          ? "Cannot start with a space"
          : undefined
    : undefined;
  return buildGroup<AddressViewModel>()({
    addressUnit: "",
    addressLine: control("", validate),
    suburb: control("", validate),
    postcode: control("", validate),
    state: control("", validate),
  });
}

type AddressControl = ControlType<ReturnType<typeof makeAddressFormDef>>;

export function AddressComplete(props: {
  state: AddressControl;
  query?: string;
  label?: string;
  required?: boolean;
}) {
  const [addressText, setAddressText] = useState(
    props.query ?? toFullAddressLine(props.state.toObject())
  );
  const [loading, setLoading] = useState(false);
  const addressQuery = useDebounce(addressText, 500);
  const [addressOptions, setAddressOptions] = useState<Address[]>();
  const addressClient = useApiClient(AddressClient);
  const fields = props.state.fields;
  useEffect(() => {
    if (addressQuery) {
      doSearch();
    }
  }, [addressQuery]);

  return (
    <Box>
      <Autocomplete
        style={{ marginTop: "16px" }}
        loading={loading}
        options={addressOptions ?? []}
        noOptionsText="No addresses found"
        freeSolo
        inputValue={addressText}
        getOptionLabel={(a) => a.formattedAddress ?? ""}
        onInputChange={(e, val, reason) => {
          if (reason !== "reset") {
            setAddressText(val);
          }
        }}
        onChange={(event: any, val: string | Address | null, change) => {
          if (typeof val === "object" && change === "select-option") {
            fields.addressUnit.setValue("");
            fields.postcode.setValue(val!.postalCode ?? "");
            fields.addressLine.setValue(val!.addressLine ?? "");
            fields.state.setValue(val!.adminDistrict ?? "");
            fields.suburb.setValue(val!.locality ?? "");
            setAddressText(toFullAddressLine(props.state.toObject()));
          }
        }}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            required={props.required}
            label={props.label ?? "Type Address"}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Box>
  );

  async function doSearch() {
    setLoading(true);
    setAddressOptions(await addressClient.searchFull(addressQuery));
    setLoading(false);
  }
}

export function toFullAddressLine({
  addressUnit,
  addressLine,
  suburb,
  postcode,
  state,
}: AddressViewModel) {
  return [addressUnit, addressLine, suburb, postcode, state]
    .filter((d) => Boolean(d))
    .join(", ");
}

export function RenderAddressFields({
  state: { fields },
  readonly,
}: {
  state: AddressControl;
  readonly?: boolean;
}) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={1}>
        <FTextField
          state={fields.addressUnit}
          label={"Unit"}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid item xs={3}>
        <FTextField
          state={fields.addressLine}
          label={"Address Line"}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid item xs={3}>
        <FTextField
          state={fields.suburb}
          label={"Suburb"}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid item xs={2}>
        <FTextField
          state={fields.postcode}
          label={"Postcode"}
          fullWidth
          disabled={readonly}
        />
      </Grid>
      <Grid item xs={3}>
        <FTextField
          state={fields.state}
          label={"State"}
          fullWidth
          disabled={readonly}
        />
      </Grid>
    </Grid>
  );
}
