import React, {useEffect, useState} from 'react'
import {StyledContainer} from "../muiCore/Container";
import {Box, ButtonBase, Grid, Paper, Typography, useTheme, useMediaQuery} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
// thomas added
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import cflc from '../../assets/images/cflc.jpg';

import appBanner from "../../assets/images/banner_covid.svg";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        footer: {
            //Thomas 28/06/21
            backgroundColor: '#353535',
            // backgroundColor:'#133650',
            //backgroundColor: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
            minHeight: '150px',
            padding: theme.spacing(2, 0)
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "start",
            color: theme.palette.primary.contrastText,
            backgroundColor: 'inherit',
            boxShadow: 'unset',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            }

            //height: "70%"
        },
        icon: {
            //color: theme.palette.primary.contrastText,
            maxWidth: 'unset',
            maxHeight: 'unset',
            fontSize: '4.5rem!important',
            //Thomas changed the color from theme
            color: theme.palette.primary.contrastText,
            //margin: '15px!important',
        },
        img: {
            height: '8rem',
            backgroundColor: 'inherit',
            filter: 'invert(100%)',
            // thomas added
            [theme.breakpoints.down('sm')]: {
                height: '4rem',
            },
        },
        cflcImg: {
            height: '12rem',
            backgroundColor: 'inherit',
            //filter:'invert(100%)',
            // thomas added
            [theme.breakpoints.down('sm')]: {
                height: '4rem',
            },
        },
        // thomas added
        contentText: {
            color: theme.palette.primary.contrastText,
        }
    }),
);

export const Footer = (props: any) => {

    let classes = useStyles();


    // thomas modified
    let theme = useTheme();

    const isThemeDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box className={classes.footer}>
            <StyledContainer style={{ marginRight: "0px", marginLeft: "0px", maxWidth: "100%" }}>
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="flex-end"
                >
                    <Grid item lg={4}>
                        <Paper style={{ textAlign: "start" }} className={classes.paper}>
                            <ButtonBase onClick={() => {
                            }}>
                                <img
                                    className={classes.img}
                                    src="/tas-gov_bw.svg"
                                    alt="Site Logo" />
                            </ButtonBase>
                        </Paper>
                    </Grid>
                    <Grid item lg={5} xs={12}>
                        <Box paddingRight={2} paddingLeft={2} textAlign={`${isThemeDown ? 'center' : 'right'}`}>
                            {/* <Box paddingRight={2} className={classes.textStyleForFooter}> */}

                            {/* Thomas add style */}
                            {/*<Typography className={classes.contentText} display="block" gutterBottom>Need an interpreter?</Typography>*/}
                            {/*<Typography variant="caption" display="block"  >Phone the Translating and Interpreting Service (TIS) on <Typography variant="caption" inputMode={'url'}><a href="sip:131 450">131 450</a></Typography> and tell them your language. Tell the interpreter your name and that you’re calling the Tasmanian Department of Health <Typography variant="caption" inputMode={'url'}><a href="sip:1800 671 738">1800 671 738</a></Typography>. </Typography>*/}
                            {/*<Typography variant="caption" display="block" >*/}
                            {/*    You are directed to the <Typography variant="caption" inputMode={'url'}>*/}
                            {/*        <a href="http://www.tas.gov.au/stds/codi.htm">Disclaimer and copyright notice notice</a>*/}
                            {/*    </Typography> and the <Typography variant="caption" inputMode={'url'}>*/}
                            {/*    <a href="http://www.tas.gov.au/stds/pip.htm">Personal Information Protection statement</a>*/}
                            {/*</Typography> governing the information provided. */}
                            {/*</Typography>*/}
                            <Typography variant="caption" display="block">
                                This page has been produced by the <Typography variant="caption" inputMode={'url'}>
                                <a href="http://www.education.tas.gov.au/">Department of Education</a>
                            </Typography>.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </StyledContainer>
        </Box>
    )
}