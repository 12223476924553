import { buildGroup, control, ControlType } from "@react-typed-forms/core";
import { SubQuestionEditModel } from "../../../../common/client";
import { FTextField } from "../../../../common/formComponents/FTextField";
import { Box, Button, Grid } from "@material-ui/core";
import { FIconSelector } from "../../../../common/formComponents/FIconSelector";

export const SubQuestionFormDef = buildGroup<SubQuestionEditModel>()({
  id: control(undefined),
  icon: "",
  text: "",
});

export type SubQuestionForm = ControlType<typeof SubQuestionFormDef>;

export function SubQuestionEditor({
  state,
  onDelete,
}: {
  state: SubQuestionForm;
  onDelete: () => void;
}) {
  const fields = state.fields;
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FTextField state={fields.text} label="Text" fullWidth />
      </Grid>
      <Grid item xs={3}>
        <FIconSelector state={fields.icon} />
      </Grid>
      <Grid item xs={1}>
        <Button onClick={onDelete}>Delete</Button>
      </Grid>
    </Grid>
  );
}
