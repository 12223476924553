import {withStyles} from "@material-ui/core/styles";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";

export const Dialog = withStyles({

})(MuiDialog);
export const DialogContent = withStyles({
    root: {
        padding:'0px'
    },
    dividers:{
        padding:'0px'
    }
})(MuiDialogContent);
export const DialogActions = withStyles({

})(MuiDialogActions);
export const DialogTitle = withStyles({

})(MuiDialogTitle);