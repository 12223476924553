import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core/styles";
import { DoHScreeningTheme } from "./themes/DoHScreeningTheme";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Store from "./state/Store";
import { SnackbarProvider } from "notistack";
import Dashboard from "./modules/admin/views/Dashboard";
import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";

const baseUrl =
  document.getElementsByTagName("base")[0].getAttribute("href") ?? undefined;

const formats = {
  normalDate: "d MMM yyy",
  keyboardDate: "d MMM yyy",
};

ReactDOM.render(
  <Router basename={baseUrl}>
    <ThemeProvider theme={DoHScreeningTheme}>
      <LocalizationProvider dateAdapter={DateFnsUtils} dateFormats={formats}>
        <Store>
          <SnackbarProvider maxSnack={3}>
            <Switch>
              <Route path="/admin">
                <Dashboard />
              </Route>
              <Route path="/">
                <App />
              </Route>
            </Switch>
          </SnackbarProvider>
        </Store>
      </LocalizationProvider>
    </ThemeProvider>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
