import { createRenderer } from "@react-typed-forms/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import { TextField } from "@material-ui/core";
import React from "react";

export const FNumberField = createRenderer<number | undefined, TextFieldProps>(
  (
    { state, helperText, ...props },
    { errorText, value, onChange, ...formProps }
  ) => (
    <TextField
      {...formProps}
      variant="outlined"
      value={typeof value !== "number" ? "" : value}
      error={Boolean(errorText)}
      {...props}
      helperText={errorText ?? helperText}
      type="number"
      onChange={(e) => {
        const num = parseFloat(e.target.value);
        state.setValue(isNaN(num) ? undefined : num);
      }}
    />
  )
);
