import React, { ReactNode } from "react";
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";

import { OutcomeHeaderTypography } from "./Typograpy";
import { isWhiteSpaceLike } from "typescript";
import { Variant } from "@material-ui/core/styles/createTypography";

const useStyles = makeStyles((theme) => ({
  subQuestionIcon: {
    marginRight: "18px",
    fontSize: "1.5em!important",

    "& :hover": {
      color: theme.palette.primary.main,
      //cursor: 'default'
    },
  },
  InfoActionIcon: {
    marginRight: "18px",
    fontSize: "3em!important",
    "& :hover": {
      color: theme.palette.primary.main,
    },
  },
  infoLink: {
    "& a": {
      textDecoration: "underline",
      color: theme.palette.primary.main + "!important",
    },
    "& a:hover": {
      textDecoration: "underline!important",
      [theme.breakpoints.up("sm")]: {
        color: theme.palette.primary.dark + "!important",
        borderBottom: "3px solid !important",
      },
    },
    "& a:visited": {
      textDecoration: "underline!important",
      color: theme.palette.primary.main + "!important",
    },
  },
  icon: {
    // Thomas 28/06/21 color: theme.palette.primary.light,
    //color: '#BAD97C',
    marginBottom: theme.spacing(1),
    maxWidth: "72px",
    maxHeight: "72px",
    width: "68px",
    height: "68px",
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up("sm")]: {
      maxWidth: "76px",
      maxHeight: "76px",
      width: "76px",
      height: "76px",
    },
    "&.info": {
      color: theme.palette.primary.contrastText,
    },
  },
  infoIcon: {
    color: "#BAD97C",
    marginBottom: theme.spacing(1),
    maxWidth: "72px",
    maxHeight: "72px",
    width: "68px",
    height: "68px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "76px",
      maxHeight: "76px",
      width: "76px",
      height: "76px",
    },
    "&.info": {
      color: theme.palette.primary.contrastText,
    },
  },
  w100: { width: "100%" },
  header: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "70px",
    fontWeight: 550,
    fontSize: theme.typography.fontSize,
    padding: "0.5em 0 0.5em 0.25em",
    display: "block",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em 0 0.5em 0.25em",
    },
    "&.New": {
      backgroundColor: theme.DoH.color.warning,
    },
    "&.Viewed": {
      backgroundColor: theme.DoH.color.exemption,
    },
    "&.OnHold": {
      backgroundColor: theme.DoH.color.fail,
    },
    "&.PDFDownloaded": {
      backgroundColor: theme.DoH.color.pass,
    },
    //marginBottom:theme.spacing(3)
  },
  infoHeader: {
    backgroundColor: theme.palette.primary.dark,
    minHeight: "70px",
    fontWeight: 550,
    fontSize: theme.typography.fontSize,
    padding: "0.5em 0 0.5em 0.25em",
    display: "block",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em 0 0.5em 0.25em",
    },
    "& span": {},
    "&.info": {
      backgroundColor: theme.DoH.color.warning,
    },
    marginBottom: theme.spacing(3),
  },
  pLR_15: {
    paddingLeft: "15px!important",
    paddingRight: "15px!important",
  },
}));
const StyledOutcomeHeaderListItem = withStyles((theme) => ({
  root: {
    backgroundColor: "#92b200", //theme.palette.primary.light,
    boxShadow:
      "0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)",
    minHeight: "70px",
    fontWeight: 550,
    fontSize: theme.typography.fontSize,
    padding: "0.5em 0 0.5em 0.25em",
    display: "block",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5em 0 0.5em 0.25em",
    },
    "& span": {},
  },
}))(ListItem);
export const SelectableInfoHeaderListItem = (props: any) => {
  const classes = useStyles();

  const StyledIcon = (): JSX.Element | null => {
    if (props.icon)
      return React.cloneElement(props.icon as JSX.Element, {
        className: classes.infoIcon,
      });
    return null;
  };

  // @ts-ignore
  return (
    <ListItem className={clsx(classes.infoHeader, props.variant ?? "")}>
      {props.icon && <StyledIcon />}
      <OutcomeHeaderTypography>{props.text}</OutcomeHeaderTypography>
    </ListItem>
  );
};
export const SelectableInfoDetailsListItem = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.w100}>
      <ListItem disableGutters={true} className={classes.pLR_15}>
        {props.icon && (
          <ListItemIcon className={classes.subQuestionIcon}>
            {props.icon}
          </ListItemIcon>
        )}
        <ListItemText>
          <Typography variant={props.textVariant}>{props.text}</Typography>
        </ListItemText>
      </ListItem>
      {props.divider && <Divider />}
    </div>
  );
};
export const SelectableInfoActionListItem = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.w100} style={{ marginTop: "auto" }}>
      <ListItem style={{ justifyContent: "flex-end" }}>
        {props.icon && (
          <ListItemIcon className={classes.InfoActionIcon}>
            {props.icon}
          </ListItemIcon>
        )}
      </ListItem>
      {props.divider && <Divider />}
    </div>
  );
};
export const OutcomeHeaderListItem = (props: any) => {
  const classes = useStyles();

  const StyledIcon = (): JSX.Element | null => {
    if (props.icon)
      return React.cloneElement(props.icon as JSX.Element, {
        className: clsx(classes.icon, props.variant ?? ""),
      });
    return null;
  };

  // @ts-ignore
  return (
    <ListItem className={clsx(classes.header, props.variant ?? "")}>
      {props.icon && <StyledIcon />}
      <OutcomeHeaderTypography>{props.text}</OutcomeHeaderTypography>
    </ListItem>
  );
};

export interface OutcomeDetailsListItemProps {
  text: ReactNode;
  icon?: ReactNode;
  textVariant?: Variant;
  divider?: boolean;
  editor?: ReactNode;
}

export const OutcomeDetailsListItem = (props: OutcomeDetailsListItemProps) => {
  const classes = useStyles();
  return (
    <div className={classes.w100}>
      <ListItem disableGutters={true} className={classes.pLR_15}>
        {props.icon && (
          <ListItemIcon className={classes.subQuestionIcon}>
            {props.icon}
          </ListItemIcon>
        )}
        <ListItemText>
          {props.editor ?? (
            <Typography variant={props.textVariant}>{props.text}</Typography>
          )}
        </ListItemText>
      </ListItem>
      {props.divider && <Divider />}
    </div>
  );
};
export const OutcomeActionListItem = (props: any) => {
  const classes = useStyles();
  return (
    <div className={classes.w100} style={{ marginTop: "auto" }}>
      <ListItem style={{ justifyContent: "flex-end" }}>
        {props.icon && (
          <ListItemIcon className={classes.subQuestionIcon}>
            {props.icon}
          </ListItemIcon>
        )}
      </ListItem>
      {props.divider && <Divider />}
    </div>
  );
};
