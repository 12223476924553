import { createContext, useContext } from "react";

const BASE_URL = "";

type ApiClientClass<A> = new (
  baseUrl?: string,
  http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
) => A;

export function useApiClient<A>(client: ApiClientClass<A>) {
  const token = useToken();
  return new client(BASE_URL, token ? { fetch: addToken(token) } : undefined);
}

export function useToken() {
  return useContext(TokenContext);
}

export const TokenContext = createContext<string | undefined>(undefined);

function addToken(token: string): (url: RequestInfo, init?: RequestInit) => Promise<Response> {
  return (url, init) => {
    init = init ?? {};
    const headers = new Headers(init.headers);
    headers.append("Authorization", "Bearer " + token);
    init.headers = headers;
    return window.fetch(url, init);
  };
}
