import {
  ArrayControl,
  arrayControl,
  buildGroup,
  control,
  ControlType,
  FormArray,
  GroupControl,
  GroupControlFields,
  useControlStateComponent,
  ValueTypeForControl,
} from "@react-typed-forms/core";

import {
  DateConstraint,
  QuestionAdminViewModel,
  QuestionEditModel,
  QuestionIntent,
  QuestionType,
  QuestionValidation,
} from "../../../../common/client";
import { FTextField } from "../../../../common/formComponents/FTextField";
import { FNumberField } from "../../../../common/formComponents/FNumberField";
import { Box, Button, Grid, MenuItem } from "@material-ui/core";
import { ChoiceFormDef } from "./ChoiceEditor";
import { SubQuestionFormDef } from "./SubQuestionEditor";
import { useDrag } from "react-dnd";
import { Droppable } from "./QuestionnaireEditor";
import { isoToDate } from "../../../../common/dates";

export type QuestionForm = GroupControl<
  GroupControlFields<ControlType<typeof BaseQuestionForm>> & {
    nestedQuestions: ArrayControl<QuestionForm>;
  }
>;

const DateConstraintFormDef = buildGroup<DateConstraint>()({
  days: control(undefined),
  fixed: control(undefined),
});

export type DateConstraintFormType = ControlType<typeof DateConstraintFormDef>;

const ValidationFormDef = buildGroup<QuestionValidation>()({
  notAfter: DateConstraintFormDef,
  notBefore: DateConstraintFormDef,
  textType: control(undefined),
});

const BaseQuestionForm = buildGroup<
  Omit<QuestionEditModel, "nestedQuestions">
>()({
  id: control(undefined),
  heading: "",
  intentKey: "",
  type: QuestionType.YesNo,
  choices: arrayControl(ChoiceFormDef),
  intent: QuestionIntent.None,
  hidden: false,
  questionKey: "",
  exportKey: "",
  required: false,
  validation: ValidationFormDef,
  subQuestions: arrayControl(SubQuestionFormDef),
});

export const QuestionFormDef: () => QuestionForm = () =>
  BaseQuestionForm().addFields({
    nestedQuestions: new ArrayControl(QuestionFormDef),
  });

function toDateConstraintForm(v?: DateConstraint): DateConstraint {
  if (!v) {
    return { fixed: undefined, days: undefined };
  }
  return { ...v, fixed: isoToDate(v.fixed) };
}

function toValidationForm(v?: QuestionValidation) {
  return {
    notBefore: toDateConstraintForm(v?.notBefore),
    notAfter: toDateConstraintForm(v?.notAfter),
    textType: v?.textType,
  };
}

export function toQuestionForm(
  q: QuestionAdminViewModel
): ValueTypeForControl<QuestionForm> {
  const sortedNested = q.nestedQuestions.sort((a, b) => a.order - b.order);
  return {
    ...q,
    nestedQuestions: sortedNested.map(toQuestionForm),
    validation: toValidationForm(q.validation),
    choices: q.choices.map((c) => ({
      ...c,
      nextQuestionId: q.responseMappings.find((rm) => rm.choiceId === c.id)
        ?.nextQuestionId,
    })),
  };
}

export function QuestionEdit({
  state,
  onDelete,
  otherQuestions,
  openOptions,
}: {
  state: QuestionForm;
  onDelete: () => void;
  openOptions: (q: QuestionForm, others: ArrayControl<QuestionForm>) => void;
  otherQuestions: ArrayControl<QuestionForm>;
}) {
  const fields = state.fields;
  const WithType = useControlStateComponent(fields.type, (c) => c.value);
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: "question",
    item: { state, otherQuestions },
  }));
  return (
    <>
      <Box>
        <div ref={drag} {...collected}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FTextField state={fields.heading} label="Heading" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <FNumberField state={fields.type} select fullWidth label="Type">
                <MenuItem value={QuestionType.YesNo}>Yes / No</MenuItem>
                <MenuItem value={QuestionType.RadioButtons}>
                  Multiple Choice
                </MenuItem>
                <MenuItem value={QuestionType.TextField}>Text Field</MenuItem>
                <MenuItem value={QuestionType.Grouped}>Grouped</MenuItem>
                <MenuItem value={QuestionType.DateField}>Date</MenuItem>
                <MenuItem value={QuestionType.DateTimeField}>DateTime</MenuItem>
                <MenuItem value={QuestionType.Repeater}>Repeater</MenuItem>
                <MenuItem value={QuestionType.Address}>Address</MenuItem>
                <MenuItem value={QuestionType.RadioButtonsWithLocationHelper}>Location Helper</MenuItem>
                <MenuItem value={QuestionType.MultiLineTextField}>MultiLine Text Field</MenuItem>
              </FNumberField>
            </Grid>
            <Grid item xs={2}>
              <FTextField state={fields.questionKey} label="Key" fullWidth />
            </Grid>
            <Grid item xs={2}>
              <FTextField
                state={fields.exportKey}
                label="Export Key"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <Button onClick={() => openOptions(state, otherQuestions)}>
                Configure
              </Button>
              <Button onClick={onDelete}>Delete</Button>
            </Grid>
          </Grid>
        </div>
        <WithType>
          {(t) => {
            switch (t) {
              case QuestionType.Grouped:
              case QuestionType.Repeater:
                return (
                  <>
                    <Box ml={4}>
                      <Droppable questions={fields.nestedQuestions} />
                      <FormArray state={fields.nestedQuestions}>
                        {(nestedQuestions) =>
                          nestedQuestions.map((c, i) => (
                            <Box key={c.uniqueId} my={1}>
                              <QuestionEdit
                                state={c}
                                otherQuestions={fields.nestedQuestions}
                                openOptions={openOptions}
                                onDelete={() =>
                                  fields.nestedQuestions.remove(i)
                                }
                              />
                              <Droppable
                                questions={fields.nestedQuestions}
                                question={c}
                              />
                            </Box>
                          ))
                        }
                      </FormArray>
                      <Button
                        onClick={() => {
                          const nq = fields.nestedQuestions.add();
                          nq.fields.id.setValue(nq.uniqueId.toString());
                        }}
                      >
                        Add nested-question
                      </Button>
                    </Box>
                  </>
                );
              default:
                return <></>;
            }
          }}
        </WithType>
      </Box>
    </>
  );
}
