import {
  FormControl,
  genericProps,
  useControlStateVersion,
} from "@react-typed-forms/core";
import {
  FormControl as FC,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import React, { ReactNode } from "react";

export type CheckPropsFunc<A> = (value: A) => {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

interface FCheckListProps<A> {
  label: string;
  helperText?: string;
  children: (checkProps: CheckPropsFunc<A>) => ReactNode;
  state: FormControl<A[]>;
}

export function FCheckList<A>({
  state,
  children,
  label,
  helperText,
}: FCheckListProps<A>) {
  useControlStateVersion(state);
  const { errorText } = genericProps(state);
  function isSelected(v: A) {
    return state.value.includes(v);
  }
  const checkProps: CheckPropsFunc<A> = (v: A) => {
    return {
      checked: isSelected(v),
      onChange: (e, c) => {
        const current = isSelected(v);
        if (current !== c) {
          state.setValue(
            current ? state.value.filter((a) => a !== v) : [...state.value, v]
          );
        }
      },
    };
  };
  return (
    <FC error={Boolean(errorText)}>
      <FormLabel>{label}</FormLabel>
      {children(checkProps)}
      <FormHelperText>{errorText ?? helperText}</FormHelperText>
    </FC>
  );
}
