import React from "react";
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Tooltip,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {createStyles, Theme, withStyles} from "@material-ui/core/styles";
import DeleteSweepOutlinedIcon from '@material-ui/icons/DeleteSweepOutlined';
import PlaylistAddOutlinedIcon from '@material-ui/icons/PlaylistAddOutlined';

const StyledTypography = withStyles((theme: Theme) =>
    createStyles({
        body1: {
            fontSize: "20px!important",
            color: "#FFF",
            [theme.breakpoints.down("sm")]: {
                paddingRight: "10px",
                paddingLeft: "10px",
                fontSize: "18px",
                color: "#fff",
                // color:'#133650',
                // Thomas added the marginTop
                marginTop: "10px",
            },
            "& .ml15": {
                marginLeft: "15px",
            },
            "& .ml20": {
                marginLeft: "20px",
            },
        },
    })
)(Typography);
const StyledListItemText = withStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            alignItems: "center",
        },
    })
)(ListItemText);
const StyledListItemIcon = withStyles((theme: Theme) =>
    createStyles({
        root: {
            //marginRight: "-15px",
            marginLeft: "15px",
            color: "#FFF!important",
            justifyContent: "center",
            display: "grid",
            alignContent: "center",
        },
    })
)(ListItemIcon);
const StyledListItemIconRepeater = withStyles((theme: Theme) =>
    createStyles({
        root: {
            //marginRight: "-15px",
            marginLeft: "15px",
            color: "#FFF!important",
            justifyContent: "center",
            display: "grid",
            alignContent: "center",
            fontSize:'3rem!important'
        },
    })
)(ListItemIcon);
const QuestionHeaderBox = withStyles((theme) => ({
    root: {
        display: "flex",
        paddingTop: "20px",

        paddingLeft: "5px",
        backgroundColor: theme.palette.primary.light,

        boxShadow:
            "0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)",
        minHeight: "70px",
        fontWeight: 550,
        fontSize: theme.typography.fontSize,
        padding: "0.5em 0 0.5em 0.25em",
        [theme.breakpoints.down("sm")]: {
            padding: "0.5em 0 0.5em 0.25em",
        },
    },
}))(Box);
const QuestionRepeaterHeaderBox = withStyles((theme) => ({
    root: {
        display: "flex",
        //paddingTop: "20px",

        paddingLeft: "5px",
        backgroundColor: theme.palette.primary.main,

        boxShadow:
            "0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)",
        //minHeight: "70px",
        fontWeight: 550,
        fontSize: theme.typography.fontSize,
        //padding: "0.5em 0 0.5em 0.25em",
        [theme.breakpoints.down("sm")]: {
            padding: "0.5em 0 0.5em 0.25em",
        },
    },
}))(Box);
const QuestionNestedHeaderBox = withStyles((theme) => ({
    root: {
        display: "flex",
        paddingTop: "20px",

        paddingLeft: "5px",
        //backgroundColor: theme.palette.primary.main,

        //boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',

        minHeight: "30px",
        fontWeight: 550,
        fontSize: theme.typography.fontSize,
        padding: "0.5em 0 0.5em 1em",
        [theme.breakpoints.down("sm")]: {
            padding: "0.5em 0 0.5em 0.25em",
        },
    },
}))(Box);

export const QuestionHeader = (props: any) => {
    return (
        <QuestionHeaderBox>
            {props.index != 0 && (
                <StyledListItemIcon>
                    <ArrowBackIosIcon
                        onClick={() => {
                            props.onBack();
                        }}
                    />
                </StyledListItemIcon>
            )}
            <StyledListItemText
                primary={
                    <StyledTypography
                        style={{paddingLeft: `${props.index > 0 ? "5px" : "20px"}`}}
                       // className={`${props.index > 0 ? "ml20" : "ml15"}`}
                        // color="textPrimary" Thomas commoned
                    >
                        {props.text}
                    </StyledTypography>
                }
            />
        </QuestionHeaderBox>
    );
};
export const QuestionRepeaterHeader = (props: any) => {
    return (
        <QuestionRepeaterHeaderBox>

            <StyledListItemText
                primary={
                    <StyledTypography
                        style={{paddingLeft: "20px"}}
                    >
                        {props.row >= 1 ? `${props.text} ${props.row + 1}` : props.text}
                    </StyledTypography>
                }
            />
            {!props.readonly && (<>
                <StyledListItemIconRepeater>
                    <Tooltip title={`Add ${props.text}`}><PlaylistAddOutlinedIcon
                        onClick={() => {
                            props.onAddRow();
                        }}
                    /></Tooltip>
                </StyledListItemIconRepeater>
                {props.row >= 1 && (
                    <StyledListItemIconRepeater>
                        <Tooltip title={`Delete ${props.text}`}><DeleteSweepOutlinedIcon
                            onClick={() => {
                                props.onRemoveRow();
                            }}
                        /></Tooltip>
                    </StyledListItemIconRepeater>
                )}</>)}
        </QuestionRepeaterHeaderBox>
    );
};
export const QuestionNestedHeader = (props: any) => {
    return (
        <QuestionNestedHeaderBox>
            {props.index != 0 && (
                <StyledListItemIcon>
                    <ArrowBackIosIcon
                        onClick={() => {
                            props.onBack();
                        }}
                    />
                </StyledListItemIcon>
            )}
            <StyledListItemText
                primary={
                    <Typography
                        variant={"h5"}
                        style={{paddingLeft: "10px"}}
                        className={`${props.index != 0 ? "ml20" : "ml15"}`}
                        // color="textPrimary" Thomas commoned
                    >
                        {props.text}
                    </Typography>
                }
            />
        </QuestionNestedHeaderBox>
    );
};
