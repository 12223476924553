import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface IInputDialog {
  open: boolean;
  title?: string;
  description?: string;
  confirmButtonName?: string;
  cancelButtonName?: string;
  inputPlaceholder?: string;
  value: string;
  onSubmit: (text: string) => void;
  onClose: () => void;
}

const InputDialog = ({
  open,
  title = "Info",
  description,
  confirmButtonName = "Save",
  cancelButtonName = "Cancel",
  inputPlaceholder = "",
  value = "",
  onSubmit,
  onClose,
}: IInputDialog) => {
  const [text, setText] = useState<string>(value);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleSubmit = () => {
    onSubmit(text);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {description && <DialogContentText>{description}</DialogContentText>}
          <TextField
            autoFocus
            margin="dense"
            label={inputPlaceholder}
            fullWidth
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelButtonName}
          </Button>
          <Button onClick={handleSubmit} color="primary">
            {confirmButtonName}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const inputValidor = (text: string) => {
  return text && text.replace(/\s/g, "") !== "";
};

export default InputDialog;
