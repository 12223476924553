import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import { AppBarSearch } from "./Search";
import { ItemSearch } from "./ItemSearch";
import { Divider, Paper } from "@material-ui/core";

import { Drawer } from "./Drawer";
// import { PersonSearch } from "../Search/PersonSearch";
import { UserMinusSolid, UserPlusSolid } from "../Icons/Icons";

const StyledIconButton = withStyles((theme) => ({
	root: {
		fontSize: "3rem",
		padding: "5px!important",
		color: theme.palette.primary.main,
	},
}))(IconButton);

// @ts-ignore
export const AppBarIconButton = ({ children, title, ...props }) => (
	<Tooltip title={title}>
		<StyledIconButton {...props}>{children}</StyledIconButton>
	</Tooltip>
);

export const AppBar = withStyles((theme) => ({
	root: {
		display: "flex",
		padding: "10px",
		marginBottom: "10px",
		minHeight: "60px",
	},
}))(Paper);
export const AppBarDivider = withStyles((theme) => ({
	root: {
		margin: theme.spacing(0, 2),
	},
}))(Divider);

const useStyles = makeStyles(() => ({
	root: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	left: {
		display: "flex",
		flex: "1 1 auto",
	},
	right: {},
}));

interface IMemberToolbar {
	onSearch?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
	onRefresh?: any;
	onAdd: any;
	onRemove: any;
	disabled?: boolean;
	children?: JSX.Element;
	renderExtraActions?: JSX.Element;
	onItemSearch: (query: string) => Promise<any>;
}

const ManagerToolbar = ({
	onSearch,
	onRefresh,
	onAdd,
	onRemove,
	disabled,
	children,
	renderExtraActions,
	onItemSearch,
}: IMemberToolbar) => {
	const classes = useStyles();
	const sideDrawer = useRef<any>(null);

	return (
		<>
			<Drawer ref={sideDrawer} toolbar anchor={"right"}>
				<ItemSearch
					onAddMembers={(members: string[]) => {
						onAdd(members);
						sideDrawer.current.closeDrawer();
					}}
					onItemSearch={onItemSearch}
				/>
			</Drawer>
			<AppBar elevation={2} className={classes.root}>
				<div className={classes.left}>
					<AppBarSearch onChange={onSearch} disabled={disabled} />
					<AppBarDivider flexItem orientation="vertical" />
					{/* <AppBarIconButton
            title={"Refresh Members"}
            aria-label="Refresh Member"
            disabled={disabled}
          >
            <SyncIcon onClick={onRefresh} />
          </AppBarIconButton> */}
					<AppBarIconButton
						title={"Add Member"}
						aria-label="Add Member"
						disabled={disabled}
					>
						<UserPlusSolid onClick={() => sideDrawer.current.openDrawer()} />
					</AppBarIconButton>
					<AppBarIconButton
						title={"Remove Member(s)"}
						aria-label="Remove Member"
						disabled={disabled}
					>
						<UserMinusSolid onClick={onRemove} />
					</AppBarIconButton>
					{renderExtraActions && (
						<>
							<AppBarDivider flexItem orientation="vertical" />
							{renderExtraActions}
						</>
					)}
				</div>
				{children && <div className={classes.right}>{children}</div>}
			</AppBar>
		</>
	);
};

export default ManagerToolbar;
