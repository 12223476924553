import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Fade,
  Slide,
  SlideProps,
  Step,
  StepButton,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { Question } from "./QuestionItem";
import { QuestionChoice } from "../../../common/client";
import { QuestionItemNavProps } from "./Questionnaire";

export const QuestionItemStepper = (props: QuestionItemNavProps) => {
  const {
    questions,
    controls,
    onIndexChange,
    questionIndex: currentIndex,
  } = props;
  const question = questions[currentIndex];
  const [slideIn, setSlideIn] = useState(true);
  const [slideDirection, setSlideDirection] =
    useState<SlideProps["direction"]>("left");
  //const [currentIndex, setCurrentIndex] = useState(props.questionIndex);
  function getPreviousQuestionIndex() {
    return currentIndex - 1;
  }
  function getNextQuestionIndex(selectedChoice: QuestionChoice) {
    let nextQuestionIndex = -1;
    if (question.responseMappings) {
      if (selectedChoice.id) {
        //find nextQuestionId of question response mappings matching choice Id
        const mapping = question.responseMappings.find(
          (m) => m.choiceId === selectedChoice.id
        );
        const nextQuestionId = mapping?.nextQuestionId;
        if (nextQuestionId) {
          //get question index matching these id
          nextQuestionIndex = questions.findIndex(
            (question) => question.id === nextQuestionId
          );
          if (nextQuestionIndex >= 0) {
            return nextQuestionIndex;
          }
        }
      }
    }

    return nextQuestionIndex;
  }
  const onChoiceSelected = (direction: "left" | "right") => {
    setSlideDirection(direction);
    setSlideIn(false);

    const selectedChoiceId = controls.fields[question.id].fields.choiceId.value;
    const nextQuestionIndex =
      direction === "left"
        ? getPreviousQuestionIndex()
        : getNextQuestionIndex(
            question.choices.find((c) => c.id === selectedChoiceId)!
          );

    props.onIndexChange(nextQuestionIndex, direction === "right");
  };

  useEffect(() => {
    const toDir = (b: boolean) => (b ? "left" : "right");
    const goingLeft = !props.transitionForward;
    // setSlideDirection(toDir(goingLeft));
    // setSlideIn(false);

    setTimeout(() => {
      //questionIndex = props.questionIndex;
      setSlideDirection(toDir(!goingLeft));
      setSlideIn(true);
    }, 200);
  }, [currentIndex]);

  return (
    <Box
      display="flex"
      my={2}
      style={{
        maxWidth: props.maxWidth ?? "100%",
        ...props.style,
        margin: "auto",
      }}
      justifyContent="stretch"
    >
      <Box width="30%" >
        <Stepper activeStep={currentIndex} nonLinear orientation="vertical">
          {props.questions.map((q, i) => (
            <Step key={q.id} completed={false}>
              <StepButton
                color="inherit"
                style={{ textAlign: 'left' }}
                onClick={() => {
                  setSlideDirection("right");
                  setSlideIn(false);
                  onIndexChange(i, true);
                }}
              >
                <StepLabel error={props.invalidQuestions?.includes(q.id)}>
                  {q.heading}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box width="70%" padding={'0 10px'} margin={"0 auto"}>
        {/*<Fade in={slideIn} >*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      maxWidth: props.maxWidth ?? "100%",*/}
        {/*      ...props.style,*/}
        {/*      margin: "auto",*/}
        {/*    }}*/}
        {/*  >*/}
            <Question
              readonly={false}
              question={question}
              control={props.controls.fields[question.id]}
              currentIndex={currentIndex}
              onBack={() => {
                onChoiceSelected("left");
              }}
              onResult={() => {
                onChoiceSelected("right");
              }}
            />
        {/*  </div>*/}
        {/*</Fade>*/}
      </Box>
    </Box>
  );
};
