import React, {MutableRefObject, useEffect, useMemo, useState} from "react";
import {IQuestion} from "../../../models/Question";
import {QuestionItemSlider} from "./QuestionItemSlider";
import {AuthorisationDialog} from "../dialogs/AuthorisationDeclaration";
import {QuestionValidationError} from "../../../common/client";
import {findNestedQuestionControl, QuestionnaireControls, visitQuestions,} from "../Questionnaire";
import {QuestionItemStepper} from "./QuestionItemStepper";
import { IconButton } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";

interface IQuestionnaireProps {
  questions: IQuestion[];
  validation: QuestionValidationError[] | undefined;
  onSaveResponse: (questionIndex: number) => Promise<any>;
  onQuestionnaireFinalised: () => void;
  maxWidth: string;
  style?: any;
  controls: QuestionnaireControls;
  overrideSubmit?: (lastIndex: number) => Promise<boolean>;
  currentPageRef?: MutableRefObject<number>;
  stepper?: boolean;
}

export interface QuestionItemNavProps {
  questions: IQuestion[];
  maxWidth?: string;
  style?: any;
  controls: QuestionnaireControls;
  questionIndex: number;
  transitionForward: boolean;
  onIndexChange: (index: number, forward: boolean) => void;
  invalidQuestions?: string[];
}

export const Questionnaire = ({
  currentPageRef,
  stepper,
  controls,
  ...props
}: IQuestionnaireProps) => {
  const questions = props.questions;
  const [authOpen, setAuthOpen] = React.useState(false);
  const [[currentIndex, transitionForward], setCurrentIndex] = useState([
    0,
    true,
  ]);

  const NavComponent = stepper ? QuestionItemStepper : QuestionItemSlider;

  if (currentPageRef) {
    currentPageRef.current = currentIndex;
  }

  const handleAuthClose = (newValue?: string) => {
    setAuthOpen(false);
    if (newValue === "submitted") {
      props.onQuestionnaireFinalised();
    }
  };

  const invalidQuestions = useMemo(() => {
    if (props.validation) {
      return Object.keys(
        props.validation.reduce((tlqs, err) => {
          const tlq = findTopLevelQuestion(err.questionId);
          if (tlq) {
            tlqs[tlq.id] = true;
          }
          return tlqs;
        }, {} as { [key: string]: boolean })
      );
    }
    return [];
  }, [props.validation, props.questions]);

  useEffect(() => {
    if (props.validation) {
      props.validation.forEach((err) => {
        const q = findTopLevelQuestion(err.questionId);
        if (q) {
          const tlc = controls.fields[q.id];
          if (q.id !== err.questionId) {
            const errControl = findNestedQuestionControl(
              tlc,
              q,
              err.questionId,
              err.repeaterPath
            );
            if (errControl) {
              errControl.setError(err.message);
            }
          } else {
            console.log("Found a top level");
          }
        }
      });
    }
  }, [props.validation, props.questions]);

    useEffect(() => {
        if (invalidQuestions.length > 0) {
            window.scrollTo(0, 0);
        }
    }, [invalidQuestions])

  return (
    <>
      <NavComponent
        style={{ ...props.style }}
        questions={questions}
        controls={controls}
        maxWidth={props.maxWidth}
        questionIndex={currentIndex}
        transitionForward={transitionForward}
        onIndexChange={indexChange}
        invalidQuestions={invalidQuestions}
      />
      <AuthorisationDialog open={authOpen} onClose={handleAuthClose} />
    </>
  );

  async function indexChange(i: number, forward: boolean) {
    await props.onSaveResponse(currentIndex);
    if (i === -1) {
      if (props.overrideSubmit) {
        props.overrideSubmit(currentIndex).then(setAuthOpen);
      } else {
        setAuthOpen(true);
      }
    } else {
      setCurrentIndex([i, forward]);
    }
  }

  function findTopLevelQuestion(q: string) {
    return props.questions.find((tlq, i) => {
      const notMatched = visitQuestions([tlq], (qvm) => {
        return qvm.id !== q;
      });
      return !notMatched;
    });
  }
};
