import { createRenderer } from "@react-typed-forms/core";
import { Box, Button, Grid, ListItemIcon, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { SubQuestionIcon } from "../../components/core/QuestionSlider/QuestionItemSubQuestion";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../components/muiCore/Dialog";

const AllIcons = [
  "breath",
  "fever",
  "smell",
  "nose",
  "contact",
  "travel",
  "travelContact",
  "homeIsolation",
  "interpreter",
  "UserNurseIcon",
];

export const FIconSelector = createRenderer<string | undefined | null, {}>(
  ({ state, ...props }, { errorText, value, ...formProps }) => {
    const [open, setOpen] = useState(false);
    function select(i: string) {
      setOpen(false);
      state.setValue(i);
    }

    return (
      <>
        <ListItemIcon onClick={() => setOpen(true)}>
          {SubQuestionIcon(value)}
        </ListItemIcon>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>
            <Typography color="textPrimary" variant={"h1"}>
              Choose Icon
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box m={4}>
              <Grid container spacing={3}>
                {AllIcons.map((i) => (
                  <Grid item xs={4} key={i}>
                    <ListItemIcon
                      style={{ textAlign: "center" }}
                      onClick={() => select(i)}
                    >
                      {SubQuestionIcon(i)}
                    </ListItemIcon>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
);
