import React, {ChangeEvent, useState} from 'react'
import SearchIcon from "@material-ui/icons/Search";
import {Divider, fade, InputBase, ListItem, TextField} from "@material-ui/core";
import MuiListItem from "@material-ui/core/List";
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.black, 0.03),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.black, 0.05),
            },
            marginLeft: 0,
            width: '100%',
            height:'60px',
            [theme.breakpoints.up('sm')]: {
                //margin: theme.spacing(1),
                // width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color:theme.palette.primary.main,
            fontSize:'3rem',
            paddingLeft: `10px`,
        },
        inputRoot: {
            color: 'inherit',
            padding:'15px 0 0 15px',
            width: '100%',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                //width: '12ch',
                '&:focus': {
                    //width: '20ch',
                },
            },
        },
    })
)

const StyledListItem = withStyles((theme:Theme) => ({
    root: {
        padding:'5px'
    },
}))(MuiListItem);

export const ChoiceFilter = (props: any) => {

    const classes = useStyles();
    const [searchFilters, setSearchFilters] = useState()

    return (
        <>
            <StyledListItem>
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder={props.placeholder}
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}
                        value={props.value}
                        onChange={(e:ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            //setLoading(true)
                            props.onChange(e)
                        }}
                    />
                </div>
            </StyledListItem>
            <Divider/>
        </>
    )
}