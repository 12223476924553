import { useHistory, useParams } from "react-router";
import { useApiClient } from "../../../../common/apiclients";
import {
  AudiencesClient,
  AudienceViewModel,
  QuestionnaireEditModel,
  QuestionnairesClient,
  QueueOutcomeViewModel,
} from "../../../../common/client";
import { useEffect, useState } from "react";
import { ArrayControl, buildGroup, FormArray } from "@react-typed-forms/core";
import {
  QuestionEdit,
  QuestionForm,
  QuestionFormDef,
  toQuestionForm,
} from "./QuestionEditor";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { FTextField } from "../../../../common/formComponents/FTextField";
import { FCheckList } from "../../../../common/formComponents/FCheckList";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrop } from "react-dnd";
import { QuestionOptions, QuestionOptionsProps } from "./QuestionOptions";
import { questionnaireEditUrl, questionnaireListUrl } from "../../Routes";
import { QuestionnaireEditorButton } from "./QuestionnaireList";

export const QuestionnaireFormDef = buildGroup<
  Omit<QuestionnaireEditModel, "questions">
>()({
  name: "",
  audiences: [],
  questions: () => new ArrayControl(QuestionFormDef),
});

export function QuestionnaireEditor() {
  const { id: _id } = useParams<{ id: string }>();
  const id = _id !== "new" ? _id : null;
  const client = useApiClient(QuestionnairesClient);
  const audienceClient = useApiClient(AudiencesClient);
  const [loading, setLoading] = useState(true);
  const [audiences, setAudiences] = useState<AudienceViewModel[]>();
  const [form] = useState(QuestionnaireFormDef);
  const fields = form.fields;
  const [saving, setSaving] = useState(false);
  const [optionsQuestion, setOptionsQuestion] =
    useState<QuestionOptionsProps>();
  const [showingOptions, setShowingOptions] = useState(false);
  const [queueOutcomes, setQueueOutcomes] = useState<QueueOutcomeViewModel[]>(
    []
    );

  const router = useHistory();
  useEffect(() => {
    if (id) {
      loadQuestionnaire(id);
    } else {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    loadAudiences();
  }, []);

    const scrollToBottom = () => {
        setTimeout(() => {
            const mainElement = document.getElementsByTagName('main')[0];
            mainElement.scroll(0, mainElement.scrollHeight);
        }, 0);
    };

  if (loading) return <h1>Loading</h1>;
  return (
    <DndProvider backend={HTML5Backend}>
      {optionsQuestion && (
        <Dialog
          open={showingOptions}
          onClose={() => setShowingOptions(false)}
          maxWidth="xl"
          fullWidth
        >
          <DialogContent>
            <QuestionOptions {...optionsQuestion} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowingOptions(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <Box>
        <Box
          my={2}
          textAlign="center"
          component={(p) => (
            <Typography {...p} variant="h1">
              Edit Questionnaire
            </Typography>
          )}
        />
        <FTextField
          state={fields.name}
          label="Questionnaire Name"
          style={{ width: "40em" }}
        />
        {audiences && (
          <Box my={3}>
            <FCheckList label={"Audiences"} state={fields.audiences}>
              {(checkProps) =>
                audiences.map((a) => (
                  <FormControlLabel
                    key={a.name}
                    control={<Checkbox {...checkProps(a.id)} />}
                    label={a.name}
                  />
                ))
              }
            </FCheckList>
          </Box>
        )}
        <Box my={3}>
          <Typography variant="h1">Questions</Typography>
          <Droppable questions={fields.questions} />
          <FormArray state={fields.questions}>
            {(qs) =>
              qs.map((q, i) => (
                <Box key={q.fields.id.value} my={4}>
                  <QuestionEdit
                    otherQuestions={fields.questions}
                    openOptions={(q1, o) => {
                      setOptionsQuestion({
                        state: q1,
                        otherQuestions: o,
                        outcomeResults: queueOutcomes,
                      });
                      setShowingOptions(true);
                    }}
                    state={q}
                    onDelete={() => fields.questions.remove(i)}
                  />
                  <Droppable questions={fields.questions} question={q} />
                </Box>
              ))
            }
          </FormArray>
        </Box>
      </Box>
          <div style={{ zIndex: '10', position: 'sticky', bottom: '50px', background: 'white', padding: '10px', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <QuestionnaireEditorButton
                  title='Add Question'
                  onClick={() => {
                      const nq = fields.questions.add();
                      nq.fields.id.setValue(nq.uniqueId.toString());
                      scrollToBottom();
                  }}
              />
              <div style={{ display: 'flex', gap: '10px' }}>
                  <QuestionnaireEditorButton
                      title='Save'
                      style={{ width: '100px' }}
                      disabled={saving}
                      onClick={() => saveQuestionnaire()}
                  />
                  <QuestionnaireEditorButton
                      title='Close'
                      style={{ width: '100px' }}
                      disabled={saving}
                      onClick={() => router.replace(questionnaireListUrl())}
                  />
              </div>
          </div>
    </DndProvider>
  );

  async function loadQuestionnaire(id: string) {
    const questionnaire = await client.getQuestionnaire(id);
    const { audiences, ...others } = questionnaire;
    form.setValue({
      ...others,
      questions: others.questions.map(toQuestionForm),
      audiences: audiences.map((a) => a.audienceId),
    });
    setQueueOutcomes(
      questionnaire.queueOutcomes ? questionnaire.queueOutcomes : []
    );
    setLoading(false);
  }

  async function loadAudiences() {
    setAudiences(await audienceClient.getAudiences());
  }

  async function saveQuestionnaire() {
    setSaving(true);
    form.setDisabled(true);
    try {
      if (id) {
          console.log(form.toObject());
        await client.editQuestionnaire(id, form.toObject());
        await loadQuestionnaire(id);
      } else {
        const newId = await client.createQuestionnaire(form.toObject());
        router.replace(questionnaireEditUrl(newId));
      }
    } catch (e) {
      alert("Failure to save");
    }
    form.setDisabled(false);
    setSaving(false);
  }
}

export function Droppable({
  questions,
  question,
}: {
  questions: ArrayControl<QuestionForm>;
  question?: QuestionForm;
}) {
  const [{ isOver, canDrop }, drop] = useDrop<
    { state: QuestionForm; otherQuestions: ArrayControl<QuestionForm> },
    any,
    any
  >(() => ({
    accept: "question",
    canDrop: (item) =>
      item.state !== question && !isChildOf(item.state, questions),
    drop: (item, o) => {
      item.otherQuestions.update((f) => f.filter((q) => q !== item.state));
      questions.update((f) => {
        const outArray = [...f];
        const indIns = question ? outArray.indexOf(question) : -1;
        outArray.splice(indIns + 1, 0, item.state);
        return outArray;
      });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  return (
    <div ref={drop}>
      <Box py={2} style={isOver && canDrop ? { background: "yellow" } : {}}>
        <Divider />
      </Box>
    </div>
  );
}

function isChildOf(
  q: QuestionForm,
  questionList: ArrayControl<QuestionForm>
): boolean {
  if (q.fields.nestedQuestions === questionList) {
    return true;
  }
  return q.fields.nestedQuestions.elems.some((dq) =>
    isChildOf(dq, questionList)
  );
}
