import {withStyles} from "@material-ui/core/styles";
import {Divider, Paper} from "@material-ui/core";

export const AppBar = withStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '5px',
        marginBottom: '10px',
        minHeight:'60px'
        
    }
}))(Paper);
export const AppBarDivider = withStyles((theme) => ({
    root: {
        margin: theme.spacing(0, 2),

    }
}))(Divider);