import { useApiClient } from "../../../../common/apiclients";
import {
  BasicQuestionnaireViewModel,
  QuestionnairesClient,
} from "../../../../common/client";
import { useEffect, useState } from "react";
import { Card, Link } from "@material-ui/core";
import { questionnaireHref } from "../../Routes";
import { AppBarDivider } from "../../../../components/muiCore/Paper";

const buttonStyles = {
    backgroundColor: '#55437e',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
};

const buttonHoverStyles = {
    backgroundColor: '#6a52a3',
};

interface ButtonProps {
    onClick: () => void;
    title: string;
    style?: React.CSSProperties;
    disabled?: boolean;
}

export const QuestionnaireEditorButton: React.FC<ButtonProps> = ({ onClick, title, style, disabled }) => {
    const [isHovered, setIsHovered] = useState(false);

    const baseStyles: React.CSSProperties = {
        backgroundColor: disabled ? '#cccccc' : isHovered ? '#6a52a3' : '#55437e',
        border: 'none',
        color: 'white',
        padding: '10px 20px',
        borderRadius: '5px',
        cursor: disabled ? 'not-allowed' : 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        ...style,
    };

    return (
        <button
            onClick={disabled ? undefined : onClick}
            style={baseStyles}
            onMouseEnter={() => !disabled && setIsHovered(true)}
            onMouseLeave={() => !disabled && setIsHovered(false)}
            disabled={disabled}
        >
            {title}
        </button>
    );
};

export function QuestionnaireList() {
  const client = useApiClient(QuestionnairesClient);
  const [questionnaires, setQuestionnaires] =
    useState<BasicQuestionnaireViewModel[]>();

    interface QuestionnaireCardProps {
        children: React.ReactNode;
        extraStyles?: React.CSSProperties;
        q: BasicQuestionnaireViewModel;
    }

    const QuestionnaireCard: React.FC<QuestionnaireCardProps> = ({ children, extraStyles, q }) => {
        const baseStyles: React.CSSProperties = {
            marginBottom: '10px',
            width: '650px',
            display: 'flex',
            alignItems: 'center',
            transition: 'box-shadow 0.3s ease-in-out',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
            ...extraStyles,
        };

        const linkStyles: React.CSSProperties = {
            flex: 1,
            padding: '15px 0 15px 10px',
            textDecoration: 'none',
            color: 'inherit',
            overflow: 'hidden',
            textOverflow: '...'
        };

        return (
            <Card
                style={baseStyles}
                onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.3)')}
                onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.3)')}
            >
                <a href={questionnaireHref(q.id)} style={linkStyles}>
                    {q.name}
                </a>
                <AppBarDivider flexItem orientation="vertical" style={{margin: '0 16px 0 0'}} />
                {children}
            </Card>
        );
    };


  useEffect(() => {
    loadQuestionnaires();
  }, []);

  return questionnaires ? showQuestionnaires(questionnaires) : <div />;

    function showQuestionnaires(list: BasicQuestionnaireViewModel[]) {
        return (
            <>
                <h2>Published</h2>
                <div style={{ paddingLeft: '20px' }}>
                    {list.filter(q => q.published === true).map((q) => (
                        <QuestionnaireCard key={q.id} q={q}>
                            <QuestionnaireEditorButton
                                onClick={() => publishQuestionnaire(q.id)}
                                title="Unpublish"
                                style={{ width: '110px' }}
                            />
                            <QuestionnaireEditorButton
                                onClick={() => duplicateQuestionnaire(q.id)}
                                title="Duplicate"
                                style={{ width: '110px', marginLeft: '10px', marginRight: '10px' }}
                            />
                        </QuestionnaireCard>
                    ))}
                </div>
                <h2>Drafts</h2>
                <div style={{ paddingLeft: '20px' }}>
                    {list.filter(q => q.published === false).map((q) => (
                        <QuestionnaireCard key={q.id} q={q}>
                            <QuestionnaireEditorButton
                                onClick={() => publishQuestionnaire(q.id)}
                                title="Publish"
                                style={{ width: '110px' }}
                            />
                            <QuestionnaireEditorButton
                                onClick={() => duplicateQuestionnaire(q.id)}
                                title="Duplicate"
                                style={{ width: '110px', marginLeft: '10px', marginRight: '10px' }}
                            />
                            {/*<QuestionnaireEditorButton
                            onClick={() => deleteQuestionnaire(q.id)}
                            title="Delete"
                        />*/}
                        </QuestionnaireCard>
                    ))}
                </div>
            </>
        );
    }

  async function loadQuestionnaires() {
    setQuestionnaires(await client.getQuestionnaires());
  }

    async function deleteQuestionnaire(id: string) {
        const questionnaire = await client.getQuestionnaire(id);
        questionnaire.questions.forEach((question) => {
            deleteQuestion(id, question.id);
        });
        loadQuestionnaires();
    }

    async function deleteQuestion(id: string, questionID: string) {
        await client.deleteQuestion(id, questionID);
    }

    async function duplicateQuestionnaire(id:string) {
        await client.cloneQuestionnaire(id);
        loadQuestionnaires();
    }

    async function publishQuestionnaire(id: string) {
        await client.publishQuestionnaire(id);
        loadQuestionnaires();
    }
}
