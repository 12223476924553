import {withStyles} from "@material-ui/core/styles";
import {Avatar, Box, Typography} from "@material-ui/core";

const CustomAvatar = withStyles((theme) => ({
    root: {
        marginLeft:'0px',
        width:'30px',
        height:'30px',
        fontSize:theme.typography.caption.fontSize
    }
}))(Avatar);

export const PersonAvatar = (props:any) => {
    
    return <Box 
        display={'flex'} 
        marginTop={'10px'} 
        //onClick={(event: React.MouseEvent<HTMLButtonElement>)=>props.onClick(event)}
    >
        <CustomAvatar>
            {props.initials}
        </CustomAvatar>
        <Typography variant={'caption'} style={{paddingTop:'14px'}}>{props.fullName}</Typography>
    </Box>
} 