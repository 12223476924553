import {
  ArrayControl,
  buildGroup,
  control,
  ControlType,
  FormArray,
  useControlState,
  useControlStateVersion,
} from "@react-typed-forms/core";
import {
  ChoiceAlert,
  ChoiceResult,
  QuestionChoiceEditModel,
  QueueOutcomeViewModel,
} from "../../../../common/client";
import { Box, Button, Grid, MenuItem } from "@material-ui/core";
import {
  FSelectField,
  FTextField,
} from "../../../../common/formComponents/FTextField";
import { FNumberField } from "../../../../common/formComponents/FNumberField";
import { QuestionForm } from "./QuestionEditor";
import deepEqual from "fast-deep-equal/es6";
import { FCheckbox } from "../../../../common/formComponents/FCheckbox";
import { IconButton } from "@material-ui/core";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
export const ChoiceAlertFormDef = buildGroup<ChoiceAlert>()({
  id: "",
  body: "",
  title: "",
});

export const ChoiceFormDef = buildGroup<QuestionChoiceEditModel>()({
  id: control(undefined),
  outcomeResult: ChoiceResult.None,
  text: "",
  exportValue: "",
  nextQuestionId: control(undefined),
  queueOutcomeChoiceId: control(undefined),
  onAssignment: false,
});

export type ChoiceForm = ControlType<typeof ChoiceFormDef>;

function sameAsExisting(existing: any, newone: any) {
  if (deepEqual(existing, newone)) {
    return existing;
  }
  return newone;
}

export function ChoiceEditor({
  state,
  onDelete,
  otherQuestions,
  queueOutcomes,
  onUp,
  onDown,
}: {
  state: ChoiceForm;
  otherQuestions: ArrayControl<QuestionForm>;
  onDelete: () => void;
  onUp: () => void;
  onDown: () => void;
  queueOutcomes: QueueOutcomeViewModel[];
}) {
  const fields = state.fields;
  const others: [string | undefined, string][] = useControlState(
    otherQuestions,
    (c, p) =>
      sameAsExisting(
        p,
        c.elems.map(({ fields: { id, heading } }) => [id.value, heading.value])
      )
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <FTextField state={fields.text} label="Text" fullWidth />
      </Grid>
      <Grid item xs={2}>
        <FTextField state={fields.exportValue} label="Export Value" fullWidth />
      </Grid>
      <Grid item xs={2}>
        <FSelectField
          state={fields.nextQuestionId}
          label="Next Question"
          fullWidth
        >
          <MenuItem value="">None</MenuItem>
          {others.map((q) => (
            <MenuItem key={q[0]} value={q[0]}>
              {q[1]}
            </MenuItem>
          ))}
        </FSelectField>
      </Grid>
      {/*<Grid item xs={2}>*/}
      {/*  <FNumberField*/}
      {/*    state={fields.outcomeResult}*/}
      {/*    select*/}
      {/*    fullWidth*/}
      {/*    label="Outcome"*/}
      {/*  >*/}
      {/*    <MenuItem value={ChoiceResult.None}>None</MenuItem>*/}
      {/*    <MenuItem value={ChoiceResult.TestRequired}>Test Required</MenuItem>*/}
      {/*    <MenuItem value={ChoiceResult.TraceRequired}>Trace Required</MenuItem>*/}
      {/*    <MenuItem value={ChoiceResult.TraceAndTestRequired}>*/}
      {/*      Trace and Test Required*/}
      {/*    </MenuItem>*/}
      {/*  </FNumberField>*/}
      {/*</Grid>*/}
      <Grid item xs={1}>
        <FCheckbox label="On Assignment" state={fields.onAssignment} />
      </Grid>
      <Grid item xs={2}>
        <FTextField
          state={fields.queueOutcomeChoiceId}
          label="Queue Outcome Result"
          fullWidth
          select
        >
          <MenuItem value={undefined}>{"None"}</MenuItem>
          {queueOutcomes.map((qo) => (
            <MenuItem value={qo.id}>{qo.name}</MenuItem>
          ))}
        </FTextField>
      </Grid>

      <Grid item xs={2}>
        <IconButton onClick={onUp}>
          <UpIcon />
        </IconButton>
        <IconButton onClick={onDown}>
          <DownIcon />
        </IconButton>
        <Button onClick={onDelete}>Delete</Button>
      </Grid>
    </Grid>
  );
}
