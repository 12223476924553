import {IQuestion} from "../../../models/Question";
import {
  ArrayControl,
  arrayControl,
  BaseControl,
  control,
  FormControl,
  GroupControl,
  groupControl,
} from "@react-typed-forms/core";
import {
  QuestionAdminViewModel,
  QuestionChoiceViewModel,
  QuestionResponseVm,
  QuestionType,
} from "../../../common/client";
import {ReactNode, useMemo} from "react";
import {toFullAddressLine} from "../Address/AddressComplete";
import {isoDateTimeToDisplay, isoDateToDisplay} from "../../../common/dates";

type AnyControl = FormControl<any> | GroupControl<any> | ArrayControl<any>;
export type AnswersState = {
  [questionId: string]: { choiceId: string; value: any };
};

export type TopLevelControl = GroupControl<{
  choiceId: FormControl<string | undefined>;
  value: AnyControl;
}>;

export type QuestionnaireControls = GroupControl<{
  [choiceId: string]: TopLevelControl;
}>;

export type GroupAnswerValue = { [childId: string]: any };

export type RepeaterAnswerValue = GroupAnswerValue[];

export function createControl(
  question: QuestionAdminViewModel
): () => AnyControl {
  switch (question.type) {
    case QuestionType.Grouped:
      return makeNestedGroup(question.nestedQuestions!);
    case QuestionType.Repeater:
      return arrayControl(makeNestedGroup(question.nestedQuestions!));
    default:
      return control<any>(undefined);
  }
}

export function createTopLevelControl(
  question: QuestionAdminViewModel
): () => TopLevelControl {
  return groupControl({
    choiceId: control(undefined),
    value: createControl(question),
  });
}

export function makeQuestionnaireControl(
  questions: IQuestion[],
  answers?: AnswersState
): QuestionnaireControls {
  const children: { [s: string]: () => TopLevelControl } = {};
  questions.forEach((qv) => {
    children[qv.id] = createTopLevelControl(qv);
  });
  const c = groupControl(children)();
  if (answers) {
    c.setValue(answers, true);
  }
  return c;
}

function makeNestedGroup(questions: IQuestion[]) {
  const children: { [s: string]: () => BaseControl } = {};
  questions.forEach((qv) => {
    children[qv.id] = createControl(qv);
  });
  return groupControl(children);
}

export function parseAnswers(responses: {
  [q: string]: QuestionResponseVm;
}): AnswersState {
  const ans: AnswersState = {};
  for (const qid in responses) {
    const { choiceId, value } = responses[qid];
    ans[qid] = { choiceId, value: value ? JSON.parse(value) : undefined };
  }
  return ans;
}

export function useSortedNestedQuestions(q: QuestionAdminViewModel) {
  return useMemo(() => {
    const nq = [...q.nestedQuestions];
    nq.sort((q) => q.order);
    return nq;
  }, [q]);
}

export function isGroupElementVisible(
  parent: QuestionAdminViewModel,
  groupValue: { [childId: string]: any },
  child: QuestionAdminViewModel
) {
  if (!child.hidden) return true;
  return parent.nestedQuestions.some((nq) => {
    const choice = selectedChoiceNestedValue(nq, groupValue);
    return (
      choice &&
      nq.responseMappings.some(
        (rm) => rm.choiceId === choice.id && rm.nextQuestionId === child.id
      )
    );
  });
}

export function selectedChoiceNestedValue(
  question: QuestionAdminViewModel,
  parentValue: { [childId: string]: any }
): QuestionChoiceViewModel | undefined {
  const childValue = parentValue[question.id];
  if (!childValue) return undefined;
  switch (question.type) {
    case QuestionType.RadioButtons:
    case QuestionType.YesNo:
    case QuestionType.RadioButtonsWithLocationHelper:
    case QuestionType.RadioButtonsWithSearchFilter:
      return question.choices.find((c) => c.id === childValue);
    default:
      return undefined;
  }
}

export function questionAnswerText(
  question: QuestionAdminViewModel,
  choice: QuestionChoiceViewModel | undefined,
  value: any,
  betweenRepeater: (nodes: ReactNode[]) => ReactNode,
  textNode: (text: string) => ReactNode
): ReactNode {
  function answerText(
    question: QuestionAdminViewModel,
    choice: QuestionChoiceViewModel | undefined,
    value: any
  ): ReactNode {
    switch (question.type) {
      case QuestionType.Address:
        return textNode(toFullAddressLine(value ?? {}));
      case QuestionType.DateField:
        return textNode(isoDateToDisplay(value) ?? "");
      case QuestionType.DateTimeField:
        return textNode(isoDateTimeToDisplay(value) ?? "");
      case QuestionType.TextField:
        return textNode(value);
      case QuestionType.MultiLineTextField:
        return textNode(value);
      case QuestionType.Repeater:
        return betweenRepeater(
          value.map((v: GroupAnswerValue) =>
            groupText(question.nestedQuestions, v)
          )
        );
      default:
        return textNode(choice?.text ?? "");
    }
  }

  return answerText(question, choice, value);

  function groupText(
    questions: QuestionAdminViewModel[],
    value: GroupAnswerValue
  ): ReactNode {
    return questions
      .map((q) => [
        q.heading,
        questionAnswerText(
          q,
          selectedChoiceNestedValue(q, value),
          value[q.id],
          betweenRepeater,
          (t) => t
        ),
      ])
      .filter((c) => c[1])
      .map(([h, v]) => `${h}: ${v}`)
      .join(", ");
  }
}

export function visitQuestions(
  questions: QuestionAdminViewModel[],
  visit: (q: QuestionAdminViewModel) => boolean
): boolean {
  function visitRecurse(q: QuestionAdminViewModel): boolean {
    return (
      visit(q) && (!q.nestedQuestions || q.nestedQuestions.every(visitRecurse))
    );
  }
  return questions.every(visitRecurse);
}

export function autoSelectChoice(q: QuestionAdminViewModel) {
  switch (q.type) {
    case QuestionType.Grouped:
    case QuestionType.Repeater:
      return q.choices[0]?.id;
    default:
      return undefined;
  }
}

export function findNestedQuestionControl(
  control: TopLevelControl,
  q: QuestionAdminViewModel,
  id: string,
  repeaterPaths: number[]
): BaseControl | undefined {
  function recurse(
    q: QuestionAdminViewModel,
    control: BaseControl,
    repeaterPaths: number[]
  ): BaseControl | undefined {
    if (q.id === id) {
      return control;
    }
    switch (q.type) {
      case QuestionType.Grouped:
        const childGroup = control as GroupControl<any>;
        for (let i = 0; i < q.nestedQuestions.length; i++) {
          const nestedQ = q.nestedQuestions[i];
          const found = recurse(
            nestedQ,
            childGroup.fields[nestedQ.id],
            repeaterPaths
          );
          if (found) {
            return found;
          }
        }
        return undefined;
      case QuestionType.Repeater:
        const childArray = control as ArrayControl<any>;
        const [arrayIndex, ...nextPaths] = repeaterPaths;
        if (arrayIndex === undefined) {
          console.log("No repeater index found");
          return undefined;
        }
        const indexedControl = childArray.elems[arrayIndex];
        if (!indexedControl) {
          console.log("No element for index: " + arrayIndex);
          return undefined;
        }
        for (let i = 0; i < q.nestedQuestions.length; i++) {
          const nestedQ = q.nestedQuestions[i];
          const found = recurse(
            nestedQ,
            indexedControl.fields[nestedQ.id],
            nextPaths
          );
          if (found) {
            return found;
          }
        }
        return undefined;
    }
  }
  return recurse(q, control.fields.value, repeaterPaths);
}
