import React from "react";
import {Box, ListItem, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";


const StyledOutcomeHeaderTypography = withStyles((theme) => ({
    root: {
        fontSize: '22px!important',
        // Thomas 28/06/021
        // color: theme.palette.primary.contrastText
        color:'#fff',
    }
}))(Typography);
const StyledOutcomeResultTypography = withStyles((theme) => ({
    root: {
        minHeight: '70px',
        fontWeight: 550,
        textAlign: 'justify',
        //fontSize: theme.typography.fontSize,
        //padding: '0.5em 0 0.5em 0.25em',
        display: 'block',
    }
}))(Typography);
export const CenteredTypography = (props: any) => {
    return (
        <Box alignItems={'center'} display={"flex"} flexDirection={"row"}>
            <Typography>
                {(props.text) ? props.text : ''}
            </Typography>
        </Box>
    )
}

export const OutcomeHeaderTypography = (props: any) => {
    return (
        <StyledOutcomeHeaderTypography>
            {props.children}
        </StyledOutcomeHeaderTypography>
    )
}

export const OutcomeResultTypography = (props: any) => {
    return (
        <>
            {props.paragraphs.map((p:any)=>{
                return <StyledOutcomeResultTypography >
                    {p}
                </StyledOutcomeResultTypography>
            })}
        </>
    )
}