import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import AssignmentIcon from "@material-ui/icons/Assignment";
import ViewListIcon from "@material-ui/icons/ViewList";

interface IMainListItems {
	queueItems?: any[];
	onItemClick?: (item: any) => void;
	selectedItem?: any;
}

export const MainListItems = ({
	queueItems = [],
	onItemClick,
	selectedItem,
}: IMainListItems) => {
  return (
    <div>
      {queueItems.map((q: any) => (
        <ListItem
          key={q.id}
          selected={selectedItem && selectedItem.id === q.id}
          button
          onClick={() => onItemClick && onItemClick(q)}
        >
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText primary={q.name} />
        </ListItem>
      ))}
      {/*<ListItem button>*/}
      {/*    <ListItemIcon>*/}
      {/*        <LabelOutlinedIcon />*/}
      {/*    </ListItemIcon>*/}
      {/*    <ListItemText primary="Access" />*/}
      {/*</ListItem>*/}
    </div>
  );
};

export const secondaryListItems = (
	<div>
		<ListSubheader inset>Saved reports</ListSubheader>
		<ListItem button>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary="Current month" />
		</ListItem>
		<ListItem button>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary="Last quarter" />
		</ListItem>
		<ListItem button>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary="Year-end sale" />
		</ListItem>
	</div>
);

export const DummyQuestions = (): any[] => [
	{
		id: "816a739f-31a1-4bdb-d8ba-08d9560f2620",
		heading: "Referral Person Registration",
		type: 6,
		//intent: 0,
		//intentKey: null,
		//order: 0,
		//questionnaireId: "3dcbd0af-ac9e-4152-8503-0c4f95609030",
		//deletedAt: null,
		choices: [
			{
				id: "55faf17e-d10a-4476-f1f4-08d9560f884d",
				text: "Submit",
				//outcomeResult: 4,
				//alert: null,
				//order: 0,
				//deletedAt: null
			},
			// {
			//   id: "946df91a-0e50-403a-f1f5-08d9560f884d",
			//   text: "No",
			//   //outcomeResult: 4,
			//   //alert: null,
			//   //order: 0,
			//   //deletedAt: null
			// },
		],
		responseMappings: [
			{
				choiceId: "55faf17e-d10a-4476-f1f4-08d9560f884d",
				//Id: '816a739f-31a1-4bdb-d8ba-08d9560f2620',
				nextQuestionId: "1ff51566-ee3b-496b-d8bb-08d9560f2620",
				//deletedAt: null
			},
			// {
			//   choiceId: "946df91a-0e50-403a-f1f5-08d9560f884d",
			//   //Id: '816a739f-31a1-4bdb-d8ba-08d9560f2620',
			//   nextQuestionId: "1ff51566-ee3b-496b-d8bb-08d9560f2620",
			//   //deletedAt: null
			// },
		],
		subQuestions: [],
		nestedQuestions: [
			{
				id: "816a739f-31a1-4bdb-d8ba-08d9560f2123",
				heading: "First Name",
				type: 4,
				required: true,
			},
			{
				id: "816a739f-31a1-4bdb-d8ba-08d9560f2456",
				heading: "Last Name",
				type: 4,
				required: true,
			},
		],
		info: [],
		previousQuestionId: "",
		response: "",
	},
];
