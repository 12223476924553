import { createRenderer } from "@react-typed-forms/core";
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from "@material-ui/core";
import React from "react";

export const FCheckbox = createRenderer<
  boolean,
  Omit<FormControlLabelProps, "control">
>(({ state, ...props }, { errorText, value, onChange, ...formProps }) => (
  <FormControlLabel
    {...props}
    control={
      <Checkbox
        checked={value}
        onChange={(_, v) => state.setValue(v)}
        {...formProps}
      />
    }
  />
));
