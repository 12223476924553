import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppBar, AppBarDivider } from "../../../components/muiCore/Paper";
import { AppBarSearch } from "../../../components/muiCore/InputBase";
import { CalendarsDateRangePicker } from "../../../components/muiCore/DateRangePicker";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  GridSize,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  OutcomeCard,
  SelectableCard,
  SelectableCardIconContentItem,
  SelectableStatisticCard,
  SkeletonCard,
} from "../../../components/muiCore/Card";
import { Skeleton, ToggleButtonGroup } from "@material-ui/lab";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import {
  endOfToday,
  format,
  parse,
  parseISO, startOfYear,
  startOfYesterday,
} from "date-fns";
import { Drawer } from "../../../components/muiCore/Drawer";
import {
  OutcomeDetailsListItem,
  OutcomeHeaderListItem,
} from "../../../components/muiCore/ListItem";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {
  ClinicMedicalSolid,
  UserMdSolid, UserNurseIcon,
  UserSolid,
} from "../../../components/Icons/DoHIcons";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { ResendEamilNotification } from "../../../data/api";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { QueueWorkItem } from "../../../state/State.model";
import CommentIcon from "@material-ui/icons/Comment";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import EventIcon from "@material-ui/icons/Event";
import RestoreIcon from "@material-ui/icons/Restore";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { AppBarIconButton } from "../../../components/muiCore/Button";
import { PersonAvatar } from "../../../components/muiCore/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import {
  StyledFTextField,
  ValidationTextField,
} from "../../../components/muiCore/TextField";
import PostAddIcon from "@material-ui/icons/PostAdd";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useSnackbar } from "notistack";
import { Context } from "../../../state/Store";
import { QueueWorkItemTabs } from "./QueueWorkItem/QueueWorkItemTabs";
import { SkeletonTimeLine } from "../../../components/muiCore/TimeLine";
import TooltipToggleButton from "../../../components/muiCore/ToggleButton";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import DialogContentText from "@material-ui/core/DialogContentText";
import * as api from "../../../data/api";
import { HubConnection } from "@microsoft/signalr";
import { useSignalR } from "../../utils/signalr";
import { useApiClient } from "../../../common/apiclients";
import {
  CSVExportViewModel,
  LoginUserViewModel,
  PersonKind,
  PersonsClient,
  QuestionChoice,
  QuestionnaireClient,
  QuestionnairesClient,
  QueueChoiceVm,
  QueueClient,
  QueueOutcomeViewModel,
  QueueViewModel,
  QueueWorkItemStatus,
  QueueWorkItemViewModel,
} from "../../../common/client";
import { AssignToQueueDialog } from "./Queue/AssignToQueueDialog";
import CbxTree from "../../../components/core/Manager/SubComponents/CheckboxTree";
import { QueueQuestionnaireDrawer } from "./Queue/QueueQuestionnaireDrawer";
import { useHistory, useParams } from "react-router";
import { PersonDetailsFormDef } from "../../../components/core/forms/PersonDetails";
import {
  FDateField,
  FTextField,
} from "../../../common/formComponents/FTextField";
import { QuestionItemYesNoButtons } from "../../../components/core/QuestionSlider/QuestionItemYesNoButtons";
import useInfiniteScroll from "react-infinite-scroll-hook";
import deepEqual from "fast-deep-equal/es6";
import { useDebounce } from "../../../common/debounce";
import { buildGroup, useControlValue } from "@react-typed-forms/core";
import { FCheckbox } from "../../../common/formComponents/FCheckbox";
import { saveAs } from "file-saver";
import { hasPermission, Permissions } from "../../utils/permissions";
import { useHubConnection, useHubHandlers } from "../../../common/signalr";
import {
  AddressComplete,
  RenderAddressFields,
  toFullAddressLine,
} from "../../../components/core/Address/AddressComplete";
import { CalendarIcon } from "@material-ui/pickers/_shared/icons/CalendarIcon";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SearchFilter {
  before?: Date;
  after?: Date;
  queryText: string;
  status: QueueWorkItemStatus[];
  outcomes?: string[];
  queueId: string;
}

const ItemsPerPage = 10;

type CSVExportModel = CSVExportViewModel & {
  questionnaireId: string;
};

export const CSVExportFormDef = buildGroup<CSVExportModel>()({
  reExportRecords: false,
  endDateTime: "",
  startDateTime: "",
  questionnaireId: "",
});

export function Queue({ queues, referralId }: { queues: QueueViewModel[], referralId?: string }) {
    const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const { queueId } = useParams<{ queueId: string }>();
  const currentQueue = queues.find((q) => q.id === queueId)!;

  const queueClient = useApiClient(QueueClient);
  const personsClient = useApiClient(PersonsClient);
  const questionClient = useApiClient(QuestionnaireClient);
  const adminQuestionClient = useApiClient(QuestionnairesClient);

  //GLOBAL STATE
  const { state } = useContext(Context);

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [onFirstPage, setOnFirstPage] = useState(true);
  const [csvLoading, setCsvLoading] = useState(false);
  const [queue, setQueue] = useState<QueueViewModel>();
  const [queueWorkItems, setQueueWorkItems] = useState<any>();

  const [queueWorkItem, setQueueWorkItem] = useState<QueueWorkItemViewModel>();
  const [queueWorkItemStatus, setQueueWorkItemStatus] =
    useState<QueueWorkItemStatus>();
  const [metrics, setMetrics] = useState<any>([]);
  const [searchFilters, _setSearchFilters] = useState<SearchFilter>({
    queryText: "",
    queueId,
    status: [QueueWorkItemStatus.New],
    after: startOfYear(endOfToday()),
    before: endOfToday(),
  });

  const [referralProcessed, setReferralProcessed] = useState(false);

  function setSearchFilters(newFilter: SearchFilter) {
    _setSearchFilters((sf) => (deepEqual(sf, newFilter) ? sf : newFilter));
  }

  const [hasMore, setHasMore] = useState(true);
  const [isDatePickerExpanded, setIsDatePickerExpanded] =
    useState<boolean>(false);
  const [isUpdateFromDatePicker, setIsUpdateFromDatePicker] =
    useState<boolean>(false);
  const [hasDateChanged, setHasDateChanged] = useState<boolean>(false);
  const [queueWorkItemStatusChanged, setQueueWorkItemStatusChanged] =
    useState<boolean>(false);
  const [isInitialApiCalled, setIsInitialApiCalled] = useState<boolean>(false);
  const [isFilterChanged, setIsFilterChanged] = useState<boolean>(false);
  const [drawerTitle, setDrawerTitle] = useState<string>();
  const [token, setToken] = useState<string | undefined>(state.user?.token);
  const [queueWorkItemLoading, setQueueWorkItemLoading] =
    useState<boolean>(true);

  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const [queueChanged, setQueueChanged] = useState<boolean>();
  const [assignToQueueMode, setAssignToQueueMode] = useState<boolean>(false);

  const [referralFormInitiated, setReferralFormInitiated] =
    useState<boolean>(false);
  const [referralFormFinalised, setReferralFormFinalised] =
    useState<boolean>(false);

  const [metricCatSelect, setMetricCatSelect] = useState(false);

  const sideDrawer = useRef<any>(null);
  const toggleSideDrawer = sideDrawer?.current;

  const [questionnaireResponseId, setQuestionnaireResponseId] =
    useState<string>();
  const questionnaireSideDrawer = useRef<any>(null);
  const toggleQuestionnaireSideDrawer = questionnaireSideDrawer?.current;

  const [updateQueueData, setUpdateQueueData] = useState(false);
  const [updateQueueAssignemnt, setUpdateQueueAssignemnt] = useState(false);
  const [srQWI, setsrQWI] = useState<any>();
  const [srUAQWI, setsrUAQWI] = useState<any>();
  const [updateMetricsFromApi, setUpdateMetricsFromApi] = useState(false);

  const [personForm] = useState(PersonDetailsFormDef);
  const [editPerson, setEditPerson] = useState(false);
  const [noteMode, _setNoteMode] = useState<boolean>(false);
  const [csvExportMode, setCSVExportMode] = useState<boolean>(false);

  const [form] = useState(CSVExportFormDef);
  const fields = form.fields;

  const startDateFieldValue = useControlValue(fields.startDateTime);
  const endDateFieldValue = useControlValue(fields.endDateTime);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    disabled: false,
    rootMargin: "0px 0px 400px 0px",
  });

  function startEditingNote() {
    _setNoteMode(true);
    noteKey++;
  }

  const [treeData, setTreeData] = useState<
    { name: string; value: string; label: string }[]
  >([]);

  //const [outcomeChoices, setOutcomes];

  // useSignalR("/api/hub/queue", state.user?.token, (conn: HubConnection) => {
  //   conn.on("queueitem-updated", (workItem: any) => {
  //     setsrQWI(workItem);
  //     setUpdateQueueData(true);
  //     setUpdateQueueAssignemnt(true);
  //   });
  //   conn.on("queue-updated", (queueId: string) => {
  //     if (currentQueue.id == queueId) {
  //       setUpdateQueueAssignemnt(true);
  //     }
  //   });
  //   conn.on("referral-added", () => {
  //     if (currentQueue.name == "Hotline") {
  //       setUpdateQueueAssignemnt(true);
  //     }
  //   });
  // });

  const hubConnection = useHubConnection("/api/hub/queue");
  useHubHandlers(hubConnection, [
    [
      "queueitem-updated",
      (workItem: QueueWorkItemViewModel) => {
       
          setsrQWI(workItem);
          setUpdateQueueData(true);
          setUpdateQueueAssignemnt(true);
         // formDef.fields.queueUpdate.setValue(new Date());
        
      },
    ],
    [
      "queue-updated",
      (queueId: string) => {
      //  if (formDef.fields.currentQueue.value.id == queueId) {
          setUpdateQueueAssignemnt(true);
         // formDef.fields.queueUpdate.setValue(new Date());
      //  }
      },
    ],
    [
      "referral-added",
      () => {
        if (currentQueue.name == "Referral") {
          setUpdateQueueAssignemnt(true);
        }
      },
    ],
    // [
    //   "person-updated",
    //   async (personId: string) => {
    //     onPersonUpdated(await personsClient.getPersonById(personId));
    //   },
    // ],
  ]);

  let MetricCategories = AllQueueWorkItems.map((wi) => QueueWorkItemStatus[wi]);
  let QueueWorkItemStatuses = AllQueueWorkItems;

  // let QueueWorkItemStatuses = AllQueueWorkItems.filter(x=>x!=QueueWorkItemStatus.OnHold);
  // let MetricCategories = QueueWorkItemStatuses.map((wi) => QueueWorkItemStatus[wi]);
  //
  // //const treeData: any[] = [];

  async function formFinalised() {
    var d = await questionClient.finaliseQuestionnaireByAdmin(
      queueWorkItem!.id,
      questionnaireResponseId!
    );
    setReferralFormFinalised(true);
    //setReferralPerson(undefined);
    setDrawerTitle(`${MetricCategories[d.queueWorkItem!.status]} Referral`);
    //onQueueWorkItemSelected(queueWorkItem);
    //setQueueWorkItemLoading(true)
    showWorkItem(d.queueWorkItem!);
  }

  function showWorkItem(qwi: QueueWorkItemViewModel) {
    setQueueWorkItem(qwi);
    setShowQuestionnaire(false);
    toggleQuestionnaireSideDrawer.closeDrawer();
    toggleSideDrawer.openDrawer();
    setQuestionnaireResponseId(undefined);
  }

  async function registerFormUser(user: LoginUserViewModel) {
    try {
      const newPerson = {
        ...user,
        kind: PersonKind.Visitor,
      };
      const newWorkItem = await queueClient.createNewQueueWorkItem(
        currentQueue.id,
        newPerson
      );
      setQueueWorkItem(newWorkItem);
      if (currentQueue.launchForm) {
        setQuestionnaireResponseId(
          newWorkItem.workItem.questionnaireResponseId
        );
      } else {
        showWorkItem(newWorkItem);
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to register person.", { variant: "error" });
    }
  }

  async function getQuestionnaireIdForQueue() {
    try {
      //const qId = await adminQuestionClient.getQuestionnaireForPersonKind(kind);
      const qId = await queueClient.getQuestionnaireByQueueId(currentQueue.id);
      const qrId = await questionClient.createNewResponseForQueueWorkItem(
        qId,
        queueWorkItem!.workItem.person.id!,
        queueWorkItem!.id
      );
      setQuestionnaireResponseId(qrId.id);
    } catch (err) {
      console.log(err);
      enqueueSnackbar("Failed to register person.", { variant: "error" });
    }
  }

  async function doItemQuery(offset?: number) {
      var query = searchFilters.queryText
      if (referralId && !referralProcessed) {
          query = referralId;
          setSearchFilters({ ...searchFilters, queryText: query });
      }
    const queueData = await queueClient.getQueueWorkItems(
      queueId,
      offset ?? 0,
      ItemsPerPage,
      {
        after: searchFilters.after as any,
        before: searchFilters.before as any,
        query: query,
        queueWorkItemStatuses: searchFilters.status,
        outcomes: searchFilters.outcomes,
        isSuperUser: true,
      }
    );
    setHasMore((queueData.queueWorkItems?.length ?? 0) === ItemsPerPage);
    setOnFirstPage((offset ?? 0) === 0);
    return queueData;
  }

  const loadQueueData = () => {
    setLoading(true);
    let apiCalls = [];

    apiCalls.push(doItemQuery());

    apiCalls.push(queueClient.getQueueOutcomesByQueueId(queueId));

    if (hasDateChanged || queueWorkItemStatusChanged || !isInitialApiCalled) {
      apiCalls.push(
        api.getQueueMetrics(
          queueId,
          searchFilters.before,
          searchFilters.after,
          searchFilters.outcomes,
          token
        )
      );
    }

    Promise.all(apiCalls)
      .then((res) => {
        const [queue, outcomes, metrics] = res;
        setQueue(queue);
        metrics && setMetrics(metrics);
        setHasDateChanged(false);
        setQueueWorkItemStatusChanged(false);
        setQueueWorkItemStatus(undefined);

        if (outcomes) {
            for (let data of outcomes) {
                if (data.children) {
                    data.children.sort((a:any, b:any) => a.name.localeCompare(b.name));
                }
            }
          setTreeData(
            outcomes.map((o: QueueChoiceVm) => ({
              name: o.name,
              label: o.name,
              value: o.id,
              children: o.children
                ? o.children.map((a: QueueChoiceVm) => ({
                    name: a.name,
                    label: a.name,
                    value: a.id,
                    parentId: a.parentId,
                  }))
                : null,
            }))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getQueueDataWithoutRestriction = () => {
    let apiCalls = [];
    apiCalls.push(doItemQuery());

    apiCalls.push(
      api.getQueueMetrics(
        currentQueue!.id,
        searchFilters.before,
        searchFilters.after,
        searchFilters.outcomes,
        token
      )
    );

    Promise.all(apiCalls)
      .then((res) => {
        const [queue, metrics] = res;
        setQueue(queue);
        metrics && setMetrics(metrics);
      })
      .catch((err) => {
        console.log(err);
      });

    if (referralFormFinalised) setReferralFormFinalised(false);
  };

  const onWorkItemAssigned = (queueWorkItem: any) => {
    // 	setQueueWorkItemStatusChanged(true);
    // 	setQueueWorkItemLoading(true);
    // 	if (
    // 		queueWorkItem.personAssignedTo &&
    // 		queueWorkItem.status == QueueWorkItemStatus.InProgress
    // 	) {
    // 		//call unassign api
    //
    // 		api
    // 			.UnAssignWorkItem(currentQueue!.id, queueWorkItem.workItem.id, token)
    // 			.then((d) => {
    // 				setQueueWorkItem(d);
    // 			})
    // 			.catch((err) => console.log(err));
    // 	} else {
    // 		//call assign api
    // 		api
    // 			.AssignWorkItemToMe(currentQueue!.id, queueWorkItem.workItem.id, token)
    // 			.then((d) => {
    // 				setQueueWorkItem(d);
    // 			})
    // 			.catch((err) => console.log(err));
    // 	}
  };
  const onWorkItemNoteAdded = (
    queueWorkItemNote: string,
    globalNote: boolean
  ) => {
    setQueueWorkItemLoading(true);
    // if (
    // 	queueWorkItemStatusChanged &&
    // 	(queueWorkItemStatus == QueueWorkItemStatus.OnHold ||
    // 		queueWorkItemStatus == QueueWorkItemStatus.Closed)
    // ) {
    // 	setQueueWorkItemStatusChanged(true);
    // 	queueClient
    // 		.updateQueueWorkItemStatus(
    // 			currentQueue!.id,
    // 			queueWorkItem!.workItem.id,
    // 			{ note: queueWorkItemNote, status: queueWorkItemStatus }
    // 		)
    // 		.then((d) => {
    // 			setQueueWorkItem(d);
    // 		})
    // 		.catch((err) => {
    // 			setQueueWorkItemLoading(false);
    // 			setQueueWorkItemStatus(queueWorkItem!.status);
    // 			enqueueSnackbar("Failed to update referral", { variant: "error" });
    // 		});
    // } else {
    api
      .SaveWorkItemNote(
        currentQueue!.id,
        queueWorkItem!.workItem.id,
        queueWorkItemNote,
        globalNote,
        token
      )
      .then((qwi) => {
        setQueueWorkItem(qwi);
      })
      .catch((err) => console.log(err));
    //}
  };
  const onDatePickerChangeHandler = (range: any) => {
    if (
      searchFilters &&
      (searchFilters?.after !== range[0] || searchFilters?.before !== range[1])
    ) {
      setLoading(true);
      setIsUpdateFromDatePicker(true);
      setHasDateChanged(true);
      setSearchFilters({
        ...searchFilters,
        before: range[1],
        after: range[0],
      });
    }
  };
  const handleStatusSelect = (newFormats: QueueWorkItemStatus[]) => {
    //setLoading(true);
    setSearchFilters({
      ...searchFilters,
      status: newFormats,
    });
    setIsFilterChanged(true);
    setMetricCatSelect(true);
  };

  const WorkItemIcon = (state: number): JSX.Element | undefined => {
    switch (state) {
      case QueueWorkItemStatus.New:
        return <ErrorOutlineOutlinedIcon />;
      case QueueWorkItemStatus.Viewed:
        return <PlayCircleOutlineIcon />;

      case QueueWorkItemStatus.PDFDownloaded:
        return <CheckCircleOutlineIcon />;
    }
  };

  function addNote(noteTextField: string, globalNote: boolean) {
    onWorkItemNoteAdded(noteTextField, globalNote);
    handleClose();
  }

  const handleClose = () => {
    _setNoteMode(false);
    setQueueWorkItemStatus(undefined);
  };

  // const handleAssignToQueueClose = () => {
  // 	setAssignToQueueMode(false);
  // };
  //
  // const handleAssignAndCloseQueueClose = () => {
  // 	setQueueWorkItemStatus(QueueWorkItemStatus.Closed);
  // 	setAssignToQueueMode(false);
  // };

  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatuses: QueueWorkItemStatus[]
  ) => {
    setLoading(true);
    setSearchFilters({
      ...searchFilters,
      status: newStatuses,
    });
    setIsFilterChanged(true);
  };

  useEffect(() => {
    if (state.user?.token) {
      setToken(state.user?.token);
    }
  }, []);
  useEffect(() => {
    if (currentQueue) {
      setQueueChanged(true);
      setSearchFilters({
        ...searchFilters,
        status: [QueueWorkItemStatus.New],
        after: searchFilters?.after ?? startOfYear(endOfToday()),
        before: searchFilters?.before ?? endOfToday(),
        outcomes: undefined,
      });
      setUpdateMetricsFromApi(true);
    }
    if (csvExportMode) {
      setCSVExportMode(false);
      fields.startDateTime.setValue("");
      fields.endDateTime.setValue("");
    }
  }, [currentQueue]);
  useEffect(() => {
    loadQueueData();
  }, [searchFilters]);
  useEffect(() => {
    if (!csvExportMode) loadQueueData();
  }, [csvExportMode]);
  useEffect(() => {
    setSearchFilters({
      queueId,
      queryText: "",
      status: [QueueWorkItemStatus.New],
      after: startOfYear(endOfToday()),
      outcomes: [],
      before: endOfToday(),
    });
  }, [queueId]);
  useEffect(() => {
    if (queue) {
      setQueueWorkItems(queue.queueWorkItems);
    }
  }, [queue]);
  useEffect(() => {
      if (queueWorkItems && queueWorkItems.length >= 0 && loading) {
        setLoading(false);
        if (referralId && !referralProcessed) {
            const targetItem = queueWorkItems.find((q: QueueWorkItemViewModel) => q.id === referralId);

            if (targetItem) {
                showWorkItem(targetItem);
            }
            setReferralProcessed(true);
        }
    }
  }, [queueWorkItems]);
  useEffect(() => {
    if (queueWorkItem) {
      setQueueWorkItemLoading(false);
    }
  }, [queueWorkItem]);

  // function setQueueWorkItemStatusMethod(status: QueueWorkItemStatus )
  // {
  // 	if(queueWorkItem && queueWorkItem.workItem){

  // 		setQueueWorkItemStatusChanged(true);
  // 		queueClient
  // 			.updateQueueWorkItemStatus(
  // 				currentQueue!.id,
  // 				queueWorkItem!.workItem.id,
  // 				{ note: "", status: status }
  // 			)
  // 			.then((d) => {
  // 				setQueueWorkItem(d);
  // 				//setQueueWorkItemStatus(undefined);
  // 			})
  // 			.catch((err) => {
  // 				setQueueWorkItemLoading(false);
  // 				//setQueueWorkItemStatus(queueWorkItem!.status);
  // 				enqueueSnackbar("Failed to update referral", { variant: "error" });
  // 			});

  // 	}
  // }

  const workItemStatusChanged = (queueWorkItem: any) => {
    if (queueWorkItem.status === QueueWorkItem.New) {
      setQueueWorkItemStatusChanged(true);

      api
        .UpdateQueueWorkItemStatus(
          queueId,
          queueWorkItem.workItem.id,
          "",
          hasPermission(state.user, Permissions.SuperUser) ? 3 : 1,
          token
        )
        .then((d) => {
          setSearchFilters({
            ...searchFilters,
            status: [0, 1, 2],
          });
          setQueueWorkItem(d);
        })
        .catch((err) => {
          setQueueWorkItemLoading(false);
          setQueueWorkItemStatus(queueWorkItem.status);
          enqueueSnackbar("Failed to update referral", { variant: "error" });
        });
    }
    if (queueWorkItem.status === QueueWorkItem.PDFDownloaded) {
      setQueueWorkItemStatusChanged(true);

      api
        .UpdateQueueWorkItemStatus(
          queueId,
          queueWorkItem.workItem.id,
          "VIEWING",
          hasPermission(state.user, Permissions.SuperUser) ? 4 : 2,
          token
        )
        .then((d) => {
          setSearchFilters({
            ...searchFilters,
            status: [0, 1, 2],
          });
          setQueueWorkItem(d);
        })
        .catch((err) => {
          setQueueWorkItemLoading(false);
          setQueueWorkItemStatus(queueWorkItem.status);
          enqueueSnackbar("Failed to update referral", { variant: "error" });
        });
    }
    if (queueWorkItem.status === QueueWorkItem.Viewed) {
      setQueueWorkItemStatusChanged(true);
      api
        .UpdateQueueWorkItemStatus(
          queueId,
          queueWorkItem.workItem.id,
          "VIEWING",
          hasPermission(state.user, Permissions.SuperUser) ? 3 : 1,
          token
        )
        .then((d) => {
          setSearchFilters({
            ...searchFilters,
            status: [0, 1, 2],
          });
          setQueueWorkItem(d);
        })
        .catch((err) => {
          setQueueWorkItemLoading(false);
          setQueueWorkItemStatus(queueWorkItem.status);
          enqueueSnackbar("Failed to update referral", { variant: "error" });
        });
    }
  };

  const onWorkItemEdited = (queueWorkItem: any) => {
    setQueueWorkItemStatusChanged(true);

    api
      .UpdateQueueWorkItemStatus(
        queueId,
        queueWorkItem.workItem.id,
        "",
        5,
        token
      )
      .then((d) => {
        setSearchFilters({
          ...searchFilters,
          status: [0, 1, 2],
        });
        setQueueWorkItem(d);
      })
      .catch((err) => {
        setQueueWorkItemLoading(false);
        setQueueWorkItemStatus(queueWorkItem.status);
        enqueueSnackbar("Failed to update referral", { variant: "error" });
      });
  };

  const onWorkItemReset = (queueWorkItem: any) => {
    if (queueWorkItem.status !== QueueWorkItem.New) {
      setQueueWorkItemStatusChanged(true);

      api
        .UpdateQueueWorkItemStatus(
          queueId,
          queueWorkItem.workItem.id,
          "",
          0,
          token
        )
        .then((d) => {
          setSearchFilters({
            ...searchFilters,
            status: [0, 1, 2],
          });
          setQueueWorkItem(d);
        })
        .catch((err) => {
          setQueueWorkItemLoading(false);
          setQueueWorkItemStatus(queueWorkItem.status);
          enqueueSnackbar("Failed to update referral", { variant: "error" });
        });
    }
  };

  const resendEmailNotification = (queueWorkItem: any) => {
    console.log(queueWorkItem);
    ResendEamilNotification(
      "matthew.vince@dayonedigital.com.au",
      "DoESystem",
      state.user!.token
    ).then(() => enqueueSnackbar("Email Resent", { variant: "success" }));
  };

  const downloadPDF = (queueWorkItem: any) => {
    setQueueWorkItemStatusChanged(true);
    api
      .UpdateQueueWorkItemStatus(
        queueId,
        queueWorkItem.workItem.id,
        "",
        hasPermission(state.user, Permissions.SuperUser) ? 4 : 2,
        token
      )
      .then((d) => {
        setSearchFilters({
          ...searchFilters,
          status: [0, 1, 2],
        });
        setQueueWorkItem(d);
      })
      .catch((err) => {
        setQueueWorkItemLoading(false);
        setQueueWorkItemStatus(queueWorkItem.status);
        enqueueSnackbar("Failed to update referral", { variant: "error" });
      });
  };

  useEffect(() => {
    if (updateMetricsFromApi) {
      api
        .getQueueMetrics(
          currentQueue!.id,
          searchFilters.before,
          searchFilters.after,
          searchFilters.outcomes,
          token
        )
        .then((m) => setMetrics(m));
      setUpdateMetricsFromApi(false);
    }
  }, [updateMetricsFromApi]);
  useEffect(() => {
    if (updateQueueData) {
      if (srQWI) {
        //let qwi = queue.queueWorkItems.find((q: any) => q.id == srQWI.id);

        // setQueueWorkItems((qwi: any) =>
        //   qwi.map((q: any) =>
        //     q.id == srQWI.id
        //       ? {
        //           ...q,
        //           personAssignedTo: srQWI.personAssignedTo,
        //           status: srQWI.status,
        //         }
        //       : q
        //   )
        // );
        if (queueWorkItem && queueWorkItem.id == srQWI.id) {
          queueWorkItem.status = srQWI.status;
          queueWorkItem.personAssignedTo = srQWI.personAssignedTo;
        }
      }
      setUpdateQueueData(false);
    }
    if (updateQueueAssignemnt) {
      getQueueDataWithoutRestriction();
      setUpdateQueueAssignemnt(false);
      //setUpdateMetricsFromApi(true);
    }
  }, [updateQueueData, updateQueueAssignemnt]);
  useEffect(() => {
    if (referralFormFinalised) {
      getQueueDataWithoutRestriction();
    }
  }, [referralFormFinalised]);
  useEffect(() => {
    setMetricCatSelect(false);
  }, [metricCatSelect]);

  return (
    <>
      <Drawer
        ref={sideDrawer}
        anchor={"right"}
        onClose={() => {
          toggleSideDrawer.closeDrawer();
          setEditPerson(false);
            history.push(`/admin/view/${queueId}/`);
        }}
        title={drawerTitle}
      >
        {queueWorkItemLoading ? (
          <>
            <Skeleton variant="rect" width={"100%"} height={250} />
            <Skeleton
              variant="rect"
              style={{ marginTop: "10px" }}
              width={"100%"}
              height={50}
            />
            <Skeleton
              variant="rect"
              style={{ marginTop: "10px" }}
              width={"100%"}
              height={50}
            />
            <Skeleton
              variant="rect"
              style={{ marginTop: "10px" }}
              width={"100%"}
              height={50}
            />
            <SkeletonTimeLine />
          </>
        ) : (
          <>
            <OutcomeCard width={"100%"}>
                <div style={{paddingRight:'0.125em', backgroundColor: '#FC4C1F'}}>
                    <OutcomeHeaderListItem
                        variant={MetricCategories[queueWorkItem!.status]}
                        text={`${MetricCategories[queueWorkItem!.status]} Referral`}
                        icon={WorkItemIcon(queueWorkItem!.status)}
                    />
                </div>
              <AppBar elevation={2}>
                <div style={{ display: "flex", margin: "auto" }}>
                  {/*<AppBarDivider flexItem orientation="vertical" />*/}
                  {/*<AppBarIconButton*/}
                  {/*	title={`${*/}
                  {/*		queueWorkItem?.personAssignedTo*/}
                  {/*			? "UNASSIGN"*/}
                  {/*			: "ASSIGN TO ME"*/}
                  {/*	}`}*/}
                  {/*	onClick={() => onWorkItemAssigned(queueWorkItem)}*/}
                  {/*	aria-label="Add Team"*/}
                  {/*	//disabled={!toggleSideDrawer || !hasModifyTeamMemberAccess }*/}
                  {/*>*/}
                  {/*	{queueWorkItem?.personAssignedTo ? (*/}
                  {/*		<UserMinusSolid />*/}
                  {/*	) : (*/}
                  {/*		<UserPlusSolid />*/}
                  {/*	)}*/}
                  {/*</AppBarIconButton>*/}
                  {/*<AppBarDivider flexItem orientation="vertical" />*/}
                  {/*<AppBarIconButton*/}
                  {/*	title={`${*/}
                  {/*		queueWorkItem?.status == QueueWorkItemStatus.OnHold*/}
                  {/*			? "RESUME"*/}
                  {/*			: "PUT ON HOLD"*/}
                  {/*	}`}*/}
                  {/*	aria-label="Add Team"*/}
                  {/*	onClick={() => {*/}
                  {/*		setQueueWorkItemStatus(*/}
                  {/*			queueWorkItem?.status !== QueueWorkItemStatus.OnHold*/}
                  {/*				? QueueWorkItemStatus.OnHold*/}
                  {/*				: queueWorkItem?.personAssignedTo*/}
                  {/*				? QueueWorkItemStatus.InProgress*/}
                  {/*				: QueueWorkItemStatus.New*/}
                  {/*		);*/}
                  {/*	}}*/}
                  {/*>*/}
                  {/*	{queueWorkItem?.status == QueueWorkItemStatus.OnHold ? (*/}
                  {/*		<PlayCircleOutlineIcon />*/}
                  {/*	) : (*/}
                  {/*		<PauseCircleOutlineIcon />*/}
                  {/*	)}*/}
                  {/*</AppBarIconButton>*/}
                  {/*<AppBarDivider flexItem orientation="vertical" />*/}

                  {queueWorkItem &&
                    queueWorkItem.status != QueueWorkItemStatus.New &&
                    hasPermission(state.user, Permissions.SuperUser) && (
                      <>
                        <AppBarIconButton
                          title={"Reset"}
                          aria-label="Reset"
                          onClick={() => {
                            onWorkItemReset(queueWorkItem);
                          }}
                        >
                          <RestoreIcon
                            onClick={() => {
                              onWorkItemReset(queueWorkItem);
                            }}
                          />
                        </AppBarIconButton>

                        <AppBarDivider flexItem orientation="vertical" />
                      </>
                    )}
                  <AppBarIconButton
                    title={""}
                    aria-label="Add Team"
                    onClick={() => {
                      setQueueWorkItemStatus(undefined);
                      startEditingNote();
                    }}
                    //disabled={!toggleSideDrawer || !hasModifyTeamMemberAccess }
                  >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <CommentIcon />
                        <div style={{ fontSize: 'small' }}>Add Notes</div>
                    </div>
                  </AppBarIconButton>
                  {/*<AppBarDivider flexItem orientation="vertical" />*/}
                  {/*<AppBarIconButton*/}
                  {/*	title={"Assign to another queue"}*/}
                  {/*	aria-label="Assign To Queue"*/}
                  {/*	//disabled={!toggleSideDrawer || !hasModifyTeamMemberAccess }*/}
                  {/*	onClick={() => setAssignToQueueMode(true)}*/}
                  {/*>*/}
                  {/*	<AddCircleOutlineIcon />*/}
                  {/*</AppBarIconButton>*/}

                  {/*<AppBarDivider flexItem orientation="vertical" />*/}
                  {/*{currentQueue.canCreateForm && !currentQueue.launchForm && (*/}
                  {/*	<>*/}
                  {/*		<AppBarIconButton*/}
                  {/*			title={*/}
                  {/*				currentQueue.formCreateHelpText ??*/}
                  {/*				"Create Risk Assessment"*/}
                  {/*			}*/}
                  {/*			aria-label={*/}
                  {/*				currentQueue.formCreateHelpText ??*/}
                  {/*				"Create Risk Assessment"*/}
                  {/*			}*/}
                  {/*			onClick={() => {*/}
                  {/*				getQuestionnaireIdForQueue().then((d) => {*/}
                  {/*					setShowQuestionnaire(true);*/}
                  {/*				});*/}
                  {/*			}}*/}
                  {/*		>*/}
                  {/*			<PostAddIcon />*/}
                  {/*		</AppBarIconButton>*/}
                  {/*		<AppBarDivider flexItem orientation="vertical" />*/}
                  {/*	</>*/}
                  {/*)}*/}

                  {/*<AppBarIconButton*/}
                  {/*    title={"AUDIT EVENTS"}*/}
                  {/*    aria-label="Add Team"*/}
                  {/*    //disabled={!toggleSideDrawer || !hasModifyTeamMemberAccess }*/}
                  {/*>*/}
                  {/*    <ListAltIcon onClick={() => toggleSideDrawer.openDrawer()}/>*/}

                  {/*</AppBarIconButton>*/}
                  {/*<AppBarDivider flexItem orientation="vertical"/>*/}
                </div>
              </AppBar>
              <OutcomeDetailsListItem
                text={`${queueWorkItem?.workItem.person.firstName} ${queueWorkItem?.workItem.person.lastName}`}
                icon={
                  <Tooltip title={"Contact Person"}>
                    <UserSolid />
                  </Tooltip>
                }
                divider={true}
              />
              <OutcomeDetailsListItem
                text={format(
                  new Date(queueWorkItem?.workItem.createdAt!),
                  "dd-MM-yyyy HH:mm:ss"
                )}
                icon={
                  <Tooltip title={"Created At"}>
                    <EventIcon />
                  </Tooltip>
                }
                divider={true}
              />
              {queueWorkItem!.outcomes &&
                queueWorkItem!.outcomes.length > 0 && (
                  <OutcomeDetailsListItem
                    icon={<HomeIcon />}
                    text={queueWorkItem!.outcomes
                      .map((o: QueueOutcomeViewModel) => o.name)
                      .join(", ")}
                    divider={true}
                  />
                )}
                <OutcomeDetailsListItem
                    text={queueWorkItem?.id}
                    icon={<div style={{marginLeft:"3px"}}>#</div>}
                />
{/*                <div style={{}}>{queueWorkItem?.id}</div>*/}
            </OutcomeCard>
            <QueueWorkItemTabs
              hubConnection={hubConnection}
              queueId={currentQueue!.id}
              queueWorkItem={queueWorkItem!}
              onEdit={(responseId) => {
                onWorkItemEdited(queueWorkItem);
                setQuestionnaireResponseId(responseId);
                setShowQuestionnaire(true);
              }}
              onViewPDF={() => {
                downloadPDF(queueWorkItem);
              }}
              admin={hasPermission(state.user, Permissions.SuperUser)}
            />
          </>
        )}
      </Drawer>
      <QueueQuestionnaireDrawer
        questionnaireSideDrawer={questionnaireSideDrawer}
        show={showQuestionnaire}
        drawerTitle={drawerTitle!}
        formFinalised={formFinalised}
        questionnaireResponseId={questionnaireResponseId}
        registerFormUser={registerFormUser}
        allFormDetails={currentQueue.name == "PHEOC" ? true : false}
        onClose={(event: any, reason: string) => {
          if (reason != "backdropClick" && reason != "escapeKeyDown") {
            setShowQuestionnaire(false);
            toggleQuestionnaireSideDrawer.closeDrawer();
            setQuestionnaireResponseId(undefined);
          } else {
          }
        }}
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={4}
        style={{ marginBottom: "10px" }}
      >
        {metrics.totals &&
          MetricCategories.map((category: any, key: number) => (
            <Grid item key={key}>
              <SelectableStatisticCard
                disabled={loading}
                title={
                  <Typography variant={"body1"}>
                    {category || "Unknown"}
                  </Typography>
                }
                value={
                  <Typography variant={"h1"} className={category}>
                    {metrics?.totals
                      ?.filter((metric: any) => metric.status == key)
                      .map((m: any) => m.count)[0] || 0}
                  </Typography>
                }
                onSelected={() => {
                  handleStatusSelect([key]);
                }}
              />
            </Grid>
          ))}
      </Grid>
      <AppBar elevation={3}>
        <>
          {!csvExportMode && (
            <>
              <div>
                {currentQueue.canCreateForm && (
                  <AppBarIconButton
                    title={
                      currentQueue.formCreateHelpText ?? "Create New Referral"
                    }
                    aria-label={
                      currentQueue.formCreateHelpText ?? "Create Referral"
                    }
                    onClick={() => setShowQuestionnaire(true)}
                  >
                    <PostAddIcon />
                  </AppBarIconButton>
                )}
                <AppBarDivider flexItem orientation="vertical" />
              </div>
              <SearchAppBar
                searchFilters={searchFilters}
                queryChanged={(q) => {
                    setSearchFilters({ ...searchFilters, queryText: q })

                    const baseUrl = `/admin/view/${queueId}/`;
                    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
                    const newUrl = guidRegex.test(q) ? `${baseUrl}${q}` : baseUrl;
                    history.push(newUrl);
                }}
              />
              <AppBarDivider flexItem orientation="vertical" />
              <CalendarsDateRangePicker
                key={queueId}
                onOpen={() => setIsDatePickerExpanded(true)}
                onClose={() => setIsDatePickerExpanded(false)}
                disabled={loading}
                isDatePickerExpanded={isDatePickerExpanded}
                onRangeChange={onDatePickerChangeHandler}
              />
              <AppBarDivider flexItem orientation="vertical" />
              <ToggleButtonGroup
                size="large"
                value={searchFilters?.status}
                onChange={handleStatusChange}
                aria-label="text alignment"
                style={{marginTop:'auto', marginBottom:'auto'}}
              >
                {QueueWorkItemStatuses.map((t) => (
                  <TooltipToggleButton
                    key={t}
                    title={MetricCategories[t]}
                    value={t}
                    className={MetricCategories[t]}
                    disabled={loading}
                  >
                    {WorkItemIcon(t as QueueWorkItemStatus)}
                  </TooltipToggleButton>
                ))}
              </ToggleButtonGroup>
            </>
          )}

          {csvExportMode && (
            <>
              <StyledFTextField
                state={fields.startDateTime}
                label={"Start DateTime"}
                type={"datetime-local"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <StyledFTextField
                state={fields.endDateTime}
                label={"End DateTime"}
                type={"datetime-local"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {/*<StyledFTextField*/}
              {/*  state={fields.questionnaireId}*/}
              {/*  label={"Questionnaire ID"}*/}
              {/*/>*/}
              <FCheckbox
                label={"Re-Export Records"}
                state={fields.reExportRecords}
              />
              <Button
                onClick={retrieveCSVExportRecords}
                disabled={
                  startDateFieldValue == "" ||
                  endDateFieldValue == "" ||
                  csvLoading
                }
              >
                Retrieve
              </Button>

              <Button
                onClick={exportRecordsToCSV}
                disabled={
                  (queue &&
                    (!queue.queueWorkItems ||
                      queue.queueWorkItems.length == 0)) ||
                  csvLoading
                }
              >
                Export
              </Button>
            </>
          )}

          {currentQueue.canExportCSV && (
            <>
              <AppBarDivider flexItem orientation="vertical" />

              <FormControlLabel
                control={
                  <Switch
                    checked={csvExportMode}
                    onChange={(_, exportMode) => {
                      setCSVExportMode(exportMode);
                      if (exportMode && queue) {
                        setQueue({ ...queue, queueWorkItems: [] });
                      }
                    }}
                  />
                }
                label="CSV Export"
              />
            </>
          )}
        </>
      </AppBar>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        spacing={4}
        style={{ marginTop: "15px" }}
      >
        <Grid item xs={7}>
          {loading || csvLoading ? (
            loading ? (
              <SkeletonCard count={5} />
            ) : (
              <>
                <CircularProgress />
                CSV Exporting...
              </>
            )
          ) : queueWorkItems.length > 0 ? (
            renderCards()
          ) : (
            <SelectableCard
              style={{ marginBottom: "15px", display: "flex" }}
              icon={<InfoOutlinedIcon />}
              content={
                <>
                  <Typography variant={"h5"}>No Referrals Found</Typography>
                  <SelectableCardIconContentItem
                    style={{ marginTop: "10px" }}
                  />
                </>
              }
            />
          )}
        </Grid>
        <Grid item xs={5}>
          {!csvExportMode && treeData && treeData.length > 0 && (
            <Grid>
              <CbxTree
                generic
                notFoundContent="No outcomes to select"
                nodes={treeData}
                multiSelect={true}
                header={"Outcome Filters"}
                onChecked={(checked: string[]) => {
                  setSearchFilters({ ...searchFilters, outcomes: checked });
                  setIsFilterChanged(true);

                    //if (queue && queue.queueWorkItems) {
                    //    setQueueWorkItems(
                    //        queue.queueWorkItems.filter((q: QueueWorkItemViewModel) => {
                    //            if (q.outcomes?.some((r) => checked.includes(r.id)) == true)
                    //                return q;
                    //        })
                    //    );
                    //}
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <AssignToQueueDialog
        open={assignToQueueMode}
        onClose={handleClose}
        onAssignAndClose={handleClose}
        currentQueueId={currentQueue?.id}
        workItemId={queueWorkItem?.workItem.id}
        queues={queues}
        currentQueueName={currentQueue.name}
      />
      <AddNoteDialog
        handleClose={handleClose}
        noteMode={noteMode}
        submitNote={addNote}
        key={noteKey}
      />
      <Dialog
        open={referralFormInitiated && !referralFormFinalised}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ClosableDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Refferal is not finalised!
        </ClosableDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Press 'Continue' to continue with Referral. Otherwise, press 'Close'
            to close Referral from. No Queue Referral Item will be created.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleClose} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

  async function loadMore() {
    setLoadingMore(true);
    setHasMore(false);
    const queueData = await doItemQuery(queueWorkItems.length);
    setQueue({
      ...queueData,
      queueWorkItems: [...queueWorkItems, ...queueData.queueWorkItems!],
    });
    setLoadingMore(false);
  }

  function renderCards() {
      var toRender = queueWorkItems;
      if (searchFilters.outcomes != undefined && searchFilters.outcomes.length > 0) {
              toRender = queueWorkItems.filter((q: QueueWorkItemViewModel) => {
                  if (q.outcomes?.some((r) => searchFilters.outcomes?.includes(r.id)) == true)
                      return q;
              })
      }
    return (
      <>
        {toRender!.map((queueWorkItem: any) => (
          <SelectableCard
            key={queueWorkItem.id}
            onSelected={() => {
              // setQueueWorkItemStatusMethod(QueueWorkItemStatus.Viewed);
              //setDrawerTitle(`${queueWorkItem.status} Referral`);
              //onQueueWorkItemSelected(queueWorkItem);
              //setQueueWorkItemLoading(true)
              //setQueueWorkItem(queueWorkItem);
              workItemStatusChanged(queueWorkItem);
                toggleSideDrawer.openDrawer();

                history.push(`/admin/view/${queueId}/${queueWorkItem.id}`);
            }}
            style={{ marginBottom: "15px", display: "flex" }}
            icon={WorkItemIcon(queueWorkItem.status)}
            severity={MetricCategories[queueWorkItem.status]}
            content={
              <>
                <Typography variant={"body1"}>
                    {queueWorkItem.workItem.person.firstName}{" "}
                    {queueWorkItem.workItem.person.lastName}
                </Typography>
                <SelectableCardIconContentItem
                  style={{ marginTop: "5px" }}
                  icon={<EventIcon />}
                  text={format(
                    new Date(queueWorkItem.workItem.createdAt),
                    "dd-MM-yyyy HH:mm:ss"
                  )}
                />

                {queueWorkItem.outcomes &&
                  queueWorkItem.outcomes.length > 0 && (
                    <SelectableCardIconContentItem
                      style={{ marginTop: "5px" }}
                      icon={<HomeIcon />}
                      text={queueWorkItem.outcomes
                        .map((o: QueueOutcomeViewModel) => o.name)
                        .join(", ")}
                    />
                  )}
                {queueWorkItem.nurse && (

                    <SelectableCardIconContentItem
                        style={{ marginTop: "5px" }}
                        icon={<UserNurseIcon />}
                        text={queueWorkItem.nurse.firstName+ " "+queueWorkItem.nurse.lastName}
                    />
                    
                )}

                {queueWorkItem.personAssignedTo && (
                  <>
                    <br />
                    <PersonAvatar
                      initials={`${queueWorkItem.personAssignedTo.firstName
                        .toString()
                        .charAt(0)
                        .toUpperCase()}${queueWorkItem.personAssignedTo.lastName
                        .toString()
                        .charAt(0)
                        .toUpperCase()} `}
                      fullName={`${queueWorkItem.personAssignedTo.firstName} ${queueWorkItem.personAssignedTo.lastName}`}
                    />
                  </>
                )}
                    
                    {/*<SelectableCardIconContentItem*/}
                    {/*    style={{ marginTop: "5px" }}*/}
                    {/*    icon={<><div className="makeStyles-contentIcon-50" style={{width:"20.8px", height:"20.8px", paddingLeft:"3px"}}>#</div></>}*/}
                    {/*    text={queueWorkItem.id}*/}
                    {/*>*/}
                    <div style={{marginTop: "10px", fontSize:"small"}}>{queueWorkItem.id}</div>
              </>
            }
          />
        ))}
        {(loadingMore || hasMore) && (
          <div ref={sentryRef}>
            <Box textAlign={"center"}>
              <CircularProgress />
            </Box>
          </div>
        )}
      </>
    );
  }

  function editor(xs: GridSize, ...fields: ReactElement[]) {
    if (editPerson) {
      return (
        <Grid container spacing={1}>
          {fields.map((f, i) => (
            <Grid item xs={xs} key={i}>
              {f}
            </Grid>
          ))}
        </Grid>
      );
    }
    return undefined;
  }

  // function startEditingPerson() {
  //   const p = queueWorkItem!.workItem.person;
  //   const d = parse(p.shortDateOfBirth!, "dd/MM/yyyy", new Date());
  //   personForm.setValue({
  //     ...p,
  //     dateOfBirth: format(d, "yyyy-MM-dd"),
  //     addressFull: p.addressFull ?? {},
  //   });
  //   personForm.setTouched(false);
  //   personForm.setDisabled(false);
  //   setEditPerson(true);
  // }

  async function savePerson() {
    personForm.setDisabled(true);
    const _personData = personForm.toObject();
    const personData = {
      ..._personData,
      address: toFullAddressLine(_personData.addressFull),
    };

    const existingPerson = queueWorkItem?.workItem.person;
    await personsClient.editPerson(existingPerson!.id, personData);
    const newPerson = {
      ...existingPerson,
      ...personData,
      shortDateOfBirth: format(parseISO(personData.dateOfBirth), "dd/MM/yyyy"),
    };
    setQueueWorkItem((qwi: any) => ({
      ...qwi,
      workItem: { ...qwi.workItem, person: newPerson },
    }));

    setQueueWorkItems((qwi: any) =>
      qwi?.map((qw: any) =>
        qw.id == queueWorkItem!.id
          ? { ...qw, workItem: { ...qw.workItem, person: newPerson } }
          : qw
      )
    );

    setEditPerson(false);
  }

  async function retrieveCSVExportRecords() {
    setCsvLoading(true);
    const csvExportData = form.toObject();
    const csvQueueWorkItems =
      await adminQuestionClient.getCSVExportForWorkItemId(
        queueId,
        currentQueue.defaultExportQuestionnaireId!,
        csvExportData
      );

    setQueue(csvQueueWorkItems);
    setCsvLoading(false);
  }

  async function exportRecordsToCSV() {
    setCsvLoading(true);
    if (queue && queue.queueWorkItems) {
      const ids = queue.queueWorkItems.map((x) => x.workItem!.id);

      if (ids) {
        const result = await adminQuestionClient.getExportsForWorkItems(
          currentQueue.defaultExportQuestionnaireId!,
          ids
        );

        saveAs(
          result.data,
          `eforms_export_${format(new Date(), "dd-MM-yyyy HH:mm ")}.csv`
        );
        setCSVExportMode(false);
      }
    }
    setCsvLoading(false);
  }
}

const AllQueueWorkItems = [
  QueueWorkItemStatus.New,
  QueueWorkItemStatus.Viewed,
  QueueWorkItemStatus.PDFDownloaded,
];

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export const ClosableDialogTitle = withStyles(styles)(
  (props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h5">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }
);

function SearchAppBar({
  searchFilters,
  queryChanged,
}: {
  searchFilters: SearchFilter;
  queryChanged: (text: string) => void;
}) {
  const [query, setQuery] = useState(searchFilters.queryText);
  const debounced = useDebounce(query, 500);
  useEffect(() => {
    queryChanged(debounced);
  }, [debounced]);
  useEffect(() => {
    setQuery(searchFilters.queryText);
  }, [searchFilters.queryText]);
  return (
    <AppBarSearch value={query} onChange={(e) => setQuery(e.target.value)} />
  );
}

export function AddNoteDialog({
  handleClose,
  noteMode,
  submitNote,
}: {
  handleClose: () => void;
  noteMode: boolean;
  submitNote: (noteText: string, global: boolean) => void;
}) {
  const [noteTextField, setNoteTextField] = useState<string>("");
  const [globalNote, setGlobalNote] = useState(true);

  const handleNoteCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGlobalNote(event.target.checked);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={noteMode}
    >
      <ClosableDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Referral Notes
      </ClosableDialogTitle>
      <DialogContent dividers>
        <ValidationTextField
          style={{ width: "500px", height: "400px" }}
          aria-label="minimum height"
          // error={!formErrors.firstName.valid}
          // helperText={formErrors.firstName.errorMessage}
          margin="normal"
          fullWidth
          id="notes"
          label="Notes"
          name="notes"
          autoFocus
          required
          onChange={(e: any) => {
            setNoteTextField(e.target.value);
          }}
          variant="outlined"
          multiline={true}
          rows={3}
        />
        <FormControlLabel
          label={"Global Note"}
          control={
            <Checkbox
              checked={globalNote}
              onChange={handleNoteCheckboxChange}
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => submitNote(noteTextField || "", globalNote)}
          disabled={noteTextField == "" || !noteTextField}
        >
          Save Notes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

let noteKey = 0;
