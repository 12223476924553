import React from 'react'
import {withStyles} from "@material-ui/core/styles";
import {Button, Box, IconButton, Tooltip} from "@material-ui/core";
import shadows from '@material-ui/core/styles/shadows';
import { ToggleButton } from '@material-ui/lab';

const StyledIconButton = withStyles((theme) => ({
    root: {
        fontSize: '3rem',
        padding: '5px!important',
        color: theme.palette.primary.light
    },
    label:{
        '& a':{
            color: theme.palette.primary.light + '!important'
        }
    }
    
}))(IconButton);

const StyledTextButton = withStyles((theme) => ({
    root: {
        fontSize: '1rem',
        padding: '5px!important',
        color: '#55437e',
        width: '50px',
        '&.Mui-selected': {
            backgroundColor: '#55437e',
            color: '#fff',
            '&:hover': {
                backgroundColor: '#55437e',
                color: '#fff',
            }
        },
    },
    label: {
        '& a': {
            color: theme.palette.primary.light + '!important'
        }
    }
}))(ToggleButton);

// @ts-ignore
export const AppBarIconButton = ({children, title, ...props}) => (
    <Tooltip title={title}>
        <StyledIconButton {...props}>
            {children}
        </StyledIconButton>
    </Tooltip>
)

// @ts-ignore
export const AppBarTextButton = ({children, title, ...props}) => (
    <Tooltip title={title}>
        <StyledTextButton {...props}>
            {children}
        </StyledTextButton>
    </Tooltip>
)