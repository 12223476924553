import { User } from "../../state/State.model"


export enum Permissions {
  SuperUser = 0x1,
  /// <summary>
  /// provides edit access for all roles & users
  /// </summary>
  ModifyRoles = 0x2,
  ModifyLocations = 0x4,
  ModifyQuestions = 0x8,
  ModifyAudiences = 0x10,

  IgnoreLocationConstraints = 0x20,
  IgnoreAudienceConstraints = 0x40,
  ModifyDayPasses = 0x80,
  ModifyTeams
}

export const hasPermission = (user: User | null, permission: Permissions): boolean => {
    if (!user) {
        return false;
    }

    if (user.roles?.length === 0) {
        return false;
    }

    for(const role of user.roles) {
        const p = role.permissions;

        if ((p & Permissions.SuperUser) > 0) {
            return true;
        }

        if (( p & permission ) > 0) {
            return true;
        }
    }

    return false;
}

