import { useHistory, useParams } from "react-router";
import { useApiClient } from "../../../../common/apiclients";
import {
  BasicQuestionnaireViewModel,
  EditQueueChoiceVm,
  EditQueueViewModel,
  QuestionnairesClient,
  QueueClient,
} from "../../../../common/client";
import React, { useEffect, useState } from "react";
import {
  arrayControl,
  buildGroup,
  control,
  ControlType,
  FormArray,
} from "@react-typed-forms/core";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { FTextField } from "../../../../common/formComponents/FTextField";
import { FCheckbox } from "../../../../common/formComponents/FCheckbox";

const choiceForm = buildGroup<EditQueueChoiceVm>()({
  name: "",
  description: "",
  notificationEmail: "",
  grouping: "",
  id: control(undefined),
  filterable: false,
  assignable: false,
});

export const QueueFormDef = buildGroup<EditQueueViewModel>()({
  name: "",
  defaultForm: control(undefined),
  canAccessGlobalFiles: false,
  queueOutcomes: arrayControl(choiceForm),
  canCreateForm: true,
  questionnaires: arrayControl(control("")),
  launchForm: false,
  formCreateHelpText: "",
  defaultCSVQuestionnaireId: control(undefined),
  canExportCSV: false,
  notificationEmail: ""
});

export type QueueForm = ControlType<typeof QueueFormDef>;

export function QueueEditor() {
  const client = useApiClient(QueueClient);
  const [form] = useState(QueueFormDef);
  const fields = form.fields;
  const { id } = useParams<{ id: string | undefined }>();
  const [saving, setSaving] = useState(false);
  const [queue, setQueue] = useState<EditQueueViewModel>();
  const history = useHistory();
  const qsclient = useApiClient(QuestionnairesClient);
  const [questionnaires, setQuestionnaires] =
    useState<BasicQuestionnaireViewModel[]>();

  useEffect(() => {
    if (id) {
      loadQueue(id);
    }
  }, [id]);

  useEffect(() => {
    loadQuestionnaires();
  }, []);

  async function loadQuestionnaires() {
    const allQ = await qsclient.getQuestionnaires();
    setQuestionnaires(allQ);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FTextField state={fields.name} label="Name" fullWidth />
        </Grid>
        <Grid item xs={8}>
          <FTextField
            state={fields.defaultForm}
            label="Default Form"
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <FCheckbox
            state={fields.canAccessGlobalFiles}
            label="Can Access Global Files"
          />
        </Grid>
        <Grid item xs={8}>
          <FCheckbox state={fields.canCreateForm} label="Can Create Form" />
        </Grid>
        <Grid item xs={8}>
          <FCheckbox
            state={fields.launchForm}
            label="Launch form immediately"
          />
        </Grid>
        <Grid item xs={8}>
          <FTextField
            fullWidth
            state={fields.formCreateHelpText}
            label="Form launch tooltip"
          />
        </Grid>
        <Grid item xs={8}>
          <FCheckbox
              state={fields.canExportCSV}
              label="Can Export CSV"
          />
        </Grid>
        {questionnaires ? (
        <Grid item xs={6}>
          <FTextField
              select
              state={fields.defaultCSVQuestionnaireId}
              label="Default CSV Export Questionnaire"
              fullWidth
          >
            {questionnaires.map((q) => (
                <MenuItem value={q.id}>{q.name}</MenuItem>
            ))}
          </FTextField>
        </Grid>
        ): (
        <CircularProgress />
        )}
      </Grid>
      <Box my={3}>
        <FormArray state={fields.queueOutcomes}>
          {(outcomes) =>
            outcomes.map((o, i) => (
              <Grid item xs={6}>
                <FTextField state={o.fields.name} label="Name" fullWidth />
                <FTextField state={o.fields.notificationEmail} label="Notification Email" fullWidth />
                <FTextField state={o.fields.grouping} label="Grouping" fullWidth />
                <FCheckbox state={o.fields.filterable} label="Filterable" />
                <FCheckbox state={o.fields.assignable} label="Assignable" />
                <Button onClick={() => fields.queueOutcomes.remove(i)}>
                  Delete
                </Button>
              </Grid>
            ))
          }
        </FormArray>
        <Button
          onClick={() => {
            fields.queueOutcomes.add();
          }}
        >
          Add Queue Outcome
        </Button>
      </Box>
      {questionnaires ? (
        <Box my={3}>
          <Grid container>
            <FormArray state={fields.questionnaires}>
              {(qaires) =>
                qaires.map((o, i) => (
                  <>
                    <Grid item xs={6}>
                      <FTextField
                        select
                        state={o}
                        label="Queustionnaire"
                        fullWidth
                      >
                        {questionnaires.map((q) => (
                          <MenuItem value={q.id}>{q.name}</MenuItem>
                        ))}
                      </FTextField>
                    </Grid>
                    <Grid item xs={3}>
                      <Button onClick={() => fields.questionnaires.remove(i)}>
                        Delete
                      </Button>
                    </Grid>
                  </>
                ))
              }
            </FormArray>
          </Grid>
          <Button
            onClick={() => {
              fields.questionnaires.add();
            }}
          >
            Add Questionnaire
          </Button>
        </Box>
          
      ) : (
        <CircularProgress />
      )}
      <Box my={3}>
        <Button disabled={saving} onClick={() => saveQueue()}>
          Save
        </Button>
      </Box>
    </>
  );

  async function loadQueue(id: string) {
    const queue = await client.getQueueForEdit(id);
    console.log(queue)
    const data = {
      ...queue,
      queueOutcomes: queue.queueOutcomes ? queue.queueOutcomes : [],
    };
    form.setValue(data);
    setQueue(queue);
  }

  async function saveQueue() {
    console.log(form.toObject());
    setSaving(true);
    id
      ? await client.editQueue(id, form.toObject())
      : await client.addQueue(form.toObject());
    setSaving(false);

    history.push("/admin/queues");
  }
}
