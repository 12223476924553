import React, {useEffect} from 'react';
import {makeStyles, createStyles, Theme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import {AppBar, Box, Dialog, Drawer, IconButton, Paper, Slide, Toolbar} from "@material-ui/core";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import {TransitionProps} from "@material-ui/core/transitions";
import {useHistory} from "react-router";
import { useQuestionnaireContext } from '../../QuestionnaireContext';

enum MenuDialog {
    Privacy = 'Privacy',
    About = 'About',
    None = 'None'
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            minWidth: '100vw',
            borderBottom: `10px solid ${theme.palette.primary.main}`,//#bad97c',
            [theme.breakpoints.down('sm')]: {
                position: 'fixed',
                top: 0,
                zIndex: 20,
                borderBottom: 'none'
            },
        },
        focusVisible: {
            outline: '-webkit-focus-ring-color auto 1px',
         },
        paper: {
            minWidth: '100vw',
            backgroundColor: theme.palette.primary.main,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            [theme.breakpoints.down('sm')]: {
                height: '70px',
            },
        },
        KOTOCLogo: {
            
            [theme.breakpoints.down('sm')]: {
                // display: 'none',
                width: '60px',

            },
            // TODO the tab index
            // tabindex:'0',
            width: '128px',
            // Thomas commoned 28/06/21
            // filter: 'invert(100%)',
            //margin: '30px 0 30px 10px',
            margin: '3px 0 3px 10px',
            float: 'right',

        },
        tasGovLogo: {
            // thomas commoned out
            [theme.breakpoints.down('sm')]: {
                // display: 'none',
                width: '60px',

            },
            width: '128px',
            // Thomas commoned 28/06/21
            // filter: 'invert(100%)',
            //margin: '30px 0 30px 10px',
            margin: '3px 0 3px 0px',
            float: 'right',

        },
        tasGovLogoMenu: {
            [theme.breakpoints.down('sm')]: {
                //display: 'none',
            },
            width: '128px',
            //Thomas commoned 28/06/21
            // filter: 'invert(100%)',
            margin: '10px 0 10px 10px',
            float: 'right',

        },
        mgAuto: {
            margin: 'auto'
        },
        img: {
            height: '10rem',
            backgroundColor: theme.palette.primary.main,
            // thomas added
            [theme.breakpoints.down('sm')]: {
                height: '4rem',
            },
        },
        heading: {
            opacity: '80%',
            color: theme.palette.primary.contrastText,
            WebkitFontSmoothing: 'antialiased',
            fontWeight: 500,
            position: 'relative',
            marginTop: '2px!Important',
            '& p': {
                [theme.breakpoints.up('md')]: {
                    fontSize: '2.5rem!important',
                    paddingTop: '120px',
                },
            },
            '& span': {

                [theme.breakpoints.down('sm')]: {
                    //bottom: '-50px',
                    //left: '10px',
                    //position: 'absolute',

                    // Thomas changed 2 to 1.3 added the padding left 20px
                    // remove the padding20px changed font size to 1.3 to 1.0
                    fontSize: '1.0rem!important',
                    paddingLeft: '20px',
                },


                [theme.breakpoints.up('md')]: {
                    marginLeft: '40px',
                    color: theme.palette.primary.contrastText,
                },

            }

        },
        drawerMenuIcon: {
            padding: 'unset!important',
            fontSize: '2.5rem',
            color: theme.palette.primary.contrastText,
            paddingLeft: '15px!important',
            paddingTop: '5px!important',
            opacity: ' 80%',
            '&:focus': {
                outline: 'none !important'
            },
        },
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
        version: {
            fontSize: '0.8em!important',
        },
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        text: {
            textAlign: 'justify',
            padding: '10px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '0.9rem!important',
                paddingBottom: '10px',
            },


        },
        noBg: {
            backgroundColor: 'unset',
        },
        noHover: {
            '&:hover': {
                backgroundColor: 'inherit',
                cursor: 'inherit'
            }
        },
        alignItemsLeft: {
            alignItems: 'flex-start!important'
        },
        noBorder: {
            border: 'none',
        },
        noPadding: {
            padding: '0px!important',
        },
        mt_15: {
            marginTop: '15px'
        },
        mb_15: {
            marginBottom: '15px'
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.primary.main,
            // thomas changed 60 to 100
            width: '60px',
            height: '60px',

        },
        heading2: {
            textAlign: 'left',
            paddingBottom: '15px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '1rem!important',
                paddingBottom: '10px',
            },
            [theme.breakpoints.up("sm")]: {
                fontSize: '1.2rem!important',
                fontWeight: '600!important',
                lineHeight: ' 1.4375rem',
                letterSpacing: '-.01875rem'
            },

        },
        icon: {
            color: theme.palette.primary.contrastText,
            maxWidth: 'unset',
            maxHeight: 'unset',
            [theme.breakpoints.up("sm")]: {
                fontSize: '3rem!important',
                margin: '10pxImportant'
            },
            fontSize: '3rem!important',
            margin: '10pxImportant'

        },
        tasLogo: {
            fontSize: '4.2em!important',
            marginTop: '15px',
        },
        bgc: {
            backgroundColor: theme.palette.primary.main
        },
        welcome: {
            fontSize: '1.5em!important'
        },
        lh_05: {
            lineHeight: '0.5',
            [theme.breakpoints.down("md")]: {
                lineHeight: '0.4',
                paddingTop: '30px',
                color: theme.palette.primary.contrastText,
            },
        },
        uatHeader: {
          display: 'flex',
          justifyContent: 'center',
          color: "red",
          fontSize: '2.5rem',
          marginTop: '-100px',
        },
        subSmallHeader: {
            fontSize: '1rem!important',
            color: theme.palette.primary.contrastText,
            marginBottom: '10px',
            marginLeft: '380px',
            fontStyle: 'italic',
            lineHeight: '0.6',
            opacity: '80%',

            WebkitFontSmoothing: 'antialiased',
            fontWeight: 500,
            position: 'relative',
            [theme.breakpoints.down("sm")]: {
                lineHeight: 2,
                marginLeft: '10px'
            },
            '& span': {

                [theme.breakpoints.down('sm')]: {
                    fontSize: '0.7rem!important'
                },
            }
        }
    }),
);
type Anchor = 'top' | 'left' | 'bottom' | 'right';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Header = () => {
    const classes = useStyles();
    //GLOBAL STATE STORE
    //const {state, dispatch} = useContext(Context);
    const history = useHistory();
    // const location = useLocation();

    const [drawerState, setdrawerState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const url = window.location.href;
    const [open, setOpen] = React.useState(false);
    const [activeDialog, setActiveDialog] = React.useState<MenuDialog>(MenuDialog.None);
    const [ UAT, setUAT] = React.useState(false);
    const handleClickOpen = (activeDialog: MenuDialog) => {
        setOpen(true);
        setActiveDialog(activeDialog)
    };
    const handleClose = () => {
        setOpen(false);
    };
    const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setdrawerState({...drawerState, [anchor]: open});
    };
    
    useEffect(() => {
      if (url.includes('.uat.')) {
        setUAT(true);
      }
      }, [url])


    const { questionnaireTitle } = useQuestionnaireContext();

    return (
        <div className={classes.root}>
            <Box className={classes.paper}>
                <Grid container>
                    <Grid md={1} xs={1} item className={classes.KOTOCLogo}>
                        <ButtonBase focusVisibleClassName={classes.focusVisible} onClick={() => history.push('/')}>
                            <img
                                className={classes.img}
                                src="/LiL.png"
                                alt="Site Logo"/>
                        </ButtonBase>
                    </Grid>
                    <Grid xs={8} md={8} item className={classes.heading}>
                        <Typography className={classes.lh_05}>
                            <span style={{ whiteSpace: 'nowrap' }}>{questionnaireTitle != "" ? questionnaireTitle + " Referral Form" : "CHaPS to LiL/CFLC or WT Referral Form"}  {UAT && (<div className={classes.uatHeader}>Do not enter referrals here – Test site only</div>) }</span>
                        </Typography>
                      
                    </Grid>
                </Grid>
            </Box>
            <Drawer anchor={'left'} open={drawerState['left']} onClose={toggleDrawer('left', false)}>
                {/*{list('left')}*/}
            </Drawer>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography className={clsx(classes.welcome, classes.title)}>
                            {activeDialog}
                        </Typography>
                    </Toolbar>
                </AppBar>
                {/*{dialog(activeDialog)}*/}
            </Dialog>
        </div>
    );
}