import { useApiClient } from "../../../../common/apiclients";
import {
  ApiException,
  QueueClient,
  WorkItemFileViewModel,
} from "../../../../common/client";
import React, {
  ChangeEvent,
  ChangeEventHandler,
  DragEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { SkeletonTimeLine } from "../../../../components/muiCore/TimeLine";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from "@material-ui/core";
import { saveAs } from "file-saver";
import { styled } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import fileSize from "filesize.js";
import { control } from "@react-typed-forms/core";
import { FCheckbox } from "../../../../common/formComponents/FCheckbox";

type ItemFileModel = WorkItemFileViewModel & {
  action?: boolean;
};

export function QueueWorkItemFiles({
  queueId,
  workItemId,
}: {
  queueId: string;
  workItemId: string;
}) {
  const client = useApiClient(QueueClient);
  const [files, setFiles] = useState<ItemFileModel[]>();
  const [loading, setLoading] = useState(true);
  const fileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setLoading(true);
    loadFiles();
  }, [queueId, workItemId]);

  return loading || !files ? (
    <SkeletonTimeLine />
  ) : (
    <Box>
      <input
        ref={fileRef}
        type="file"
        onChange={uploadFiles}
        multiple
        style={{ display: "none" }}
      />
      <FileDropArea
        onClick={clickFile}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        Drag documents here
      </FileDropArea>
      <List dense>
        {files.map((f) => (
          <ListItem disableGutters key={f.filename}>
            <ListItemText
              primary={
                <>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      downloadFile(f);
                    }}
                  >
                    {f.filename}
                  </a>
                  {Boolean(f.action) && <CircularProgress />}
                </>
              }
              secondary={fileSize(f.length)}
            />

            {f.queueId == queueId && (
              <ListItemSecondaryAction>
                <FormControlLabel
                  control={
                    <Switch
                      checked={f.global}
                      onChange={(_, global) => setGlobal(f, global)}
                    />
                  }
                  label="Global"
                />
                <IconButton onClick={() => deleteFile(f)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  function clickFile() {
    fileRef.current?.click();
  }

  function uploadFiles(e: ChangeEvent<HTMLInputElement>) {
    uploadFileList(e.target.files);
    e.target.value = "";
  }

  function updateFile(
    f: WorkItemFileViewModel,
    update: (f: WorkItemFileViewModel) => WorkItemFileViewModel
  ) {
    setFiles((files) => files?.map((fi) => (fi === f ? update(f) : fi)));
  }

  function uploadFileList(files?: FileList | null) {
    if (!files) return;
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      uploadOne(f);
    }

    async function uploadOne(f: File) {
      const uploadingFile: ItemFileModel = {
        action: true,
        filename: f.name,
        length: f.size,
        global: false,
        queueId: queueId,
      };
      setFiles((files) => [...(files ?? []), uploadingFile]);
      try {
        const newFile = await client.addFile(
          queueId,
          workItemId,
          f.name,
          false,
          f
        );
        updateFile(uploadingFile, () => newFile);
      } catch (e) {
        if (ApiException.isApiException(e)) {
          if (e.status === 413) {
            console.log("File too large");
          }
        }
        console.log(e);
      }
    }
  }

  async function loadFiles() {
    const files = await client.getWorkItemFiles(queueId, workItemId);
    setFiles(files);
    setLoading(false);
  }

  async function downloadFile(f: WorkItemFileViewModel) {
    const result = await client.getFile(f.queueId, workItemId, f.filename);
    saveAs(result.data, f.filename);
  }

  async function deleteFile(f: WorkItemFileViewModel) {
    await client.deleteFile(queueId, workItemId, f.filename);
    setFiles((fl) => fl?.filter((f1) => f1 !== f));
  }

  function handleDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault();
    uploadFileList(e.dataTransfer?.files);
  }

  async function setGlobal(f: WorkItemFileViewModel, global: boolean) {
    await client.setFileFlags(queueId, workItemId, f.filename, global);
    updateFile(f, (f) => ({
      ...f,
      global,
    }));
  }
}

const FileDropArea = styled("div")(({ theme }) => ({
  width: "98%",
  border: "2px dashed",
  textAlign: "center",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
