import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { FTextField } from "../../common/formComponents/FTextField";

export const StyledTextField = withStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },

    fontSize: "1.25rem",
    lineHeight: " 1.4375rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormLabel-root": {
        fontSize: "1rem!Important",
      },
      "& .MuiOutlinedInput-root ": {
        fontSize: "1rem!Important",
      },
    },
  },
}))(TextField);
export const StyledFTextField = withStyles((theme) => ({
  root: {
    "& .MuiFormHelperText-root": {
      fontSize: "0.9rem!Important",
    },

    fontSize: "1.25rem",
    lineHeight: " 1.4375rem",
    letterSpacing: "-.01875rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.25rem",
      lineHeight: " 1.4375rem",
      letterSpacing: "-.01875rem",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiFormLabel-root": {
        fontSize: "1rem!Important",
      },
      "& .MuiOutlinedInput-root ": {
        fontSize: "1rem!Important",
      },
    },
  },
}))(FTextField);
const StyledCalenderTextField = withStyles({
  root: {
    "& input, label ": {
      fontSize: "0.9rem!Important",
    },
    "& label.MuiInputLabel-outlined ": {
      transform: " translate(14px, 7px) scale(1)!important",
    },
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink ": {
      transform: "  translate(14px, -6px) scale(0.75)!important",
    },
    "& .MuiOutlinedInput-root > .MuiOutlinedInput-input": {
      padding: "5px!important",
      width: "110px",
    },
    "& .MuiFormHelperText-root": {
      display: "none!important",
    },
    "& .MuiTypography-root": {
      margin: "0 5px!important",
    },
  },
})(TextField);

export const ValidationTextField = React.forwardRef(function TextField(
  props: TextFieldProps & { children?: React.ReactElement } & any,
  ref: React.Ref<unknown>
) {
  return <StyledTextField {...props} />;
});

export const CalendarTextField = React.forwardRef(function TextField(
  props: TextFieldProps & { children?: React.ReactElement } & any,
  ref: React.Ref<unknown>
) {
  return <StyledCalenderTextField {...props} />;
});
