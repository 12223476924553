import React, { useState } from "react";
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import RadioButtonCheckedOutlinedIcon from "@material-ui/icons/RadioButtonCheckedOutlined";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import { QuestionChoice } from "../../../common/client";
import { FormControl, useControlValue } from "@react-typed-forms/core";

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "0px",
      left: "0px",
      width: "100%",
    },
    display: "flex!important",
    textAlign: "center",
    maxWidth: "660px", //Default width overriden by props.maxWidth
    margin: "auto",
  },
  btnActive: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  btnStartIcon: {
    width: "35px",
    height: "35px",
  },
}));

export const QuestionItemRadioButton = (props: {
  radioButtons: QuestionChoice[] | undefined;
  onSelected: (selected: QuestionChoice) => void;
  readonly?: boolean;
  selected?: string;
}) => {
  const classes = useStyles();
  const [selectedRadioButton, setSelectedRadioButton] = useState<any>();

  return (
    <>
      {props.radioButtons &&
        props.radioButtons.map((radioButton: QuestionChoice, i: number) => {
          const selected = props.selected
            ? props.selected === radioButton.id
            : (radioButton as any).selected;
          return (
            <div key={i}>
              <ListItem
                button
                disabled={props.readonly}
                className={`${selected ? classes.btnActive : ""}`}
                onClick={() => {
                  if (!props.readonly) {
                    setSelectedRadioButton(radioButton);
                    if (props.onSelected) {
                      props.onSelected(radioButton);
                    }
                  }
                }}
              >
                <ListItemIcon>
                  {selected ||
                  selectedRadioButton?.text === radioButton.text ? (
                    <RadioButtonCheckedOutlinedIcon
                      className={`${classes.btnStartIcon}  ${
                        selected ? "selected" : ""
                      }`}
                    />
                  ) : (
                    <RadioButtonUncheckedOutlinedIcon
                      className={`${classes.btnStartIcon}  ${
                        selected ? "selected" : ""
                      }`}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={radioButton.text} />
              </ListItem>
              <Divider />
            </div>
          );
        })}
    </>
  );
};
export const QuestionItemNestedRadioButton = ({
  control,
  ...props
}: {
  radioButtons: QuestionChoice[] | undefined;
  control: FormControl<string | undefined>;
  readonly?: boolean;
}) => {
  const classes = useStyles();
  const selectedChoice = useControlValue(control);
  const theme = useTheme();

  theme.overrides = {
    ...theme.overrides,
    MuiListItem: {
      gutters: {
        padding: "1em 0 1em 1em",
      },
    },
  };

  return (
    <>
      {props.radioButtons &&
        props.radioButtons.map((radioButton, i: number) => {
          const selected = radioButton.id === selectedChoice;
          return (
            <div key={i}>
              <ListItem
                button
                className={`${selected ? classes.btnActive : ""}`}
                disabled={props.readonly}
                onClick={() => {
                  control.setValue(
                    selectedChoice === radioButton.id
                      ? undefined
                      : radioButton.id
                  );
                }}
              >
                <ListItemIcon>
                  {selected ? (
                    <RadioButtonCheckedOutlinedIcon
                      className={`${classes.btnStartIcon}  ${
                        selected ? "selected" : ""
                      }`}
                    />
                  ) : (
                    <RadioButtonUncheckedOutlinedIcon
                      className={`${classes.btnStartIcon}  ${
                        selected ? "selected" : ""
                      }`}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={radioButton.text} />
              </ListItem>
              <Divider />
            </div>
          );
        })}
    </>
  );
};
