import { useEffect, useMemo, useRef } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState, 
} from "@microsoft/signalr";
import { useToken } from "./apiclients";

export type HubHandler = [string, (...data: any[]) => void];

export function useHubConnection(url: string) {
  const token = useToken();
  const hub = useMemo(
    () =>
      new HubConnectionBuilder()
        .withUrl(url, {
          accessTokenFactory: () => token!,
        })
        .withAutomaticReconnect()
        .build(),
    [token]
  );
  useEffect(() => {
    return () => {
      console.log("Stopping hub");

      if (
        hub.state === HubConnectionState.Connected ||
        hub.state === HubConnectionState.Connecting
      ) {
        hub.stop();
      }
    };
  }, [hub]);
  return hub;
}

export function useHubHandlers(
  hubConnection: HubConnection,
  handlers: HubHandler[]
) {
  const handlersRef = useRef<HubHandler[]>(handlers);
  handlersRef.current = handlers;
  useEffect(() => {
    const actualHandlers = handlers.map(([method]) => {
      const staticHandler = function (...args: any[]) {
        handlersRef.current
          .find((h) => h[0] === method)![1]
          .call(null, ...args);
      };
      hubConnection.on(method, staticHandler);
      return [method, staticHandler] as HubHandler;
    });
    if (hubConnection.state === HubConnectionState.Disconnected) {
      hubConnection.start();
    }
    return () => {
      actualHandlers.forEach(([method, handler]) =>
        hubConnection.off(method, handler)
      );
      // hubConnection.stop();
    };
  }, []);
}
