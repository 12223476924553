//Hook useAuth
import React, {createContext, useContext, useEffect, useState} from 'react'
import StateModel, {AuthContext, User} from "../state/State.model";
import * as api from '../data/api'
import {PersonKind} from "../state/State.model";



const initialState: AuthContext = {
    user: null
};


const authContext = createContext(initialState);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const ProvideAuth = (props:any) => {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{props.children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};
// Provider hook that creates auth object and handles state
const useProvideAuth = ():AuthContext => {
    const [user, setUser] = useState<User | null>(null);
    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const signIn = (firstName:string, lastName:string, contactNumber:string, dob:string, address:string):any => {
        return api
            .getJwtToken(firstName, lastName, contactNumber, 6, {
                dateOfBirth: dob,
                address: address
            })
            .then((response) => {
                setUser(response.user);
                return { user: response.user};
            });
    };
    const signOut = () => {
        setUser(null);
    };
    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    // useEffect(() => {
    //     const unsubscribe = () => {
    //         if (user) {
    //             setUser(user);
    //         } else {
    //             setUser(null);
    //         }
    //     };
    //     // Cleanup subscription on unmount
    //     return () => unsubscribe();
    // }, [user]);
    // Return the user object and auth methods
    return {
        user,
        signIn,
        signOut,
    };
}