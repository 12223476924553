import {
  QuestionAdminViewModel,
  QuestionChoiceViewModel,
  QuestionType,
} from "../../../common/client";
import { AnswersState, GroupAnswerValue } from "./index";
import { FC, ReactElement, useMemo } from "react";

export type RenderQuestionAnswerProps = {
  question: QuestionAdminViewModel;
  choice?: QuestionChoiceViewModel;
  value: any;
};

export function QuestionnaireAnswers({
  questions,
  answers,
  RenderQuestion,
}: {
  questions: QuestionAdminViewModel[];
  answers: AnswersState;
  RenderQuestion: FC<RenderQuestionAnswerProps>;
}) {
  const questionsWithAnswers = useMemo(
    () => questions.filter((q) => Boolean(answers[q.id])),
    [questions, answers]
  );
  return (
    <>
      {questionsWithAnswers.map((qav) => {
        const answer = answers[qav.id];
        const selChoice = qav.choices.find((q) => q.id === answer.choiceId);
        return (
          <RenderQuestion
            question={qav}
            value={answer.value}
            choice={selChoice}
          />
        );
      })}
    </>
  );
}

// export function withGroupElement<A>(
//   q: QuestionAdminViewModel,
//   value: GroupAnswerValue,
//   childId: string,
//   visit: (
//     q: QuestionAdminViewModel,
//     choice: QuestionChoiceViewModel | undefined,
//     value: any
//   ) => A
// ): A | undefined {
//   const nestQuestion = q.nestedQuestions.find((q) => q.id === childId);
//   if (!nestQuestion) return undefined;
//   const nestValue = value[nestId];
//   let choice = selectedChoiceNestedValue(nestQuestion, value);
//   switch (nestQuestion.type) {
//     case QuestionType.RadioButtons:
//     case QuestionType.YesNo:
//       choice = nestQuestion.choices.find((q) => q.id === nestValue);
//       break;
//   }
//   return visit(nestQuestion, choice, nestValue);
// }
//
// export function renderGroupElement(
//   q: QuestionAdminViewModel,
//   value: any,
//   nestId: string,
//   RenderQuestion: FC<RenderQuestionAnswerProps>
// ): ReactElement {
//   return (
//     withGroupElement(q, value, nestId, (question, choice, value) => (
//       <RenderQuestion {...{ question, choice, value }} />
//     )) || <h2>Missing Question</h2>
//   );
// }
