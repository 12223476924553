import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import cx from "clsx";
import { debug } from "console";
import { QuestionChoice } from "../../../common/client";

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "0px",
      left: "0px",
      width: "100%",
      margin: "unset",
      maxWidth: "unset!important",
    },
    display: "flex!important",
    textAlign: "center",
  },
  btnActive: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  btnStartIcon: {
    fontSize: "2rem!important",
    maxWidth: "unset",
    minWidth: "40px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem!important",
    },
  },
}));

export const QuestionItemNextSkipButton = (props: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.box} style={{ maxWidth: `${props.maxWidth}px` }}>
      <ButtonGroup variant={"contained"} fullWidth>
        <Button onClick={() => props.onClick()}>
          <Typography>{props.skipQuestion ? "Next" : "Skip"}</Typography>
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export const QuestionItemChoiceButton = (props: {
  maxWidth?: number;
  choices: QuestionChoice[];
  onSelected: (s: QuestionChoice) => void;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.box} style={{ maxWidth: `${props.maxWidth}px` }}>
      <ButtonGroup variant={"contained"} fullWidth>
        {props.choices &&
          props.choices.map((button: any, i: number) => (
            <Button
              key={i}
              onClick={() => {
                props.onSelected(button);
              }}
            >
              <Typography variant={"body2"}>{button.text}</Typography>
            </Button>
          ))}
      </ButtonGroup>
    </Box>
  );
};
