import React, { useContext, useState } from "react";
import { Button, Box } from "@material-ui/core";
import FormWithValidation from "./FormWithValidation";
import ValidationTextField from "./ValidationTextField";

interface IManagerForm {
  onSucceed?: (teamName: string) => void;
}

const TeamForm = ({ onSucceed }: IManagerForm) => {
  const [teamName, setTeamName] = useState<string>("");
  const [error, setError] = useState<string>();

  return (
    <FormWithValidation>
      <Box
        height="100%"
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box>
          <ValidationTextField
            margin="normal"
            fullWidth
            id="teamName"
            label="Team Name"
            name="teamName"
            autoFocus
            required
            error={error !== undefined}
            helperText={error}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setTeamName(event.target?.value);
            }}
            variant="outlined"
          />
        </Box>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            onSucceed && onSucceed(teamName);
            setTeamName("");
          }}
        >
          Create
        </Button>
      </Box>
    </FormWithValidation>
  );
};

export default TeamForm;
