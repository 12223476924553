import React from "react";
import {Redirect, Route, Switch} from "react-router";
import {Registration} from "./Registration";
import {PublicQuestionnaire} from "./Questionnaire";
import {Finalisation} from "./Finalisation";
import Dashboard from "../modules/admin/views/Dashboard";

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <PublicQuestionnaire />
      </Route>
      <Route exact path="/registration">
        <Registration />
      </Route>
      <Route exact path="/questionnaire">
        <PublicQuestionnaire />
      </Route>
      <Route exact path="/questionnaire/finalisation/:id">
        <Finalisation />
      </Route>
      <Route exact path="/admin">
        <Dashboard />
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
