import React from 'react'
import {List} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";

const StyledOutcomeContentList = withStyles((theme) => ({
    root: {
        display:'flex',
        flexDirection:'column',
        height:'100%'
    }
}))(List);

export const OutcomeList = (props:any) => {
    return <StyledOutcomeContentList>
        {props.children}
    </StyledOutcomeContentList>
}