import React, { useContext, useEffect, useRef, useState } from "react";
//import {Dependant} from "../../../data/Dependant";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Questionnaire } from "../components/core/QuestionSlider/Questionnaire";
import {
  AnswersState,
  autoSelectChoice,
  makeQuestionnaireControl,
  QuestionnaireControls,
  visitQuestions,
} from "../components/core/Questionnaire";
import {
  QuestionAdminViewModel,
  QuestionnaireClient,
  QuestionnairesClient,
  QuestionValidationError,
} from "../common/client";
import { TokenContext, useApiClient } from "../common/apiclients";
import { Context } from "../state/Store";
import { useHistory, useLocation } from "react-router";
import { Backdrop, Box, Button, CircularProgress, Grid, IconButton, Tooltip } from "@material-ui/core";
import { SelectableInfoCard } from "../components/muiCore/Card";
import { SelectableInfoHeaderListItem, SelectableInfoDetailsListItem, SelectableInfoActionListItem } from "../components/muiCore/ListItem";
import { NarrowArrowForward, StreetViewSolid } from "../components/Icons/DoHIcons";
import { useQuestionnaireContext } from "../QuestionnaireContext";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

export function PublicQuestionnaire() {
    const { state } = useContext(Context);
    return (
        <TokenContext.Provider value={state?.user?.token}>
            <InnerQuestionnaire />
        </TokenContext.Provider>
    );
}

function InnerQuestionnaire() {
  const classes = useStyles();
  const region = new URLSearchParams(useLocation().search).get("region");
  const [questionsAndControls, setQuestionsAndControls] = useState<{
    questions: QuestionAdminViewModel[];
    controls: QuestionnaireControls;
  }>();
  const [questionnaireResponseId, setQuestionnaireResponseId] =
    useState<string>();
  const questionnaireClient = useApiClient(QuestionnaireClient);
  const questionClient = useApiClient(QuestionnaireClient);
  const history = useHistory();
  const [messageOpen, setMessageOpen] = useState(false);
  const [errors, setErrors] = useState<QuestionValidationError[]>();
  const [validateAll, setValidateAll] = useState(false);
  const currentPageRef = useRef(0);

  async function handleQuestionnaireFinalisation() {
    await finalise();
  }

  async function finalise() {
    questionnaireClient
      .finaliseQuestionnaire(questionnaireResponseId!)
      .then((result) => {
        switch (result.status) {
          case 1:
            history.push(`/questionnaire/finalisation/${questionnaireResponseId}`);
            break;
          default:
            history.push("/");
            break;
        }
      })
      .catch((err) => console.log(err));
  }

  // async function handleQuestionnaireResponse(questionIndex: number) {
  //   if (questionsAndControls) {
  //     const controls = questionsAndControls.controls;
  //     const questionId = questionsAndControls.questions[questionIndex].id;
  //     const { choiceId, value } = controls.fields[questionId].toObject();
  //     if (choiceId) {
  //       const valueAndChoice = {
  //         choiceId: choiceId,
  //         value: value ? JSON.stringify(value) : undefined,
  //       };
  //       await questionnaireClient.addQuestionChoice(
  //         questionnaireResponseId!,
  //         questionId,
  //         valueAndChoice
  //       );
  //     }
  //   }
  // }

  async function handleQuestionnaireResponse(questionIndex: number) {
    if (questionsAndControls) {
      const controls = questionsAndControls.controls;
      if (controls.dirty) {
        const question = questionsAndControls.questions[questionIndex];
        const questionId = question.id;
        const { choiceId: _choiceId, value } =
          controls.fields[questionId].toObject();
        const choiceId = _choiceId ?? autoSelectChoice(question);
        if (choiceId) {
          const valueAndChoice = {
            choiceId,
            value: value ? JSON.stringify(value) : undefined,
          };
          try {
            await questionnaireClient.addQuestionChoice(
              questionnaireResponseId!,
              questionId,
              valueAndChoice
            );
          } catch (e) {
            // enqueueSnackbar("Failed to save response: " + question.heading, {
            //   variant: "error",
            // });
          }
        }
        if (validateAll) {
          await validate();
        }
        controls.markAsClean();
      }
    }
  }

  async function startQuestionnaire() {
    const questions = await questionnaireClient.getQuestions();
    const controls = makeQuestionnaireControl(questions.questions);
    setQuestionsAndControls({ questions: questions.questions, controls });
    setQuestionnaireResponseId(questions.questionnaireResponseId);
    setValidateAll(false);
    setErrors(undefined);
    await setRegionResponse(
      controls,
      questions.questionnaireResponseId,
      questions.questions
    );
  }

  useEffect(() => {
    startQuestionnaire();
  }, []);

    return questionsAndControls ? (
        <>
            <Box marginTop={5} marginBottom={5}>
                <Questionnaire
                    questions={questionsAndControls.questions}
                    validation={errors ?? []}
                    maxWidth={"100%"}
                    controls={questionsAndControls.controls}
                    onQuestionnaireFinalised={handleQuestionnaireFinalisation}
                    onSaveResponse={handleQuestionnaireResponse}
                    currentPageRef={currentPageRef}
                    overrideSubmit={saveAndValidate}
                    stepper
                />
            </Box>
        </>
  ) : (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  async function saveAndValidate() {
    return await validate();
  }

  async function validate() {
    const errors = await questionClient.validateQuestionnaire(
      questionnaireResponseId!
    );
    setValidateAll(true);
    setErrors(errors);
    questionsAndControls!.controls.setTouched(true);
    const showErrors = errors.length > 0;
    return !showErrors;
  }

  async function checkHighRisk(qIndex: number) {
    if (!questionsAndControls) return true;
    const selQuestion = questionsAndControls.questions[qIndex];
    if (selQuestion && selQuestion.questionKey === "HighRisk") {
      setMessageOpen(true);
      return false;
    }
    return true;
  }

  async function setRegionResponse(
    controls: QuestionnaireControls,
    responseId: string,
    questions: QuestionAdminViewModel[]
  ) {
    let p: Promise<any> | undefined;
    visitQuestions(questions, (q) => {
      if (q.questionKey === "Region") {
        const choice = q.choices.find((c) => c.text.toLowerCase() === region);
        if (choice) {
          p = questionnaireClient.addQuestionChoice(responseId, q.id, {
            choiceId: choice.id,
          });
          const preFill: AnswersState = {};
          preFill[q.id] = { choiceId: choice.id, value: undefined };
          controls.setValue(preFill);
        }
        return false;
      }
      return true;
    });
    if (p) await p;
  }
}

// export const Questionnaire = (props: any) => {
//   const classes = useStyles();
//   //GLOBAL STATE
//   const { state, dispatch } = useContext(Context);
//   let history = useHistory();
//   const auth = useAuth();
//   //COMPONENT STATE
//   const [loading, setLoading] = useState(true);
//   const [questions, setQuestions] = useState<IQuestion[]>([]);
//   const [questionnaireStartId, setQuestionnaireStartId] = useState<string>();
//   const [questionnaireId, setQuestionnaireId] = useState<string>();
//   const [authOpen, setAuthOpen] = React.useState(false);
//   const [messageOpen, setMessageOpen] = React.useState(false);
//   const [currentResponse, setCurrentResponse] = useState<any>();
//   const [bookingMessageOpen, setBookingMessageOpen] = React.useState(false);
//
//   let locationId = "f1372857-ca51-40fe-c2c7-08d8a7b38c2c";
//   const { enqueueSnackbar } = useSnackbar();
//
//   useEffect(() => {
//     if (state.user?.token) {
//       getQuestions(state.user?.token)
//         .then((d) => {
//           if (d) {
//             setQuestions(d);
//             setQuestionnaireStartId(d[0].id);
//             let dependantQuestion = d.find(
//               (c: IQuestion) => c.type === 2
//             ) as IQuestion;
//             if (dependantQuestion != undefined) {
//               getDependants(state.user?.token!)
//                 .then((dep: Dependant[]) => {
//                   if (dep) {
//                     let choices = dependantQuestion.choices;
//                     for (const d of dep) {
//                       choices!.push({
//                         text: `${d.firstName} ${d.lastName}`,
//                         id: d.id,
//                       });
//                     }
//                   }
//                 })
//                 .catch((err) => {
//                   console.log({
//                     type: "SET_ERROR",
//                     payload: `Failed to retrieve dependants. Please try again or contact support for assistance if issue persists.`,
//                   });
//                 });
//             }
//           }
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     } else {
//       history.push("/");
//     }
//   }, []);
//   useEffect(() => {
//     if (questions.length > 0) {
//       setLoading(false);
//     }
//   }, [questions]);
//
//   const handleClose = (newValue?: string) => {
//     setAuthOpen(false);
//     if (newValue) {
//       if (newValue === "submitted") {
//         //props.onResponse(index, qResponse, true)
//         console.log("finalise form");
//         finalise(currentResponse.questionId, currentResponse.selectedChoice);
//
//         //history.push('/questionnaire/finalisation')
//       }
//     }
//   };
//   const handleMessageClose = (newValue?: string) => {
//     finalise(currentResponse.questionId, currentResponse.selectedChoice);
//     setMessageOpen(false);
//     //history.push('/')
//   };
//
//   const saveResponse = (
//     locationId: string,
//     questionId: string,
//     selectedChoiceId: string
//   ) => {
//     if (!questionnaireId) {
//       //initialise questionnaire
//       startQuestionnaire(locationId, state.user?.token!)
//         .then((d) => {
//           console.debug(`Questionnaire::startQuestionnaire`);
//           console.debug(d);
//           setQuestionnaireId(d.questionnaireId);
//           //save first response
//           saveQuestionResponse(
//             d.questionnaireId,
//             questionId,
//             selectedChoiceId,
//             state.user?.token
//           )
//             .then((d) => {})
//             .catch((err) => {});
//         })
//         .catch((err) => {
//           enqueueSnackbar("Failed to save question response", {
//             variant: "error",
//           });
//         });
//     } else {
//       saveQuestionResponse(
//         questionnaireId,
//         questionId,
//         selectedChoiceId,
//         state.user?.token
//       )
//         .then((d) => {
//           console.debug(
//             `Questionnaire::setQuestionsResponseValueByIndex::saveQuestionResponse`
//           );
//           console.debug(d);
//         })
//         .catch((err) => {
//           console.debug(
//             `Questionnaire::setQuestionsResponseValueByIndex::Failed`
//           );
//           console.debug(err);
//         });
//     }
//   };
//   const saveChoice = (questionId: string, selectedChoice: any) => {
//     //update question with selected choice
//     let setSelectedChoice = [
//       ...questions.map((question: any) =>
//         question.id === questionId
//           ? {
//               ...question,
//               choices: question.choices.map((choice: any) =>
//                 choice.id === selectedChoice.id
//                   ? {
//                       ...selectedChoice,
//                       selected: true,
//                     }
//                   : { ...choice, selected: false }
//               ),
//             }
//           : question
//       ),
//     ];
//
//     //save response
//     saveResponse(locationId, questionId, selectedChoice.id);
//
//     //get next question id
//     let nextQuestionId = questions
//       .filter((question: any) => question.id === questionId)[0]
//       .responseMappings?.filter(
//         (mapping: any) => mapping.choiceId === selectedChoice.id
//       )
//       .map((r: any) => r.nextQuestionId)[0];
//
//     //update next question with previous question id
//     let setPreviousQuestionId = [
//       ...setSelectedChoice.map((question: any) =>
//         question.id === nextQuestionId
//           ? {
//               ...question,
//               previousQuestionId: questionId,
//             }
//           : question
//       ),
//     ];
//
//     setQuestions(setPreviousQuestionId);
//   };
//   const saveMultipleChoices = (questionId: string, selectedChoices: any) => {
//     console.log(selectedChoices);
//     //update question with selected choice
//     selectedChoices.forEach((selectedChoice: any) => {
//       setQuestions(
//         questions.map((question: any) =>
//           question.id === questionId
//             ? {
//                 ...question,
//                 choices: question.choices.map((choice: any) =>
//                   choice.id === selectedChoice.id
//                     ? {
//                         ...selectedChoice,
//                         selected: true,
//                       }
//                     : { ...choice, selected: false }
//                 ),
//               }
//             : question
//         )
//       );
//     });
//
//     //save response
//     saveResponse(locationId, questionId, selectedChoices[0].id);
//
//     //get next question id
//     let nextQuestionId = questions
//       .filter((question: any) => question.id === questionId)[0]
//       .responseMappings?.filter(
//         (mapping: any) => mapping.choiceId === selectedChoices[0].id
//       )
//       .map((r: any) => r.nextQuestionId)[0];
//
//     //update next question with previous question id
//     setQuestions([
//       ...questions.map((question: any) =>
//         question.id === nextQuestionId
//           ? {
//               ...question,
//               previousQuestionId: questionId,
//             }
//           : question
//       ),
//     ]);
//   };
//   const finalise = (questionId: string, selectedChoice: any) => {
//     if (!questionnaireId) {
//       console.log(state.user?.token!);
//       //initialise questionnaire
//       startQuestionnaire(locationId, state.user?.token!)
//         .then((startQuesData) => {
//           setQuestionnaireId(startQuesData.questionnaireId);
//           //save first response
//           saveQuestionResponse(
//             startQuesData.questionnaireId,
//             questionId,
//             selectedChoice.id,
//             state.user?.token
//           )
//             .then((d) => {
//               finaliseQuestionnaire(
//                 startQuesData.questionnaireId!,
//                 state.user?.token
//               )
//                 .then((d) => {
//                   console.debug(
//                     `Questionnaire::QuestionSlider::finaliseQuestionnaire`
//                   );
//                   console.debug(d);
//                   switch (d.status) {
//                     case 1:
//                       history.push("/questionnaire/finalisation");
//                       break;
//                     default:
//                       history.push("/");
//                       break;
//                   }
//                 })
//                 .catch((err) => {
//                   enqueueSnackbar("Failed to finalise questionnaire", {
//                     variant: "error",
//                   });
//                   console.error(err);
//                 });
//             })
//             .catch((err) => {
//               enqueueSnackbar("Failed to save question response", {
//                 variant: "error",
//               });
//               console.error(err);
//             });
//         })
//         .catch((err) => {
//           enqueueSnackbar("Failed to initiate questionnaire", {
//             variant: "error",
//           });
//           console.error(err);
//         });
//     } else {
//       saveQuestionResponse(
//         questionnaireId!,
//         questionId,
//         selectedChoice.id,
//         state.user?.token
//       )
//         .then((d) => {
//           finaliseQuestionnaire(questionnaireId!, state.user?.token)
//             .then((d) => {
//               console.debug(
//                 `Questionnaire::QuestionSlider::finaliseQuestionnaire`
//               );
//               console.debug(d);
//               switch (d.status) {
//                 case 1:
//                   history.push("/questionnaire/finalisation");
//                   break;
//                 default:
//                   history.push("/");
//                   break;
//               }
//             })
//             .catch((err) => {
//               enqueueSnackbar("Failed to finalise questionnaire", {
//                 variant: "error",
//               });
//               console.error(err);
//             });
//         })
//         .catch((err) => {
//           enqueueSnackbar("Failed to save question response", {
//             variant: "error",
//           });
//           console.error(err);
//         });
//     }
//   };
//
//   return !loading ? (
//     <>
//       <QuestionItemSlider
//         questions={questions}
//         answers={{}}
//         maxWidth={"700px"}
//         onQuestionAdvanced={(questionId: string, selectedChoice: any) => {
//           if (selectedChoice.length > 1) {
//             saveMultipleChoices(questionId, selectedChoice);
//           } else {
//             saveChoice(questionId, selectedChoice);
//           }
//         }}
//         onEnd={(questionId: string, selectedChoice: any) => {
//           console.log("Questionnaire Ended");
//           console.log(
//             questions.findIndex(
//               (question: IQuestion) => question.id === questionId
//             )
//           );
//           setCurrentResponse({
//             questionId: questionId,
//             selectedChoice: selectedChoice,
//           });
//           if (
//             questions.findIndex(
//               (question: IQuestion) => question.id === questionId
//             ) ===
//             questions.length - 1
//           ) {
//             //reached end of questionnaire
//             console.log("end of form reached");
//
//             setAuthOpen(true);
//           } else {
//             //early termination
//             //finalise(questionId, selectedChoice)
//             if (questionId === "297734ef-eb80-4d84-0a77-08d92409ac60") {
//               //history.push('/quarantine/registration/call')
//               setMessageOpen(true);
//             }
//
//             console.log("early termination");
//           }
//         }}
//       />
//       <AuthorisationDialog open={authOpen} onClose={handleClose} />
//     </>
//   ) : (
//     <Backdrop className={classes.backdrop} open={true}>
//       <CircularProgress color="inherit" />
//     </Backdrop>
//   );
// };
