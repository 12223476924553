import {Grid,Tooltip} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import {format} from "date-fns";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import CommentIcon from "@material-ui/icons/Comment";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import {SelectableCard, SelectableCardIconContentItem} from "./Card";
import {PersonAvatar} from "./Avatar";
import React from "react";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import PublicIcon from "@material-ui/icons/Public";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PersonIcon from '@material-ui/icons/Person';
import {createStyles, makeStyles, Theme, withStyles} from "@material-ui/core/styles";

const TimelineItemFullWidth = withStyles((theme) => ({
    root: {
        
    },
    missingOppositeContent:{
        '&:before':{
            flex:'none',
            content:'unset',
        }
    }
}))(TimelineItem);

export const SkeletonTimeLine = (props: any) => {

    const skeletons = []
    
    for(let i = 0; i < props.count; i++) {
        skeletons.push(
            
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
            >
                <Grid item>
                    <Skeleton variant="circle" width={30} height={30}/>
                    <Skeleton variant="rect" width={3} height={80} style={{margin:'auto', marginTop:'5px'}}/>
                </Grid>

                <Grid item>
                    <Skeleton variant="rect" width={650} height={118}/>
                </Grid>
            </Grid>
            )
    }
    
    return <div style={{margin:'30px'}}>{skeletons}</div>
    
};
export const CommentsTimeLine = (props: any) => {
  return (
    <Timeline>
      {props.comments &&
        props.comments.map((comment: any) => (
          <TimelineItemFullWidth key={comment.id}>
            <TimelineSeparator>
              <TimelineDot>
                  {comment.global ? <Tooltip title={'Global Note'}><PublicIcon/></Tooltip> : <Tooltip title={'Note'}><CommentIcon/></Tooltip>}
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <SelectableCard
                style={{ display: "flex" }}
                content={
                  <>
                    <SelectableCardIconContentItem
                      style={{ marginTop: "5px" }}
                      icon={comment.global ? <PublicIcon /> : <CommentIcon />}
                      text={comment.note}
                    />
                      <SelectableCardIconContentItem
                          style={{marginTop: "5px"}}
                          icon={<PersonIcon/>}
                          text={`${comment.person.firstName} ${comment.person.lastName}`}
                      />
                      <SelectableCardIconContentItem
                          style={{marginTop: "5px"}}
                          icon={<AccessTimeIcon/>}
                          text={format(new Date(comment.createdAt), "HH:mm dd-MM-yyyy")}
                      />
                  </>
                }
              />
            </TimelineContent>
          </TimelineItemFullWidth>
        ))}
    </Timeline>
  );
};
export const AuditTimeLine = (props: any) => {
  return (
    <Timeline align="alternate">
      {props.auditEvents &&
        props.auditEvents.map((event: any, idx: number) => (
          <TimelineItemFullWidth key={idx}>
            <TimelineSeparator>
              <TimelineDot>
                  <Tooltip title={'Audit Event'}><ListAltIcon/></Tooltip>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <SelectableCard
                style={{ display: "flex" }}
                // icon={<UserPlusSolid/>}
                content={
                  <>
                    <SelectableCardIconContentItem
                      style={{ marginTop: "5px" }}
                      icon={<ListAltIcon />}
                      text={event.message}
                    />
                      <SelectableCardIconContentItem
                          style={{marginTop: "5px"}}
                          icon={<PersonIcon/>}
                          text={`${event.person.firstName} ${event.person.lastName}`}
                      />
                      <SelectableCardIconContentItem
                          style={{marginTop: "5px"}}
                          icon={<AccessTimeIcon/>}
                          text={format(new Date(event.createdAt), "HH:mm dd-MM-yyyy")}
                      />
                  </>
                }
              />
            </TimelineContent>
          </TimelineItemFullWidth>
        ))}
    </Timeline>
  );
};
export const QuestionTimeLine = (props: any) => {
  return (
    <Timeline align="alternate">
      {props.questionResponses &&
        props.questionResponses.map((response: any) => (
          <TimelineItem key={response.question.id}>
            <TimelineOppositeContent>
              <Typography variant="body2" color="textSecondary">
                {format(new Date(response.createdAt), "HH:mm dd-MM-yyyy")}
              </Typography>
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <QuestionAnswerIcon />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <SelectableCard
                style={{ display: "flex" }}
                // icon={<UserPlusSolid/>}
                content={
                  <>
                    {/*<Typography variant={'h5'}>*/}
                    {/*    {responses.message}*/}
                    {/*</Typography>*/}
                    <SelectableCardIconContentItem
                      style={{ marginTop: "5px" }}
                      icon={<LiveHelpIcon />}
                      text={response.question.heading}
                    />
                    <SelectableCardIconContentItem
                      style={{ marginTop: "5px" }}
                      icon={<QuestionAnswerIcon />}
                      text={response.questionChoice.text}
                    />
                  </>
                }
              />
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};
