import {
  FormControl,
  genericProps,
  useControlStateVersion,
} from "@react-typed-forms/core";
import {
  FormControl as FC,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import React, { ReactNode } from "react";

export type RadioPropsFunc<A> = (value: A) => {
  checked: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

interface FRadioListProps<A> {
  label: string;
  helperText?: string;
  children: (checkProps: RadioPropsFunc<A>) => ReactNode;
  state: FormControl<A>;
}

export function FRadioList<A extends string | number>({
  state,
  children,
  label,
  helperText,
}: FRadioListProps<A>) {
  useControlStateVersion(state);
  const { errorText } = genericProps(state);
  const checkProps: RadioPropsFunc<A> = (v: A) => {
    return {
      checked: v === state.value,
      onChange: (e, c) => {
        state.setValue(v);
      },
    };
  };
  return (
    <FC error={Boolean(errorText)}>
      <FormLabel>{label}</FormLabel>
      {children(checkProps)}
      <FormHelperText>{errorText ?? helperText}</FormHelperText>
    </FC>
  );
}
