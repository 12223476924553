import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Box, Button, ButtonGroup,
    Typography,
    useMediaQuery,
    useTheme
} from "@material-ui/core";
import {Context} from "../../../state/Store";
import {Dialog, DialogActions, DialogContent, DialogTitle} from '../../muiCore/Dialog'
import {PenFancySolid} from "../../Icons/DoHIcons";
import {OutcomeDetailsListItem} from "../../muiCore/ListItem";

const useStyles = makeStyles((theme) => ({
    transitionWrapper: {
        display: 'block',
        margin: 'auto',
        height: '1000px',
        [theme.breakpoints.down("sm")]: {

            height: 'calc(100vh)',
        },
    },
    slideContainer: {
        display: 'flex',
        alignItems: 'center',
        //flexWrap: 'nowrap',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            height: 'calc(100vh )',
            
        },
        [theme.breakpoints.up("sm")]: {
            width: 700,
            height: '1000px',
        },
        margin: 'auto',

    },
    menuButton: {
        marginRight: theme.spacing(2),
        fontSize: '22px'
    },
    title: {
        flexGrow: 1,
    },
    appBar: {
        boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
        [theme.breakpoints.up("sm")]: {

            height: 100,
        },
    },
    box: {
        [theme.breakpoints.down("sm")]: {
            position: "absolute",
            bottom: '0px',
            left: '0px',
            width: '100%'
        },
        display: "flex!important",
        textAlign: 'center',
        width: 660,
        margin: "auto"


    },
    subBox: {
        flex: "auto",
        position: 'relative',
        '&:not(:last-of-type)': {
            '&:after': {
                content: '" "',
                display: 'block',
                position: 'absolute',
                height: '88%',
                width: 1,
                backgroundColor: theme.palette.grey[500],
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)'
            }
        }
    },
    btnLeft: {
        padding: 15,
        //borderBottomLeftRadius: 12,
        borderRadius: 0,
        '&:focus': {
            outline: 'none !important'
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        borderColor: "unset!important",
        border: "2px solid white",
    },
    btnRight: {
        padding: 15,
        //borderBottomRightRadius: 12,
        borderRadius: 0,
        '&:focus': {
            outline: 'none !important'
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        border: "2px solid white",


    },
    btn: {
        borderColor: "unset!important",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        border: "2px solid white",
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        '&:focus': {
            outline: 'none !important'
        },
    },
    btnActive: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,

    },
    btnGroup: {
        transition: 'background 0.5s, color 1s!important',
        //[theme.breakpoints.up("sm")]: {
        fontSize: '1.25rem',
        lineHeight: ' 1.4375rem',
        letterSpacing: '-.01875rem'
        //}

    },
    icon: {
        fontSize: '2rem!important',
        color: theme.palette.primary.main,
        maxWidth: 'unset',
        minWidth: '40px',
        [theme.breakpoints.up("sm")]: {
            fontSize: '3rem!important',
        }

    },
    iconContrast: {
        fontSize: '3rem!important',
        color: theme.palette.primary.contrastText,
        maxWidth: 'unset',
        minWidth: '40px',
        marginRight: '4px',
        [theme.breakpoints.up("sm")]: {
            fontSize: '3rem!important',
        }

    },
    iconblack: {
        color: '#000'

    },
    iconMargin: {
        marginRight: '-4px!important',
        color: theme.palette.primary.contrastText,
    },
    paper: {
        width: '80%',
        maxHeight: 435,
    },
    heading: {
        [theme.breakpoints.up("sm")]: {
            '& .MuiTypography-h6': {
                fontSize: '1.4rem!important',
                fontWeight: '600!important',
                lineHeight: ' 1.4375rem',
                letterSpacing: '-.01875rem',
                // Thomas 28/06/21
                color: '#FFF',
            }
        },
        [theme.breakpoints.down("sm")]: {
            '& .MuiTypography-h6': {
                fontSize: '1.2rem!important',
                fontWeight: '600!important',
                lineHeight: ' 1.4375rem',
                letterSpacing: '-.01875rem',
                color: '#353535',
                // color:'#fff'
            }
        },
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    textField: {
        [theme.breakpoints.up("sm")]: {
            fontSize: '1.25rem',
            lineHeight: ' 1.4375rem',
            letterSpacing: '-.01875rem'
        }
    },
    innerHeading: {
        fontSize: '1.2rem!important',
        fontWeight: 'bolder',
        paddingTop: '5px',
    },
    lowerLatin: {
        listStyleType: 'lower-latin',
    }
}));
const dayPassStyles = makeStyles((theme) => ({
    card: {
        border: "none",
        //padding:'1rem',
        borderRadius: 0,
        boxShadow: ' 0 0 8px 0 rgba(0,0,0,0.3)',
        backgroundColor: '#35353',
        [theme.breakpoints.down("md")]: {
            minWidth: '100%',
            marginBottom: '70px',

        },
        [theme.breakpoints.up("md")]: {
            marginTop: '10px',

        },
    },
    cardContent: {
        padding: '0px',
        [theme.breakpoints.down("sm")]: {
            minWidth: '100%',
            margin: 'unset',
        },
        '&:last-child': {
            padding: 'unset'
        },
        '& .MuiList-root': {
            padding: '0px!important'
        }
    },
    cardHeader: {
        backgroundColor: '#92b200',//theme.palette.primary.light,
        boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
        minHeight: '70px',
        fontWeight: 550,
        fontSize: theme.typography.fontSize,
        padding: '0.5em 0 0.5em 0.25em',
        display: 'block',
        textAlign: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            padding: '0.5em 0 0.5em 0.25em',
            marginTop:'10px',
            // position: 'fixed',
            // top:0,
            // zIndex:20
        },
        '& span':{

        }

    },
    exemption:{
        backgroundColor: theme.DoH.color.exemption
    },
    failed:{
        backgroundColor: theme.DoH.color.fail
    },
    conditional:{
        backgroundColor: theme.DoH.color.conditional
    },
    cardHeaderText: {
        fontSize: '22px!important',
        color: theme.palette.primary.contrastText
    },
    cardListItem: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        //boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
        minHeight: '70px',
        fontWeight: 550,

        //fontSize: theme.typography.fontSize,
        //padding: '0.5em 0 0.5em 0.25em',
        display: 'block',
        textAlign: 'center',
        justifyContent: 'center',
        // [theme.breakpoints.down("sm")]: {
        //     padding: '0.5em 0 0.5em 0.25em'
        //     // position: 'fixed',
        //     // top:0,
        //     // zIndex:20
        // }
    },
    cardListItemAlt: {
        //boxShadow: '0 2px 4px -2px rgba(0,0,0,0.24), 0 4px 24px -2px rgba(0, 0, 0, 0.2)',
        minHeight: '70px',
        fontWeight: 550,
        textAlign: 'justify',
        //fontSize: theme.typography.fontSize,
        //padding: '0.5em 0 0.5em 0.25em',
        display: 'block',
        // [theme.breakpoints.down("sm")]: {
        //     padding: '0.5em 0 0.5em 0.25em'
        //     // position: 'fixed',
        //     // top:0,
        //     // zIndex:20
        // }
    },
    cardListItemText: {
        fontSize: '22px!important',
        textTransform: 'uppercase'
    },
    textLeft: {
        textAlign: 'justify',
    },
    cardListItemTextAlt: {
        fontSize: '16px!important',
        padding: '10px',
        textTransform: 'unset'
    },
    icon: {
        // fontSize: '4rem!important',
        margin: '10pxImportant',
        color: theme.palette.primary.contrastText,
        maxWidth: 'unset',
        maxHeight: 'unset',
        fontSize: '6rem!important',
        //minWidth: '40px',
        [theme.breakpoints.up("sm")]: {
            // fontSize: '10rem!important',
            margin: '10pxImportant',
            fontWeight: 600
        }

    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
        width: '60px',
        height: '60px',

    },
    heading: {
        [theme.breakpoints.down("sm")]: {
            fontSize: '1.1rem!important',
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: '1.4rem!important',
        },

    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '10px',
        cursor: 'pointer',
        backgroundColor: '#fcfcfc',
        marginTop: '15px',
        transition: 'background-color 0.5s ease',
        //boxShadow: '0 0 8px 0 rgba(0,0,0,0.3)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    box: {
        [theme.breakpoints.down("sm")]: {
            position: "fixed",
            bottom: '0px',
            left: '0px',
            width: '100%',
            margin:'unset',
            maxWidth:'unset!important',
        },
        display: "flex!important",
        textAlign: 'center',
        maxWidth: '660px',//Default width overriden by props.maxWidth
        margin: "10px auto",
    },
}));
export const AuthorisationDialog = (props: any) => {
    const {state, dispatch} = useContext(Context);
    const styles = useStyles();
    const classes = dayPassStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {onSubmit, onClose, value: valueProp, open, ...other} = props;

    const handleClose = () => {
        onClose();
    };
    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = () => {
        onClose('submitted');
    };

    let text = <Typography className={classes.cardListItemTextAlt}>
        By clicking submit I am declaring that to the best of my knowledge the information I have provided is
        accurate and correct.
    </Typography>
    
    let privacy = <> <Typography className={classes.cardListItemTextAlt}>
        This information has been collected for the purpose of:
    </Typography>
        <ol className={styles.lowerLatin}>

            <Typography className={classes.cardListItemTextAlt} component={'li'}>
                the management, detection, notification, treatment or prevention of the spread of COVID-19
            </Typography>

            <Typography className={classes.cardListItemTextAlt} component={'li'}>
                managing the threat to public health, or likely threat to public health, as a result of
                COVID-19
            </Typography>

            <Typography className={classes.cardListItemTextAlt} component={'li'}>
                ensuring compliance with, and enforcing, the Public Health Act 1997.
            </Typography>

        </ol>
        <Typography className={classes.cardListItemTextAlt}>
            The information will not be used, or disclosed, other than as authorised under the Public Health Act
            1997.
        </Typography>
    </>

    return <Dialog fullScreen={isMobile} onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>

        <DialogTitle className={styles.heading} id="customized-dialog-title">
            Declaration and Authorisation
        </DialogTitle>
        <DialogContent dividers>
            <OutcomeDetailsListItem text={text} icon={<PenFancySolid/>}/>
            {(state.user?.kind === 1 || state.user?.kind === 2) && <OutcomeDetailsListItem text={privacy}/>}
        </DialogContent>
        <DialogActions>
            {
                isMobile
                    ? <Box className={styles.box}>
                        <ButtonGroup variant={'contained'} fullWidth className={styles.btnGroup}>
                            <Button
                                onClick={handleCancel}
                                className={`${styles.btnLeft}`}>
                                CANCEL
                            </Button>
                            <Button
                                className={`${styles.btnRight}`}
                                onClick={handleSubmit}
                            >
                                SUBMIT & FINISH
                            </Button>
                        </ButtonGroup>
                    </Box>
                    :
                    <>
                        <Button autoFocus onClick={handleCancel} className={styles.btn}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} className={styles.btn}>
                            SUBMIT & FINISH
                        </Button>
                    </>
            }
        </DialogActions>
    </Dialog>
}