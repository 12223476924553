import {
  Backdrop,
  CircularProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { CommonBTN } from "../components/core/Buttons/CommonBTN";
import { useHistory } from "react-router";
import { useContext, useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useApiClient, TokenContext } from "../common/apiclients";
import PDFDataConverter from "../components/core/PDFGenerator/PDFDataConverter";
import { format } from "date-fns";
import { Context } from "../state/Store";
import { QuestionnaireClient } from "../common/client";

const useGeneralCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: "5px 5px 5px 10px",
      borderBottom: "1px inset",
      marginBottom: "10px",
      fontSize: "2rem",
    },
    root: {
      height: 380,
      flexGrow: 1,
      maxWidth: 500,
      position: "absolute",
      left: "50%",
      top: "41.5%",
      transform: "translate(-50%, -50%)",
    },
    content: {
      alignSelf: "center",
      textAlign: "center",
      marginTop: "12px",
    },
    Btn: {
      padding: "25px 10px 10px 50px",
      alignContent: "center",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#ffffff",
    },
  })
);

export const Finalisation = () => {
  const { state } = useContext(Context);
 
  return (
    <TokenContext.Provider value={state?.user?.token}>
      <FinalisationWindow />
    </TokenContext.Provider>
  );
};

const FinalisationWindow = () => {
    const questionnaireClient = useApiClient(QuestionnaireClient);
    const classes = useGeneralCardStyles();
    const { state } = useContext(Context);
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();
    const questionnairResponseId = window.location.href.slice(-36);
    const [email, setEmail] = useState("");

    const downlaodPDF = () => {
        console.log("downloading pdf", state);
    };
    const submitAnotherReferral = () => {
        history.push("/");
        setLoading(true);
    };

    async function getEmail(): Promise<string> {
        return await questionnaireClient.getQuestionnaireEmailByResponse(questionnairResponseId)
    }

    useEffect(() => {
        async function fetchEmail() {
            const result = await getEmail();
            setEmail(result);
        }

        fetchEmail();
    }, []);

    return (
        <>
            {loading ? (
                <Backdrop className={classes.backdrop} open={true} onClick={() => { }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            ) : (
                <Paper elevation={2} className={classes.root}>
                    <div>
                        <Typography variant="body1" className={classes.header}>
                            Thanks!
                        </Typography>
                        <Typography variant="body2" className={classes.content}>
                            Thank you, the form was submited successfully Please contact {email} for any issues.
                        </Typography>

                        <div className={classes.Btn}>
                            <PDFDownloadLink
                                style={{ textDecoration: 'none' }}
                                document={
                                    <TokenContext.Provider value={state.user!.token}>
                                        <PDFDataConverter
                                            questionnairResponseId={questionnairResponseId}
                                            firstName={state.user!.firstName}
                                            lastName={state.user!.lastName}
                                        />
                                    </TokenContext.Provider>

                                }
                                fileName={`CHaPS_LiL_CFLC_WT_Referral_${format(
                                    new Date(),
                                    "dd-MM-yyyy"
                                )}.pdf`}
                            >
                                <CommonBTN
                                    maxWidth={"400px"}
                                    BtnText={"Download PDF"}
                                    onClick={() => downlaodPDF()}
                                />
                            </PDFDownloadLink>
                        </div>
                        <div className={classes.Btn}>
                            <CommonBTN
                                maxWidth={"400px"}
                                BtnText={"Submit another referral"}
                                onClick={() => submitAnotherReferral()}
                            />
                        </div>
                    </div>
                </Paper>
            )}
        </>
    );
};