import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing(1),
  },
}));

const FormWithValidation = (props: any) => {
  const classes = useStyles();

  useEffect(() => {
    React.Children.forEach(props.children, (element) => {
      if (!React.isValidElement(element)) return;

      console.log(element.props);

      //do something with source..
    });
  }, []);

  const isFormValid = (): boolean => {
    return true;
  };

  const formChildren = React.Children.map(props.children, (child) => {
    return React.cloneElement(child);
  });

  return (
    <form
      className={classes.form}
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      {formChildren}
    </form>
  );
};

export default FormWithValidation;
