import { format, parseISO } from "date-fns";

export function isoToDate(dateIso?: string) {
  if (!dateIso) {
    return undefined;
  }
  return format(parseISO(dateIso), "yyyy-MM-dd");
}

export function isoDateTimeToDisplay(dateTimeIso?: string) {
  if (!dateTimeIso) {
    return undefined;
  }
  try {
    return format(parseISO(dateTimeIso), "dd-MM-yyyy HH:mm");
  } catch (e) {
    return dateTimeIso;
  }
}

export function isoDateToDisplay(dateIso?: string) {
  if (!dateIso) {
    return undefined;
  }
  try {
    return format(parseISO(dateIso), "dd/MM/yyyy");
  } catch (e) {
    return dateIso;
  }
}
