import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import {
  CardList,
  SelectableCard,
  SelectableCardListItem,
  SelectableCardSkeleton,
} from "./Card";
import { ChoiceFilter } from "./DrawerSearch";
import { Paper, Slide } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export interface Audience {
  id: string;
  name: string;
}

export interface BasicUser {
  id: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  currentAudience: Audience;
}

export const ItemSearch = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [searchResults, setSearchResults] = useState<BasicUser[]>([]);
  // const [checked, setChecked] = useState<string[]>([])

  const [selectedUsers, setSelectedUsers] = useState<BasicUser[]>([]);

  let randomColor = () => {
    let hex = Math.floor(Math.random() * 0xffffff);
    return "#" + hex.toString(16);
  };

  useEffect(() => {
    if (!searchQuery?.length) {
      setLoading(false);
      setSearchResults([]);
      return;
    }

    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    const id = setTimeout(() => {
      props.onItemSearch(searchQuery).then((d: any) => {
        setSearchResults(d);
        setLoading(false);
      });
      setTimeoutId(null);
    }, 250);

    setTimeoutId(id);
  }, [searchQuery]);

  useEffect(() => {
    if (loading) {
    }
  }, [searchResults]);

  const selectableCardOnClickHandler = (id: string): void => {
    // user has already been selected
    if (selectedUsers.map((x) => x.id).includes(id)) {
      setSelectedUsers([...selectedUsers.filter((u) => u.id !== id)]);
    } else {
      setSelectedUsers([
        ...selectedUsers,
        ...searchResults.filter((r) => r.id === id),
      ]);
    }
  };

  // remove duplicated items
  const filteredArr = searchResults.filter(
    (s) => !selectedUsers.map((u) => u.id).includes(s.id)
  );

  const membersCompareTo = (a: any, b: any) => {
    const nameA = a.firstName + a.lastName;
    const nameB = b.firstName + b.lastName;
    if (nameA === nameB) {
      return 0;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return -1;
    }
  };

  const combinedArr = [
    ...selectedUsers.sort(membersCompareTo),
    ...filteredArr.sort(membersCompareTo),
  ];

  const seletableCards = (arr: any) => {
    return arr.map((member: BasicUser) => (
      <SelectableCardListItem
        title={`${member.firstName} ${member.lastName}`}
        id={member.id}
        avatar={`${member.firstName.charAt(0).toUpperCase()}${member.lastName
          .charAt(0)
          .toUpperCase()}`}
        avatarColor={randomColor()}
        variant={"selectable"}
        onSelected={selectableCardOnClickHandler}
        checked={selectedUsers.map((s) => s.id).includes(member.id)}
      />
    ));
  };

  return (
    <>
      <CardList fullWidth={true} style={{ height: "90vh", overflow: "auto" }}>
        <ChoiceFilter
          placeholder={"Search for members to add..."}
          value={searchQuery}
          onChange={(
            e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setLoading(true);
            setSearchQuery(e.target.value);
          }}
        />
        {loading && (
          <div style={{ margin: "15px" }}>
            <SelectableCardSkeleton count={5} />
          </div>
        )}
        {!loading && (
          <>
            {searchResults.length === 0 && searchQuery?.length ? (
              <>
                {seletableCards(selectedUsers)}
                <SelectableCardListItem
                  title={`No Users Found`}
                  id={"noUsersFound"}
                />
              </>
            ) : (
              seletableCards(combinedArr)
            )}
          </>
        )}
      </CardList>
      <Slide
        direction="up"
        in={selectedUsers.length > 0}
        mountOnEnter
        unmountOnExit
      >
        <Paper
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            height: "100px",
            width: "800px",
            borderRadius: 0,
          }}
        >
          <Button
            style={{ width: "100%", height: "100%" }}
            variant="contained"
            color="secondary"
            onClick={() => {
              props.onAddMembers(selectedUsers);
              setSelectedUsers([]);
            }}
          >
            Add Member(s)
          </Button>
        </Paper>
      </Slide>
    </>
  );
};
