import { useSnackbar } from "notistack";
import React, { useState, useContext, useEffect } from "react";
import { Manager } from "../../../../components/core/Manager/Manager";
import {
	addRoleMember,
	getRoleMembers,
	getRoles,
	removeRoleMember,
	searchStaffMembers,
} from "../../../../data/api";
import { Context } from "../../../../state/Store";

const RoleManager = () => {
	const { state } = useContext(Context);

	const [roles, setRoles] = useState<any[]>([]);
	const [currentRole, setCurrentRole] = useState<any>();
	const [currentItems, setCurrentItems] = useState<any[]>([]);
	const [selectedItems, setSelectedItems] = useState<any[]>([]);

	const [searchQuery, setSearchQuery] = useState<string>("");

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		getRoles(state!.user!.token).then((x) => setRoles(x));
	}, []);

	const roleDataMapper = (roles: any[]) => {
		return roles.map((x: any) => {
			return {
				value: x.id,
				label: x.name,
			};
		});
	};

	const onRemoveHandler = () => {
		const promises: Promise<any>[] = [];
		selectedItems.forEach((x: any) =>
			promises.push(
				removeRoleMember(currentRole, x, state!.user!.token)
					.then(() => enqueueSnackbar("Member removed", { variant: "success" }))
					.catch(() =>
						enqueueSnackbar("Failed to remove member", { variant: "error" })
					)
			)
		);

		Promise.all(promises).then(() => {
			getRoleMembers(currentRole, state!.user!.token).then((x) => {
				setCurrentItems(x);
				setSelectedItems([]);
			});
		});
	};

	const onResultSelectHandler = (result: any) => {
		if (selectedItems.includes(result)) {
			setSelectedItems([...selectedItems].filter((x: any) => x !== result));
		} else {
			setSelectedItems([...selectedItems, result]);
		}
	};

	const onCheckBoxCheckedHandler = (id: any[]) => {
		if (id[0]) {
			setCurrentRole(id[0]);
			setSelectedItems([]);
			getRoleMembers(id[0], state!.user!.token).then((x) => setCurrentItems(x));
			// uncheck
		} else {
			setCurrentRole(undefined);
			setCurrentItems([]);
			setSelectedItems([]);
		}
	};

	const onAddMemberHandler = (members: any[]) => {
		const promises: Promise<any>[] = [];
		members.forEach((x: any) =>
			promises.push(
				addRoleMember(currentRole, x.id, state!.user!.token)
					.then(() => enqueueSnackbar("Member added", { variant: "success" }))
					.catch(() =>
						enqueueSnackbar("Failed to add member", { variant: "error" })
					)
			)
		);

		Promise.all(promises).then(() => {
			getRoleMembers(currentRole, state!.user!.token).then((x) => {
				setCurrentItems(x);
			});
		});
	};

	const matchQuery = (currentArr: any[]) => {
		const matcher = (name: string, query: string) => {
			if (!query || query === "") return true;

			// remove space in name
			const newName = name.replace(/\s/g, "").toLowerCase();
			const newQuery = query.replace(/\s/g, "").toLowerCase();
			return newName.includes(newQuery);
		};

		return currentArr.filter((x: any) => matcher(x.name, searchQuery));
	};

	const onItemSearchHandler = (query: string) => {
		return searchStaffMembers(query, state!.user!.token);
	};

	return (
		<Manager
			name="Roles"
			treeData={roleDataMapper(roles)}
			onCheckBoxChecked={onCheckBoxCheckedHandler}
			currentItems={matchQuery(currentItems)}
			onResultSelect={onResultSelectHandler}
			selectedItems={selectedItems}
			onSearchBarSearch={setSearchQuery}
			onAdd={onAddMemberHandler}
			onRemove={onRemoveHandler}
			onItemSearch={onItemSearchHandler}
			disableToolBar={!currentRole}
		/>
	);
};

export default RoleManager;
