import React, {useContext} from "react";
import {Redirect, Route, Switch, useRouteMatch} from "react-router";
import {QuestionnaireList} from "./views/Questionnaire/QuestionnaireList";
import {QueueList} from "./views/QueueManagement/QueueList";
import {QueueEditor} from "./views/QueueManagement/QueueEditor";
import {Queue} from "./views/Queue";
import SummaryView from "../../modules/admin/views/SummaryView";
import {QuestionnaireEditor} from "./views/Questionnaire/QuestionnaireEditor";
import RoleManager from "./views/RoleManager/RoleManager";
import {Context} from "../../state/Store";
import {UserPermissions} from "../../state/State.model";
import {OutcomeAccess} from "./views/OutcomeManagement/OutcomeAccess";

export function questionnaireHref(id: string) {
  return `/admin/questionnaire/${id}`;
}

export function editQueueLink(id: string) {
  return `/admin/queue/${id}`;
}

export function AdminRoutes(queues: any[], referralId?: string) {

  const { state } = useContext(Context);
  return (
    <Switch>
      <Route exact path={questionnaireListUrl()}>
        <QuestionnaireList />
      </Route>
      <Route path="/admin/questionnaire/:id">
        <QuestionnaireEditor />
      </Route>
      <Route exact path="/admin/queues">
        <QueueList />
      </Route>
      <Route path="/admin/queue/:id">
        <QueueEditor />
      </Route>
      <Route path="/admin/queue/">
        <QueueEditor />
      </Route>
      <Route path="/admin/access/">
        <OutcomeAccess />
      </Route>
      {state.user?.roles.find(
        (x) => x.permissions == UserPermissions.SuperUser
      ) && (
        <Route path="/admin/roles">
          <RoleManager />
        </Route>
      )}
      {state.user?.roles.find(
        (x) => x.permissions == UserPermissions.SuperUser
      ) && (
        <Route path="/admin/summary">
          <SummaryView />
        </Route>
      )}

      {queues && (
        <Switch>
            <Route exact path="/admin/view/:queueId/:referralId?">
                <Queue queues={queues} referralId={referralId} />
            </Route>
            <Route>
                <Redirect to={
                    referralId
                        ? `/admin/view/${queues[0].id}/${referralId}`
                        : `/admin/view/${queues[0].id}`
                } />
            </Route>
        </Switch>
      )}
    </Switch>
  );
}

export function viewQueueUrl(queueId: string) {
  return "/admin/view/" + queueId;
}

export function useViewQueueMatch() {
  return useRouteMatch<{ queueId: string }>("/admin/view/:queueId");
}

export function questionnaireListUrl() {
  return "/admin/questionnaires";
}

export function questionnaireEditUrl(id: string) {
  return "/admin/questionnaire/" + id;
}
