import { GenerateQuestionnairePDF } from "./GeneratePDF";
import { parseAnswers } from "../Questionnaire";
import {
  QuestionAdminViewModel,
  QuestionnairesClient,
  PersonViewModel
} from "../../../common/client";
import { useApiClient } from "../../../common/apiclients";
import {  useEffect, useState } from "react";



type AnswersState = {
  [questionId: string]: { choiceId: string; value: any };
};

const PDFDataConverter = ({ questionnairResponseId, firstName, lastName }: { questionnairResponseId: string, firstName: string, lastName: string }) => {
  const questionnairesClient = useApiClient(QuestionnairesClient);

  const title = "CHaPS LiL/CFLC Referral Form";
  const [answers, setAnswers] = useState<AnswersState>({});
  const [questions, setQuestions] = useState<QuestionAdminViewModel[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [completedAt, setCompletedAt] = useState<string>("")

  useEffect(() => {
    if (questions.length === 0) setData();
  }, []);
  
  const person: PersonViewModel = {
     id: "",
     firstName: firstName,
     lastName: lastName
  }

  async function setData() {
    const questionnaireData = await questionnairesClient.getQuestions(
        questionnairResponseId
    );
    setAnswers(parseAnswers(questionnaireData.responses));
    setQuestions(questionnaireData.questions);
    setCompletedAt(questionnaireData.completedAt as string);  
    setDataLoaded(true)
  }

  return (
    <>
      {dataLoaded &&
        GenerateQuestionnairePDF({
          questions,
          answers,
          title,
          person,
          completedAt,
        })}
    </>
  );
};
export default PDFDataConverter;
