import { ArrayControl } from "@react-typed-forms/core";

export function moveUp(fields: ArrayControl<any>, index: number) {
  if (index > 0 && index < fields.elems.length)
    fields.update((fields) =>
      fields.map((f, idx) =>
        idx === index ? fields[idx - 1] : idx === index - 1 ? fields[index] : f
      )
    );
}
export function moveDown(fields: ArrayControl<any>, index: number) {
  if (index >= 0 && index < fields.elems.length - 1)
    fields.update((fields) =>
      fields.map((f, idx) =>
        idx === index
          ? fields[idx + 1]
          : idx === index + 1
          ? fields[idx - 1]
          : f
      )
    );
}
