import React, {createContext, useReducer} from 'react';

import StateModel, {PersonKind, User} from "./State.model";
import {Reducer} from "./Reducer";

const getSavedUserInfo = (): User => {
    const userToken = localStorage.getItem('user:authtoken');
    console.log(`CHECK LOCAL STORAGE FOR USER`);

    return {
        //activePass: null,
        personaType: PersonKind.None,
        loggedIn: false,
        token: userToken || '',
        firstName: '',
        lastName: '',
        contactNumber: '',
        //lastLocation: null,
        //metadataQuestions:null,
        kind: 0,
        roles: [],
        outcomes: []
    };
}

const initialState: StateModel = {
    user: getSavedUserInfo()
};

const Store = (props: any) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    const value = { state, dispatch };

    return (
        // @ts-ignore
        <Context.Provider value={value}>
            { props.children }
        </Context.Provider>
    );
};

export interface IDispatchAction {
    type: string,
    payload: any
}

export const Context = createContext({
    state: initialState,
    dispatch: (action: any) => null,
});
export default Store;