import React, {
  ChangeEvent,
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ArrayControl,
  BaseControl,
  ControlChange,
  FormArray,
  FormControl,
  GroupControl,
  useControlChangeEffect,
  useControlState,
  useControlStateVersion,
  useControlValue,
} from "@react-typed-forms/core";
import { IQuestion } from "../../../models/Question";
import { QuestionItemNestedRadioButton } from "./QuestionItemRadioButton";
import { StyledFTextField } from "../../muiCore/TextField";
import { ValidatedForm } from "../forms/ValidatedForm";
import {
  AddressViewModel,
  QuestionAdminViewModel,
  QuestionChoice,
  QuestionType,
} from "../../../common/client";
import {
  QuestionItemNestedSubQuestion,
  QuestionItemSubQuestion,
  QuestionSubQuestion,
} from "./QuestionItemSubQuestion";
import { Box, Collapse } from "@material-ui/core";
import { QuestionRepeaterHeader } from "./QuestionItemHeader";
import {
  AddressComplete,
  makeAddressFormDef,
  RenderAddressFields,
} from "../Address/AddressComplete";
import {
  QuestionItemNestedYesNoButtons,
  QuestionItemYesNoButtons,
} from "./QuestionItemYesNoButtons";
import { ValidatedformWithDateFormatting } from "../forms/ValidatedFormWithDateFormatting";
import { CommonBTN } from "../Buttons/CommonBTN";
import { ChoiceFilter } from "../Questionnaire/Search";

type NestedGroupControl = GroupControl<{ [id: string]: BaseControl }>;

export function RenderNestedQuestion({
  question,
  control,
  forceShow,
  readonly,
  parentHidden,
}: {
  question: IQuestion;
  control: BaseControl;
  parentHidden: boolean;
  forceShow: boolean;
  readonly: boolean;
}): ReactElement {
  const MIN_SEARCH_LIMIT = 15;
  const fc = control as FormControl<any>;
  const hidden = parentHidden || (question.hidden && !forceShow);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [filteredChoices, setFilteredChoices] = useState<QuestionChoice[]>([]);
  useEffect(() => {
    if (hidden) {
      fc.setError(undefined);
      fc.setValue(undefined);
      return;
    }
    // const validator = (c: FormControl<any>) => {
    //   if (question.required && !c.value) {
    //     c.setError(`${question.heading} can not be blank`);
    //   }
    // };
    // fc.addChangeListener(validator);
    // return () => fc.removeChangeListener(validator);
  }, [fc, hidden]);
  useEffect(() => {
    if (searchQuery && searchQuery != "") {
      let choices = question.choices.filter((choice: any) =>
        choice.text
          .toString()
          .toLowerCase()
          .includes(searchQuery.toString().toLowerCase())
      );
      setFilteredChoices(choices);
    } else {
      setFilteredChoices(question.choices);
    }
  }, [searchQuery]);

  function renderByType() {
    switch (question.type) {
      case QuestionType.TextField:
        return (
          <>
            <QuestionSubQuestion
              required={question.required}
              subQuestions={[{ text: question.heading }]}
            />
            <ValidatedForm>
              <StyledFTextField
                state={fc}
                //label={question.heading}
                disabled={readonly}
                required={question.required}
                fullWidth
                // type={"date"}
                // InputLabelProps={{
                //   shrink: true,
                // }}
              />
            </ValidatedForm>
          </>
        );
      case QuestionType.DateField:
        return (
          <>
            <QuestionSubQuestion
              required={question.required}
              subQuestions={[{ text: question.heading }]}
            />
            <ValidatedformWithDateFormatting
              date={fc}
              required={question.required}
              disabled={readonly}
              fullWidth
              setDate={(date: string) => {
                console.log("user entered date: ", date);
                fc.setValue(date);
              }}
            />
          </>
        );
      case QuestionType.DateTimeField:
        return (
          <>
            <QuestionSubQuestion
              required={question.required}
              subQuestions={[{ text: question.heading }]}
            />
            <ValidatedForm>
              <StyledFTextField
                state={fc}
                disabled={readonly}
                required={question.required}
                fullWidth
                type={"datetime-local"}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </ValidatedForm>
          </>
        );
      case QuestionType.RadioButtons:
        return (
          <>
            
            <QuestionSubQuestion
              required={question.required}
              subQuestions={[{ text: question.heading }]}
            />
            {question.choices.length > MIN_SEARCH_LIMIT && !readonly && (
                <ChoiceFilter
                    placeholder={"Search Location..."}
                    value={searchQuery}
                    onChange={(
                        e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => {
                      //setLoading(true)

                      setSearchQuery(e.target.value);
                    }}
                />
            )}
            <QuestionItemNestedRadioButton
              radioButtons={filteredChoices}
              readonly={readonly}
              control={fc}
            />
          </>
        );
      case QuestionType.Grouped:
        return (
          <NestedQuestions
            nestedQuestions={question.nestedQuestions!}
            hidden={hidden}
            readonly={readonly}
            control={control as NestedGroupControl}
          />
        );
      case QuestionType.Repeater:
        return (
          <RepeaterQuestion
            nestedQuestions={question.nestedQuestions!}
            parentQuestion={question}
            hidden={hidden}
            readonly={readonly}
            control={control as ArrayControl<NestedGroupControl>}
          />
        );
      case QuestionType.Address:
        return (
          <AddressQuestion
            question={question}
            control={control as FormControl<AddressViewModel>}
            readonly={readonly}
          />
        );
      case QuestionType.YesNo:
        return (
          <>
            <QuestionSubQuestion
              required={question.required}
              subQuestions={[{ text: question.heading }]}
            />
            {question.subQuestions && (
              <QuestionItemNestedSubQuestion
                subQuestions={question.subQuestions}
              />
            )}
            <QuestionItemNestedYesNoButtons
              buttons={question.choices}
              readonly={readonly}
              control={fc}
            />
          </>
        );
      case QuestionType.RadioButtonsWithLocationHelper:
        const locationHelper = () => {
          window.open(
            "https://dpipwe-au.maps.arcgis.com/apps/webappviewer/index.html?id=817a00ca5f03473ab4ea4202b8f8cd12&extent=15704808.9205%2C-5427061.6209%2C17015856.8297%2C-4803946.9663%2C102100",
            "_blank"
          );
        };
        return (
          <>
            {!readonly && (
              <>
                <CommonBTN
                  maxWidth={"maxWidth"}
                  BtnText={"School Zone Map"}
                  onClick={() => locationHelper()}
                />
              </>
            )}
            <QuestionSubQuestion
              required={question.required}
              subQuestions={[{ text: question.heading }]}
            />
            {question.choices.length > MIN_SEARCH_LIMIT && (
              <ChoiceFilter
                placeholder={"Search Location..."}
                value={searchQuery}
                onChange={(
                  e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  //setLoading(true)

                  setSearchQuery(e.target.value);
                }}
              />
            )}
            <QuestionItemNestedRadioButton
              radioButtons={filteredChoices.sort((a, b) => a.text!.localeCompare(b.text!))}
              readonly={readonly}
              control={fc}
            />
          </>
        );
        case QuestionType.MultiLineTextField:
          return (
            <>
              <QuestionSubQuestion
                required={question.required}
                subQuestions={[{ text: question.heading }]}
              />
              <ValidatedForm>
                <StyledFTextField
                  state={fc}
                  //label={question.heading}
                  disabled={readonly}
                  required={question.required}
                  fullWidth
                  multiline
                  rows={10}
                  // type={"date"}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </ValidatedForm>
            </>
          );
      default:
        return <h1>error - {QuestionType[question.type]}</h1>;
    }
  }

  if (question.hidden) {
    return <Collapse in={forceShow}>{renderByType()}</Collapse>;
  }
  return renderByType();
}

export function NestedQuestions(props: {
  nestedQuestions: QuestionAdminViewModel[];
  children?: ReactNode;
  hidden: boolean;
  readonly: boolean;
  control: NestedGroupControl;
}) {
  const controlAndQuestions = useMemo(
    () =>
      props.nestedQuestions
        .map((question: IQuestion) => ({
          question,
          control: props.control.fields[question.id],
        }))
        .sort((a, b) => a.question.order - b.question.order),
    [props.nestedQuestions, props.control]
  );

  const showMap = useMemo(
    () => viewableControlList(controlAndQuestions),
    [controlAndQuestions]
  );
  useControlStateVersion(props.control);
  return (
    <>
      {controlAndQuestions.map(({ question, control }) => {
        return (
          <RenderNestedQuestion
            readonly={props.readonly}
            question={question}
            parentHidden={props.hidden}
            control={control}
            forceShow={Boolean(
              showMap[question.id]?.find(
                ([c, choiceId]) => c.value === choiceId
              )
            )}
            key={question.id}
          />
        );
      })}
      {props.children}
    </>
  );
}

export function RepeaterQuestion({
  control,
  nestedQuestions,
  parentQuestion,
  readonly,
  children,
  hidden,
}: {
  nestedQuestions: QuestionAdminViewModel[];
  children?: ReactNode;
  readonly: boolean;
  hidden: boolean;
  control: ArrayControl<NestedGroupControl>;
  parentQuestion: QuestionAdminViewModel;
}) {
  useEffect(() => {
    if (control.elems.length === 0) {
      control.add();
    }
  });
  const sortedQuestions = useMemo(
    () => [...nestedQuestions].sort((a, b) => a.order - b.order),
    [nestedQuestions]
  );

  return (
    <FormArray state={control}>
      {(rows) => (
        <>
          <Box m={0}>
            {rows.map((row, idx: number) => (
              <>
                <QuestionRepeaterHeader
                  row={idx}
                  text={parentQuestion.heading}
                  onAddRow={() => control.add()}
                  onRemoveRow={() => control.remove(rows.length - 1)}
                  readonly={readonly}
                />
                <Box my={0} key={row.uniqueId}>
                  <NestedQuestions
                    nestedQuestions={sortedQuestions}
                    hidden={hidden}
                    readonly={readonly}
                    control={row}
                  />
                </Box>
              </>
            ))}
          </Box>
          {children}
        </>
      )}
    </FormArray>
  );
}

function AddressQuestion({
  question,
  control,
  readonly,
}: {
  question: IQuestion;
  control: FormControl<AddressViewModel>;
  readonly: boolean;
}) {
  const [addressForm] = useState(makeAddressFormDef(question.required));
  useControlChangeEffect(
    addressForm,
    (c) => {
      control.setValue(c.toObject());
    },
    ControlChange.Value
  );
  useEffect(() => {
    addressForm.setValue(control.value);
  }, []);
  return (
    <>
      <QuestionSubQuestion
        required={question.required}
        subQuestions={[{ text: question.heading }]}
      />
      <Box m={1}>
        {!readonly && <AddressComplete state={addressForm} />}
        <Box my={2}>
          <RenderAddressFields state={addressForm} readonly={readonly} />
        </Box>
      </Box>
    </>
  );
}

interface ControlAndQuestion {
  question: QuestionAdminViewModel;
  control: BaseControl;
}

// Calculate a map from questionId to an array of formControl and choiceId pairs
function viewableControlList(controlAndQuestions: ControlAndQuestion[]) {
  const m: { [questionId: string]: [FormControl<any>, string][] } = {};
  controlAndQuestions.forEach(({ question, control }) => {
    question.responseMappings?.forEach((mapping) => {
      if (mapping.nextQuestionId) {
        const showMapping: [FormControl<any>, string] = [
          control as FormControl<any>,
          mapping.choiceId,
        ];
        const existing = m[mapping.nextQuestionId];
        if (existing) {
          existing.push(showMapping);
        } else m[mapping.nextQuestionId] = [showMapping];
      }
    });
  });
  return m;
}
