import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { ValidationTextField } from "../../muiCore/TextField";
import TextField, {TextFieldProps} from "@material-ui/core/TextField";
import { ValidatedForm } from "../forms/ValidatedForm";

export const QuestionItemTextField = forwardRef((props: any, TextFieldProps) => {
  const [textFieldErrors, setTextFieldErrors] = useState({
    required: {
      valid: true,
      errorMessage: "",
    },
  });
  const [textFieldValue, setTextFieldValue] = useState<any>();

  const isValid = (): boolean => {
    let valid = true;
    let errors = { ...textFieldErrors };

    if (props.required) {
      if (!textFieldValue) {
        errors.required.valid = false;
        errors.required.errorMessage = "Please provide additional information.";
        valid = false;
      } else {
        errors.required.valid = true;
        errors.required.errorMessage = "";
      }
    }

    setTextFieldErrors(errors);
    return valid;
  };
  // useImperativeHandle(ref, () => ({
  //   resetValidationErrors: () => {
  //     setTextFieldErrors({
  //       ...textFieldErrors,
  //       required: { valid: true, errorMessage: "This is a required field" },
  //     });
  //   },
  //   validate: (): boolean => {
  //     //console.log(textFieldValue)
  //     return isValid();
  //   },
  //   clearTextField: () => {
  //     setTextFieldValue("");
  //   },
  //   text: (): string => textFieldValue,
  //   id: (): string => props.id,
  // }));

  useEffect(() => {
    setTextFieldValue(props.value);
  }, [props.value]);
  useEffect(() => {
    if (props.checkValidation) {
      props.isValid(isValid());
    }
  }, [props.checkValidation]);

  return (
    <ValidatedForm>
      <ValidationTextField
        error={props.error}
        helperText={props.error}
        multiline
        margin="normal"
        fullWidth
        id={props.id}
        label={props?.label}
        name={props.name}
        autoFocus={props.focused}
        required={props.required}
        onChange={(e: any) => {
          props.onChange(e.target.value);
        }}
        //value={textFieldValue}
        variant="outlined"
      />
    </ValidatedForm>
  );
});
