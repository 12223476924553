import { buildGroup, ControlType } from "@react-typed-forms/core";
import { PersonEditModel } from "../../../common/client";
import { makeAddressFormDef } from "../Address/AddressComplete";

export const PersonDetailsFormDef = buildGroup<PersonEditModel>()({
  firstName: "",
  lastName: "",
  contactNumber: "",
  dateOfBirth: "",
  generalPractitionerName: "",
  generalPractitionerPractice: "",
  address: "",
  addressFull: makeAddressFormDef(false),
});

export type PersonDetailsControls = ControlType<typeof PersonDetailsFormDef>;
