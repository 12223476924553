import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { MainListItems } from "../../../data/listItems";
import { PersonAvatar } from "../../../components/muiCore/Avatar";
import { Context } from "../../../state/Store";
import * as api from "../../../data/api";
import { getQueues } from "../../../data/api";
import { useHistory, useLocation } from "react-router";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { PersonKind, UserPermissions } from "../../../state/State.model";
import { TokenContext } from "../../../common/apiclients";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { AdminRoutes, useViewQueueMatch, viewQueueUrl } from "../Routes";
import { RolesSoild } from "../../../components/core/Manager/Icons/Icons";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 48,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	uatHeader: {
		color: "red",
		fontSize: '2.5rem',

	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(11),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(11),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
}));

const Dashboard = () => {
	const classes = useStyles();
	//GLOBAL STATE
	const { state, dispatch } = useContext(Context);
   
	const [queues, setQueues] = useState<any[]>();
	const [referralId, setReferralId] = useState<string>();

	useEffect(() => {}, []);

	let location = useLocation();
	let history = useHistory();
	const queueMatch = useViewQueueMatch();
	const currentQueue =
		queues && queueMatch
			? queues.find((q) => q.id === queueMatch.params.queueId)
			: undefined;

	const [open, setOpen] = React.useState(true);
	const [loggedInUser, setLoggedInUser] = useState<string>();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const url = window.location.href;
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};
	const [ UAT, setUAT] = React.useState(false);
	useEffect(() => {
		if (url.includes('.uat.')) {
			setUAT(true);
		}
	}, [url])

	useEffect(() => {
	
		if (!state.user?.token) {
			console.log('No userToken found')
			let token: any = null;

			if (location.hash != "") {
				token = location.hash.split("=")[1];
				history.push("/admin");
			} else {
				if (state.user?.token) {
					token = state.user?.token;
				}
			}

			if (token) {
				api.getUserInfo(token).then((userInfo: any) => {
					console.log(userInfo);
					dispatch({
						type: "SET_USERINFO",
						payload: {
							...state.user,
							token: token,
							rememberMe: true,
							firstName: userInfo.firstName,
							lastName: userInfo.lastName,
							contactNumber: userInfo.contactNumber,
							kind: userInfo.kind,
							roles: userInfo.roles,
							outcomes: userInfo.queueOutcomeChoices
						},
					});
				});
			} else {
				window.location.href = "/api/user/login";
			}
		} else {

			console.log('Token',state.user?.token)
			api.getUserInfo(state.user?.token).then((userInfo: any) => {
				if (userInfo?.kind !== PersonKind.Staff) {
					console.log("userinfo",userInfo);
					dispatch({
						type: "LOGOUT_USER",
					});
					history.push("/");
				} else {

					dispatch({
						type: "SET_USERINFO",
						payload: {
							...state.user,
							token: state.user?.token,
							rememberMe: true,
							firstName: userInfo.firstName,
							lastName: userInfo.lastName,
							contactNumber: userInfo.contactNumber,
							kind: userInfo.kind,
							roles: userInfo.roles,
							outcomes: userInfo.queueOutcomeChoices
						},
					});
				}
			}).catch((err) => console.log(err));
		}
	}, []);

	useEffect(() => {
		
		setLoggedInUser(`${state.user?.firstName} ${state.user?.lastName}`);
		if (state.user?.roles.find((x) => x.permissions == UserPermissions.SuperUser) || 
		state.user?.outcomes && state.user?.outcomes.length > 0) {
			getQueues(state.user?.token).then((res) => {
				setQueues(res);
				
			});

			const referralMatch = location.pathname.match(/^\/admin\/referrals\/([\w-]+)$/);
			const viewMatch = location.pathname.match(/^\/admin\/view\/([\w-]+)\/([\w-]+)$/);
			if (referralMatch) {
				setReferralId(referralMatch[1]);
			} else if (viewMatch) {
				setReferralId(viewMatch[2]);
			}
		} else {
			if (state.user?.firstName !== "")
			history.push('/')
		}
	
	}, [state.user?.firstName]);

	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="absolute"
				className={clsx(classes.appBar, open && classes.appBarShift)}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						className={clsx(
							classes.menuButton,
							open && classes.menuButtonHidden
						)}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						className={classes.title}
					>
						Admin Dashboard
					</Typography>
					{UAT && (<div className={classes.uatHeader}>Do not enter referrals here – Test site only</div>) }
					{state.user && (
						<>
							<PersonAvatar
								fullName={loggedInUser} //onClick={(event: React.MouseEvent<HTMLButtonElement>)=> handleClick(event)}
							/>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={handleClose}>Logout</MenuItem>
							</Menu>
						</>
					)}
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
				}}
				open={open}
			>
				<div className={classes.toolbarIcon}>
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>
				<Divider />
				<List>
					<MainListItems
						queueItems={queues}
						onItemClick={(item) => history.push(viewQueueUrl(item.id))}
						selectedItem={currentQueue}
					/>
				</List>
				<Divider />
				{/*{state.user?.roles.find(*/}
				{/*	(x) => x.permissions == UserPermissions.SuperUser*/}
				{/*) && (*/}
				{/*	<ListItem*/}
				{/*		button*/}
				{/*		onClick={() => {*/}
				{/*			history.push("/admin/summary");*/}
				{/*		}}*/}
				{/*	>*/}
				{/*		<ListItemIcon>*/}
				{/*			<DashboardIcon />*/}
				{/*		</ListItemIcon>*/}
				{/*		<ListItemText primary="Summary" />*/}
				{/*	</ListItem>*/}
				{/*)}*/}
				{state.user?.roles.find(
					(x) => x.permissions == UserPermissions.SuperUser
				) && (
					<>
					<ListItem button onClick={() => history.push("/admin/access")}>
						<ListItemIcon>
							<RolesSoild />
						</ListItemIcon>
						<ListItemText primary="Access" />
					</ListItem>
					<ListItem button onClick={() => history.push("/admin/roles")}>
						<ListItemIcon>
							<RolesSoild />
						</ListItemIcon>
						<ListItemText primary="Roles" />
					</ListItem>
					</>
				)}
				{/* <ListItem*/}
				{/*	selected={false}*/}
				{/*	button*/}
				{/*	onClick={() => {*/}
				{/*		history.push("/admin/summary");*/}
				{/*	}}*/}
				{/*> */}
				{/*	<ListItemIcon>*/}
				{/*		<DashboardIcon />*/}
				{/*	</ListItemIcon>*/}
				{/*	<ListItemText primary="Summary" />*/}
				{/*</ListItem> */}
				{/* <ListItem selected={false} button>
					<ListItemIcon>
						<DashboardIcon />
					</ListItemIcon>
					<ListItemText primary="Team" />
				</ListItem> */}
				{/*<List>{secondaryListItems}</List> */}
			</Drawer>
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
					{/*<Routes/>*/}
					{state.user?.token ? (
						<TokenContext.Provider value={state.user.token}>
							{AdminRoutes(queues!, referralId)}
						</TokenContext.Provider>
					) : (
						<></>
					)}
				</Container>
			</main>
		</div>
	);
};

export default Dashboard;
